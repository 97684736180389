import React from 'react';
import { ThemeProvider, css } from "styled-components";
import { getRNG } from './services/utils';
import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const fadeIn = css`
  opacity: 0;
  visibility: hidden;
  animation: 500ms ease-out 0s 1 fadeIn forwards;
  @keyframes fadeIn {
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const fadeOut = css`
  opacity: 1;
  visibility: visible;
  animation: 200ms ease-out 0s 1 fadeOut forwards;
  @keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

const fadeUp = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeUp forwards;
  transform: translate(0, 20px);
  @keyframes fadeUp {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const fadeLeft = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeLeft forwards;
  transform: translate(20px, 0);
  @keyframes fadeLeft {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const fadeRight = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeRight forwards;
  transform: translate(-20px, 0);
  @keyframes fadeRight {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const fadeDown = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeDown forwards;
  transform: translate(0, -20px);
  @keyframes fadeDown {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const spin = css`
  animation: spin 2s infinite linear;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform:rotate(0deg);
      transform:rotate(0deg)
    }
    100% {
      -webkit-transform:rotate(359deg);
      transform:rotate(359deg)
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform:rotate(0deg);
      transform:rotate(0deg)
    }
    100% {
      -webkit-transform:rotate(359deg);
      transform:rotate(359deg)
    }
  }
`

export const multiSelectStyles = css`
  --rmsc-primary: #61dafb;
  --rmsc-hover: #DEEBFF;
  --rmsc-selected: #DEEBFF;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-background: #fff;
  --rmsc-spacing: 0px 10px;
  --rmsc-border-radius: 4px;
  --rmsc-height: 38px;
`;

export const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
  }
};

export const modalStylesI = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '5px 10px',
    zIndex                : '99999'
  }
};

export const modalStylesO = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible'
  }
};

export const modalStylesMerch = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0 20px'
  }
};

export const selectStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 1px 16px 0 rgba(0,0,0,.09)' : '0 1px 16px 0 rgba(0,0,0,.09)',
    borderColor: state.isFocused ? '#6724D4' : '#ced4da',
    '&:hover': {
      borderColor: '#6724D4'
    }
  }),
  option: (base, state) => ({
    ...base,
    margin: 'auto',
    minHeight: '1.3rem',
    textTransform: 'capitalize',
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: '10rem',
    textTransform: 'capitalize',
  }),
  menu: (base, state) => ({
    ...base,
  })
};

export const selectStylesNoBox = {
  control: (base, state) => ({
    ...base,
    textAlign: 'center',
    boxShadow: state.isFocused ? '0 0 0 0.1rem #61dafb' : '',
    borderColor: state.isFocused ? '#80bdff' : '#ced4da',
  }),
  option: (base, state) => ({
    ...base,
    margin: 'auto',
    textTransform: 'capitalize',
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: '10rem',
    textTransform: 'capitalize',
  }),
  singleValue: (base, state) => ({
    ...base,
    textAlign: 'center',
    width: '100%'
  })
};

export const selectStylesCards = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 0 0 0.1rem #61dafb' : '0 1px 16px 0 rgba(0,0,0,.09)',
    borderColor: state.isFocused ? '#80bdff' : '#ced4da',
  }),
  option: (base, state) => ({
    ...base,
    margin: 'auto',
    textAlign: 'center'
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: '10rem'
  })
};

export const cardInputStyles = {
  fieldWrapper: {
    base: css`
      margin-bottom: 1rem;
      font-family: Poppins;
    `
  },
  inputWrapper: {
    // base: css`
    //   box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
    // `,
    errored: css`
      border-color: #DC3545;
    `,
    focused: css`
      border-color: #6724D4;
      box-shadow: 0 0 0 1px #6724D4;
    `
  },
  input: {
    base: css`
      box-shadow: none;
      font-size: .9em;
      @media (max-width: 414px) {
        font-size: .8em;
      }
    `,
    errored: css`
      color: #424242;
      border-color: #DC3545;
    `,
    cardNumber: css`
      @media (max-width: 768px) {
        width: 8rem;
      }
      @media (max-width: 414px) {
        width: 7rem;
      }
    `,
    expiryDate: css`
      width: 4rem;
      @media (max-width: 414px) {
        width: 3rem;
      }
    `,
    cvc: css`
      width: 3rem;
      @media (max-width: 414px) {
        width: 2rem;
      }
    `
  },
  errorText: {
    base: css`
      color: #DC3545;
    `
  }
};

export const mapsSuggestionsStyle = {
  container: {
    border: '1px solid #ced4da',
  },
  // suggestion: {
  //   background: 'black',
  // },
}

export const mapsInputStyle = {
  border: '1px solid #ced4da',
}

const mIX = css`
  @media (max-width: 375px) {
    display: none;
  }
`

const mPlus = css`
  @media (max-width: 414px) {
    display: none;
  }
`

const mIpad = css`
  @media (max-width: 767px) {
    display: none;
  }
`

const effectList =[fadeIn, fadeDown, fadeRight, fadeUp, fadeLeft];

let randomEffect = effectList[getRNG(effectList.length)];

const theme = {
  colors: {
    primary: "#6724D4",
    secondary: "#6C757D;",
    green: '#80D28C',
    text: "#424242",
    textSecondary: "#868ba1",
    link: "#17A2B8",
    bold: "#238fe7",
    buttonPrimary: "#6724D4",
    buttonSecondary: "#6C757D",
    navText: "#6c757d",
    toggleOff: "#BBBBBB",
    error: "#DC3545",
    facebook: '#4267B2',
    linkedin: '#2867B2'
    
  },
  fonts: ["Poppins", "sans-serif"],
  fontsNumbers: ["Rubik"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  },
  animations: {
    fadeIn,
    fadeOut,
    fadeUp,
    fadeLeft,
    fadeRight,
    fadeDown,
    random: randomEffect,
    spin
  },
  components: {
    multiSelectStyles
  },
  mobile: {
    mIX,
    mPlus,
    mIpad
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme} randomEffect={randomEffect}>{children}</ThemeProvider>
);

export default Theme;
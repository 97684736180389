import React from 'react';
import SideNav from './SideNav';
import styled from 'styled-components';

const ConsumerRights = ({ view, setView }) => {
  return (
    <Container>
      <SideNav view={view} setView={setView} />
    </Container>
  );
}

export default ConsumerRights;

const Container = styled.div`
  display: flex;
  width: 100%;
`
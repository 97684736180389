import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import logo from '../images/bitdoor-dark.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { modalStyles, Mobile, Desktop } from '../Theme';
import { isUserConsumer, USER_TYPES } from '../services/utils'
Modal.setAppElement('#root');

const Nav = ({ handleLogout, view, setView, userType, profileData }) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const themeContext = useContext(ThemeContext);

  const tuUrl = () => { window.open('https://www.transunion.com/credit-freeze', '_blank') }

  const showModal = openModal
    ? <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Form"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
            <strong>Account Status</strong>
          <Message style={{fontSize: '.9em', marginTop: '1rem'}}>
            There is currently a credit freeze on your account. You can authorize bitdoor to display
            your credit report and score in the "Profile" page.
            Please visit <SpanLink onClick={tuUrl}>TransUnion®</SpanLink> for more information.
          </Message>
          <Row>
            <Button onClick={() => setOpenModal(false)}>Close</Button>
            {/* <DeleteButtonInv onClick={}>Delete</DeleteButtonInv> */}
          </Row>
        </ModalContent>
      </Modal>
    : <></>

  const showFreeze = profileData && isUserConsumer(userType)
    ? profileData.verification.credit_freeze || profileData.verification.credit_unfreeze
      ? <SecurityAlert onClick={() => setOpenModal(true)}>
          <Row>
            <Icon style={{width: '1.2rem'}}><FontAwesomeIcon icon="exclamation-triangle" /></Icon>
            <span style={{width: '8rem', textAlign: 'center'}}>Account Status</span>
          </Row>
        </SecurityAlert>
      : null
    : null

  const navSwitch = () => {

    switch(userType) {

      case USER_TYPES.CONSUMER:
        return(
          <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
              <div />
              <div />
              <div />
            </StyledBurger>
            <Ul open={open}>
              <Row style={{fontWeight: `${view === 'overview' ? '700' : ''}`}}>
                <Link onClick={() => {setView('overview'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="chart-bar" /></Icon>Overview
                </Link>
              </Row>
              <Row style={{fontWeight: `${view === 'opt' ? '700' : ''}`}}>
                <Link onClick={() => {setView('opt'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="check-circle" /></Icon>Opt Out
                </Link>
              </Row>
              <Row style={{fontWeight: `${view === 'shared data' ? '700' : ''}`}}>
                <Link onClick={() => {setView('shared data'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="id-card" /></Icon>Your Shared Data
                </Link>
              </Row>
              <Row style={{fontWeight: `${view === 'offers' ? '700' : ''}`}}>
                <Link onClick={() => {setView('offers'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="gift" /></Icon>Your Offers
                </Link>
              </Row>
              <Row style={{fontWeight: `${view === 'enroll' ? '700' : ''}`}}>
                <Link onClick={() => {setView('enroll'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="credit-card" /></Icon>Your Accounts & Cards
                </Link>
              </Row>
              <Row style={{fontWeight: `${view === 'credit' ? '700' : ''}`}}>
                <Link onClick={() => {setView('credit'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="bullseye" /></Icon>Your Credit Score
                </Link>
              </Row>
              <Row style={{fontWeight: `${view === 'consumer rights' ? '700' : ''}`}}>
                {/* <Link onClick={() => setView('consumer rights')}> */}
                <Link onClick={() => {window.open('https://mybitdoor.com/privacy-policy/', '_blank'); setOpen(!open)}}>
                  <Icon><FontAwesomeIcon icon="users" /></Icon>Your Consumer Rights
                </Link>
              </Row>
              <Row>
                {showFreeze}
                <Desktop>
                  <NavLink 
                    onClick={() => {setView('support'); setOpen(!open)}}
                    style={{
                      color: `${view === 'support' ? themeContext.colors.primary : themeContext.colors.text}`,
                      fontWeight: '700'
                    }}
                    >
                    Support
                  </NavLink>
                  <NavLink
                    onClick={() => {setView('faqs'); setOpen(!open)}}
                    style={{
                      color: `${view === 'faqs' ? themeContext.colors.primary : themeContext.colors.text}`,
                      fontWeight: '700'
                    }}
                    >
                    Tutorial
                  </NavLink>
                  <NavLink
                    onClick={() => {setView('profile'); setOpen(!open)}}
                    style={{
                      color: `${view === 'profile' ? themeContext.colors.primary : themeContext.colors.text}`,
                      fontWeight: '700'
                    }}
                    >
                    Profile
                  </NavLink>
                  <NavLink 
                    onClick={() => {handleLogout(); setOpen(!open)}}
                    style={{
                      fontWeight: '700',
                      color: themeContext.colors.text
                    }}
                  >
                    Logout
                  </NavLink>
                </Desktop>
                <Mobile>
                  <NavLink 
                    onClick={() => {setView('support'); setOpen(!open)}}
                    style={{fontWeight: `${view === 'support' ? `700` : ``}`}}
                    >
                    Support
                  </NavLink>
                  <NavLink
                    onClick={() => {setView('faqs'); setOpen(!open)}}
                    style={{fontWeight: `${view === 'faqs' ? `700` : ``}`}}
                    >
                    Tutorial
                  </NavLink>
                  <NavLink
                    onClick={() => {setView('profile'); setOpen(!open)}}
                    style={{fontWeight: `${view === 'profile' ? `700` : ``}`}}
                    >
                    Profile
                  </NavLink>
                  <NavLink onClick={() => {handleLogout(); setOpen(!open)}}>
                    Logout
                  </NavLink>
                </Mobile>
              </Row>
            </Ul>
          </>
        )

      case USER_TYPES.MERCHANT:
        return(
          <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
              <div />
              <div />
              <div />
            </StyledBurger>
            <Row style={{fontWeight: `${view === 'overview' ? '700' : ''}`}}>
              <Link onClick={() => {setView('overview'); setOpen(!open)}}>
                <Icon><FontAwesomeIcon icon="chart-bar" /></Icon>Overview
              </Link>
            </Row>
            <Row style={{fontWeight: `${view === 'create offer' ? '700' : ''}`}}>
              <Link onClick={() => {setView('create offer'); setOpen(!open)}}>
                <Icon><FontAwesomeIcon icon="plus-square" /></Icon>Create Offer
              </Link>
            </Row>
            <Row style={{fontWeight: `${view === 'your offers' ? '700' : ''}`}}>
              <Link onClick={() => {setView('your offers'); setOpen(!open)}}>
                <Icon><FontAwesomeIcon icon="tags" /></Icon>Your Offers
              </Link>
            </Row>
            <Row style={{fontWeight: `${view === 'analytics' ? '700' : ''}`}}>
              <Link onClick={() => {setView('analytics'); setOpen(!open)}}>
                <Icon><FontAwesomeIcon icon="chart-line" /></Icon>Analytics
              </Link>
            </Row>
            <Ul open={open}>
              <Row>
                <NavLink 
                  onClick={() => {setView('merchant support'); setOpen(!open)}}
                  style={{fontWeight: `${view === 'merchant support' ? `700` : ``}`}}
                  >
                  Support
                </NavLink>
                <NavLink
                  onClick={() => {setView('merchant profile'); setOpen(!open)}}
                  style={{fontWeight: `${view === 'merchant profile' ? `700` : ``}`}}
                  >
                  Profile
                </NavLink>
                <NavLink onClick={() => handleLogout()}>
                  Logout
                </NavLink>
              </Row>
            </Ul>
          </>
        )

      default: return null;
    }
  }


  // const dropDown = showMenu
  //   ? <Dropdown onMouseLeave={() => setShowMenu(false)}>
  //       Test
  //     </Dropdown>
  //   : ''

  return (
    <Container>
      <Logo onClick={() => setView('overview')}>
        <img className="logo" alt="logo" src={logo}/>
      </Logo>
      <LinksContainer>
        {navSwitch()}
      </LinksContainer>
      {showModal}
    </Container>
  );
}

export default Nav;

const Container = styled.div`
  width: 100%;
  background-color: #FFF;
  color: ${({ theme }) => theme.colors.navText };
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    min-height: 4rem;
  }
`

// const DropdownLink = styled.div`  
//   display: none;
//   z-index: 1;
//   min-width: 8rem;
//   background-color: #fff;
//   position: relative;
// `

const Row = styled.div`
  display: flex;
`

const NavLink = styled.li`
  display: flex;
  align-items: center;
  margin: .5rem;
  padding: 1rem .4rem;
  cursor: pointer;
  position: relative;
  justify-content: center;
  font-size: .9em;
  font-weight: 500;
`

const SecurityAlert = styled.li`
  color: ${({ theme }) => theme.colors.error };
  display: flex;
  align-items: center;
  padding: .2rem .4rem;
  cursor: pointer;
  position: relative;
  justify-content: center;
  font-size: 1em;
  border-radius: .25rem;
  margin-top: .2rem;
  
  @media (max-width: 768px) {
    display: none;
  }
`

const Logo = styled.div`
  display: flex;
  align-self: flex-start;
  margin: auto auto auto 2rem;
  padding: .2rem auto;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// const NavDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   margin: .5rem;
//   padding: 1rem .4rem;
//   cursor: pointer;
//   font-weight: bold;
//   position: relative;
// `

// const Dropdown = styled.div`
//   display: block;
//   top: 4.5rem ;
//   position: absolute;
//   background-color: #fff;
//   min-width: 160px;
//   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
//   z-index: 1;

//   ${NavDropdown}:hover & {
//     display: block;
//   }
// `

// const DropdownContent = styled.div`
//   float: none;
//   color: black;
//   padding: 12px 16px;
//   text-decoration: none;
//   display: block;
//   text-align: left;
// `
  
const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 2;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ theme, open }) => open ? '#ccc' : theme.colors.primary };
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  height: 3rem;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: ${({ theme }) => theme.colors.primary };
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: auto;
    width: 300px;
    padding-top: 1rem;
    padding-left: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    li {
      color: #fff;
    }
  }
`;

const Link = styled.span`
  cursor: pointer;
  display: none;
  color: #fff;
  margin-top: 1rem;
  margin-botttom: 1rem;
  font-size: 1.2em;
  padding-left: 1rem;
  
  @media (max-width: 768px) {
    display: flex;
  }
`

const Icon = styled.div`
  width: 2.5rem;
  margin-right: 0;
  margin-left: .5rem;
`

const Button = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary };
  background-color: ${({ theme }) => theme.colors.primary };
  height: 2rem;
  min-width: 4rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
  };

`

const Message = styled.div`
  text-align: center;
  max-width: 19rem;
  margin: auto 1rem;
`

const SpanLink = styled.span`
  color: ${({ theme }) => theme.colors.primary };
  cursor: pointer;
  vertical-align: top;
`
import React from 'react';
import styled from 'styled-components';
import bitdoorLogo from '../images/bitdoor-logo-s.png'

const Maintenance = () => {
  return (
    <Container>
        <Content>
            <LogoTitle src={bitdoorLogo} />
            <TitleText>We are updating our app. Stay tuned.</TitleText>
        </Content>
    </Container>
  );
}

export default Maintenance;


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 28rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1.8em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const LogoTitle = styled.img`
  max-width: 8rem;
  height: auto;
  width: auto;
  margin: auto;
`
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideNav = ({ view, setView }) => {
  return (
    <Container>
      <Row style={{fontWeight: `${view === 'overview' ? '700' : ''}`}}>
        <Link onClick={() => setView('overview')}>
          <Icon><FontAwesomeIcon icon="chart-bar" /></Icon>Overview
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'create offer' ? '700' : ''}`}}>
        <Link onClick={() => setView('create offer')}>
          <Icon><FontAwesomeIcon icon="plus-square" /></Icon>Create Offer
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'your offers' ? '700' : ''}`}}>
        <Link onClick={() => setView('your offers')}>
          <Icon><FontAwesomeIcon icon="tags" /></Icon>Your Offers
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'analytics' ? '700' : ''}`}}>
        <Link onClick={() => setView('analytics')}>
          <Icon><FontAwesomeIcon icon="chart-line" /></Icon>Analytics
        </Link>
      </Row>
    </Container>
  );
}

export default SideNav;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  min-width: 15rem;
  width: 17rem;
  background-color: ${({ theme }) => theme.colors.primary };
  color: #fff;
  padding: 2rem 1rem;
  @media (max-width: 768px) {
    display: none;
  }
`

const Row = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1rem;
`

const Link = styled.span`
  cursor: pointer;
  display: flex;
`

const Icon = styled.div`
  width: 2.5rem;
  margin-right: 0;
  margin-left: .5rem;
`
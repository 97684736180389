import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles, mapsInputStyle } from '../Theme';
import { useFormik } from 'formik';
import { BASE_URL } from '../services/utils';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

Modal.setAppElement('#root');

const Signup = ({ message, setMessage, setErrorStatus }) => {
  const [signupView, setSignupView] = useState('signup');
  const [emailSt, setEmailSt] = useState('');
  const [passwordSt, setPasswordSt] = useState('');
  const [password2St, setPassword2St] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [address, setAddress] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [city, setCity] = useState(null);
  const [addrState, setAddrState] = useState(null);
  const [country, setCountry] = useState(null);

  const validateSignup = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Enter valid email';
    }

    if (!values.password) {
      errors.password = 'Password required';
    } else if (!/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!]/.test(values.password)) {
      errors.password = 'Password must be 8 characters including 1 uppercase and 1 number';
    }

    if (values.password2 !== values.password) {
      errors.password2 = 'Passwords do not match';
    }

    return errors;

  };

  const validateAuth = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'First name Required';
    // } else if (values.firstName.length > 15) {
    //   errors.firstName = 'First name too long';
    }
  
    if (!values.lastName) {
      errors.lastName = 'Last name required';
    // } else if (values.lastName.length > 20) {
    //   errors.lastName = 'Last name too long';
    }

    // if (!values.companyName) {
    //   errors.companyName = 'Company name required';
    // // } else if (values.lastName.length > 20) {
    // //   errors.lastName = 'Last name too long';
    // }

    // if (!values.taxid) {
    //   errors.taxid = 'Last four required';
    // } else if (values.taxid > 10000) {
    //   errors.taxid = 'Last four'
    // }

    if (!values.phone) {
      errors.phone = 'Phone number required';
    }

    return errors;
  };

  const submitAuthentication = useCallback(({
    firstName,
    lastName,
    taxId,
    phone,
    companyName
  }) => {
    fetch(BASE_URL + '/merchants/signup', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        firstName,
        lastName,
        taxId,
        password1: passwordSt,
        password2: password2St,
        companyPostalCode: postalCode,
        companyCity: city,
        companyAddress: address,
        companyState: addrState,
        phone,
        email: emailSt,
        companyCountry: country,
        companyName
      })
    })
    .then(res => res.json())
    .then(data => {
      if(data.message) {
        setMessage(data.message);
        setOpenModal(true);
      } else {
        // setMessage('There was a problem with your request please contact us at https://mybitdoor.com/contact/#contact')
        setErrorStatus(true);
      }
    })
    .catch(err => {
      console.log("Error with authentication request", err);
      setErrorStatus(true);
    })
  }, [emailSt, passwordSt, password2St, setMessage, country, address, addrState, city, postalCode, setErrorStatus])

  const submitSignup = useCallback(({ email, password, password2 }) => {
    setEmailSt(email);
    setPasswordSt(password);
    setPassword2St(password2);
    setSignupView('authenticate');
  }, [])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      taxId: '',
      companyName: '',
    },
    validate: validateAuth,
    onSubmit: values => {
      isAddressValid(values);
    },
  });

  const formikSignup = useFormik({
    initialValues: {
      email: '',
      password: '',
      password2: '',
    },
    validate: validateSignup,
    onSubmit: values => {
      submitSignup(values);
    },
  });

  const supportUrl = () => { window.open('https://mybitdoor.com/contact/#contact', '_blank') }
  const supportButton = error ? <ModalButton onClick={supportUrl}>Support</ModalButton> : <></>

  const handleClose = useCallback(() => {
    setMessage(null);
    setOpenModal(false);
    setError(false);
  }, [setMessage]);

  const isAddressValid = useCallback((values) => {
    if (address && city && addrState && postalCode && country) {
      submitAuthentication(values);
    } else {
      setMessage('Please select a valid address');
      setOpenModal(true);
    }
  }, [address, city, addrState, postalCode, country, setMessage, submitAuthentication]);

  const handleAddress = useCallback(async(data) => {
    try {
          let response = await geocodeByPlaceId(data.place_id);
          let addr = response[0].formatted_address.split(', ');
          let stateZip = addr[2].split(' ');
          setAddress(`${addr[0]}`);
          setCity(addr[1]);
          setAddrState(stateZip[0]);
          setCountry(addr[3]);
          setPostalCode(`${stateZip[1]}`);
       }
    catch {
            console.log('Error retrieving address');
            setErrorStatus(true);
          }
  }, [setErrorStatus])

  const redirect = message === 'Account created' 
    ? <Link to='/'><ModalButton onClick={handleClose}>Close</ModalButton></Link>
    : <ModalButton onClick={handleClose}>Close</ModalButton>

  const redirectLogin = message === 'User already existed. Contact us at https://mybitdoor.com/contact/#contact'
    ? <Link to='/'><ModalButton onClick={handleClose}>Log In</ModalButton></Link> : null

  const modalContent = 
    <Modal
      isOpen={openModal}
      style={modalStyles}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <ModalMessage style={{maxWidth: '21rem'}}>
          {message} &nbsp;
        </ModalMessage>
        <Row>
          {supportButton}
          {redirectLogin}
          {redirect}
        </Row>
      </ModalContent>
    </Modal>
  
  switch(signupView) {

    case 'signup':
      return(
        <Container>
          <LoginForm style={{minHeight: '18rem'}}>
            {/* <Title>bitdoor</Title>
            <TitleText>SIGN UP</TitleText> */}
            <Form onSubmit={formikSignup.handleSubmit}>
              <Column style={{maxWidth: '17rem'}}>
                <Title>bitdoor</Title>
                <TitleText>Merchant Signup</TitleText>
                <Input 
                  value={formikSignup.values.email} 
                  onChange={formikSignup.handleChange}
                  name='email' 
                  id='email'
                  type='email' 
                  placeholder='Email' 
                  autoComplete='email'
                />
                {formikSignup.errors.email ? <Error style={{textAlign: 'left', marginLeft: '.5rem'}}>{formikSignup.errors.email}</Error> : <Error>&nbsp;</Error>}
                <Input 
                  value={formikSignup.values.password} 
                  onChange={formikSignup.handleChange}
                  name='password' 
                  id='password'
                  type='password' 
                  placeholder='Password' 
                  autoComplete='new-password'
                />
                <Input 
                  value={formikSignup.values.password2} 
                  onChange={formikSignup.handleChange}
                  name='password2' 
                  id='password2'
                  type='password' 
                  placeholder='Confirm Password' 
                  autoComplete='new-password'
                />
                <Link 
                  to='/'
                  style={{
                    textAlign: 'left', 
                    marginBottom: '.2rem', 
                    marginLeft: '.2rem', 
                    fontSize: '.9em'
                  }}>
                  <LinkSpan>Back to login</LinkSpan>
                </Link>
                {formikSignup.errors.password ? 
                  <Error style={{
                    textAlign: 'left', 
                    marginLeft: '.5rem', 
                    height: 'auto'}}>
                      {formikSignup.errors.password}
                    </Error> : <></>}
                {formikSignup.errors.password2 ? 
                  <Error style={{
                    textAlign: 'left', 
                    marginLeft: '.5rem', 
                    marginBottom: '.2rem', 
                    height: 'auto'}}>
                  {formikSignup.errors.password2}
                </Error> : <></>}
              </Column>
              <Button type="submit" style={{marginBottom: '0'}}>Continue</Button>
            </Form>
          </LoginForm>
          {modalContent}
        </Container>
      )
    
    case 'authenticate':
      return(
        <Container>
          <LoginForm style={{minWidth: '32rem', height: '28rem'}}>
            <Title>bitdoor</Title>
            <TitleText>Merchant Signup</TitleText>
            <Form onSubmit={formik.handleSubmit}>
              <Row style={{justifyContent: 'left'}}>
                <Column>
                  <Input 
                    style={{marginRight: '1rem', width: '6rem'}} 
                    value={formik.values.firstName} 
                    onChange={formik.handleChange}
                    name='firstName' 
                    id='firstName'
                    type='text' 
                    placeholder='First Name' 
                  />
                  {formik.errors.firstName ? <Error>{formik.errors.firstName}</Error> : <Error>&nbsp;</Error>}
                </Column>
                <Column>
                  <Input 
                    style={{marginRight: '1rem', width: '6rem'}} 
                    value={formik.values.lastName} 
                    onChange={formik.handleChange}
                    name='lastName' 
                    id='lastName'
                    type='text' 
                    placeholder='Last Name' 
                  />
                  {formik.errors.lastName ? <Error>{formik.errors.lastName}</Error> : <Error>&nbsp;</Error>}
                </Column>
              </Row>
              <Label>Business Details:</Label>
              <Row style={{justifyContent: 'left'}}>
                <Column>
                  <Input 
                    style={{marginRight: '1rem', width: '8rem'}}
                    value={formik.values.companyName} 
                    onChange={formik.handleChange}
                    name='companyName' 
                    id='companyName'
                    type='text' 
                    placeholder='Business Name' 
                  />
                  {formik.errors.companyName ? <Error>{formik.errors.companyName}</Error> : <Error>&nbsp;</Error>}
                </Column>
                <Column>
                  <Input 
                    style={{marginRight: '1rem', width: '6rem'}}
                    value={formik.values.phone} 
                    onChange={formik.handleChange}
                    name='phone' 
                    id='phone'
                    type='text' 
                    placeholder='Phone Number' 
                  />
                  {formik.errors.phone ? <Error>{formik.errors.phone}</Error> : <Error>&nbsp;</Error>}
                </Column>
                <Column>
                  <Row>
                    <Input 
                      style={{width: '6rem'}} 
                      value={formik.values.ssn} 
                      onChange={formik.handleChange}
                      name='taxId' 
                      id='taxId'
                      type='number' 
                      placeholder='Tax ID' 
                    />
                  </Row>
                  {formik.errors.taxId ? <Error style={{maxWidth: '6rem'}}>{formik.errors.taxId}</Error> : <Error style={{maxWidth: '6rem'}}>&nbsp;</Error>}
                </Column>
              </Row>
              <Row style={{marginBottom: '2rem'}}>
                {/* <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                  onSelect={handleAddress}
                  inputStyle={mapsInputStyle}
                /> */}
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                  onSelect={handleAddress}
                  inputStyle={mapsInputStyle}
                  renderInput={(props) => (
                    <Row>
                      <AddressInput
                        {...props}
                        autocomplete="off"
                      />
                    </Row>
                  )}
                />
              </Row>
              <Row>
                {/* <Link to='/'><Button style={{marginRight: '2rem', width: '7rem'}}>Cancel</Button></Link> */}
                <Button onClick={() => setSignupView('signup')} style={{marginRight: '2rem', width: '7rem'}}>Back</Button>
                <Button type='submit' style={{width: '7rem'}}>Submit</Button>
              </Row>
            </Form>
          </LoginForm>
          {modalContent}
        </Container>
      )
    
    default: return null
  }

}

export default Signup;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LoginForm = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  min-width: 17rem;
  max-width: 17rem;
  height: 26rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const Title = styled.div`
  width: 100%;
  font-size: 2.4em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: .4rem;
`

const Button = styled.button`
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : '#fff'};
    color: ${({ theme,disabled }) => disabled ? '#fff' : theme.colors.primary };
    border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 18rem;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  margin: 1rem 1rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1em;
  width: auto;
  height: 2.25rem;
  margin: .5rem 1rem 1rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error };
  font-size: .8em;
  height: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: .2rem;
`

const LinkSpan = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
`

const AddressInput = styled.input`
  width: 100%;
`

const Label = styled.label`
  font-size: 1.2em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-bottom: 1rem;
  text-align: left;
`
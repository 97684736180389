import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const ToggleSwitch = ({ isOn , handleToggle, id }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <Input
        checked={isOn}
        id={id}
        type="checkbox"
        onChange={() => handleToggle()}
      />
      <Label
        style={{ background: isOn && themeContext.colors.primary }}
        htmlFor={id}
      >
      <Span />
      </Label>
    </>
  );
};

export default ToggleSwitch;

const Span = styled.span`
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  
  &:checked + label span {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 22px;
  background: ${({ theme }) => theme.colors.toggleOff };
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;

  :active span {
    width: 25px;
  }
`
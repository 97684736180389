import React, { useState } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import { modalStyles } from '../../Theme';
import Modal from 'react-modal';
import TargetForm from './TargetForm';
import BootstrapTable from 'react-bootstrap-table-next';
// import { analytics, topPerformers } from '../../mockData';
import VerticalChart from '../common/VerticalChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'react-loading';

Modal.setAppElement('#root');

const Analytics = ({ view, setView, dashboardData }) => {
  const [openModal, setOpenModal] = useState(false);

  const sortOffers = (offers) => {
    let result = []
    let preSort = []
    offers.forEach(p => preSort.push(p.offer))
    let sorted = preSort.sort((a, b) => (b['views'] - a['views'])).slice(0,3);
    sorted.forEach(s => {
      let temp = {
        name: s.name,
        value: s.views
      }
      result.push(temp);
    })
    return result;
  }

  const columns = [{
    dataField: 'offer.id',
    text: 'Offer ID',
    hidden: true,
  }, {
    dataField: 'offer.name',
    text: 'Name',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'offer.views',
    text: 'Views',
    headerStyle: {textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'analytics.activations',
    text: 'Activations',
    headerStyle: {textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'analytics.redemptions',
    text: 'Redemptions',
    headerStyle: {textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }];

  const showTable = dashboardData
    ? dashboardData.offers
      ? <BootstrapTable keyField='offerId' data={dashboardData.offers} columns={columns} hover/>
      : <NoDataText>No data available</NoDataText>
    : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>
  
  const showGraph = dashboardData
    ? dashboardData.offers
      ? <VerticalChart text={'Top Performers'} data={sortOffers(dashboardData.offers)} />
      : null
    : null

  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <Card>
          <CardTitle><Icon><FontAwesomeIcon icon="chart-line" /></Icon>Analytics</CardTitle>
          <GraphContainer>
            {showGraph}
          </GraphContainer>
          <TableContainer>
            {showTable}
          </TableContainer>
        </Card>
      </Content>
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <TargetForm setOpenModal={setOpenModal} />
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default Analytics;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
  color: ${({ theme }) => theme.colors.text };
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

// const ActionsRow = styled.div`
//   display: flex;
//   justify-content: space-around;
// `

// const ActionsIcon = styled.div`
//   color: ${({ theme }) => theme.colors.text };
//   cursor: pointer;
//   margin: .2rem;
//   &:hover {
//     color: ${({ theme }) => theme.colors.primary };
//   }
// `

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`
const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
  margin-bottom: 2rem;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 18rem;
`

const TableContainer = styled.div`
  font-size: 1em;
`

const GraphContainer = styled.div`
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const NoDataText = styled.div`
  font-color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
`
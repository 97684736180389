import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import MultiSelect from 'react-multi-select-component';
import { selectStyles } from '../../Theme';
import DatePicker from 'react-datepicker';
import { stateLocations } from '../../services/utils';
import SlateReader from '../common/SlateReader';
import GoogleMapReact from 'google-map-react';
import { mapsInputStyle } from '../../Theme';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MapMarker = () => <><FontAwesomeIcon icon="map-marker-alt" color="#ED3B35" style={{fontSize: '1.5rem'}}/></>;

const OfferForm = ({ 
  selectedOption, 
  setSelectedOption, 
  selectedLocation, 
  setSelectedLocation, 
  selectedStore,
  setSelectedStore,
  name, 
  setName, 
  customers,
  setCustomers,
  income,
  setIncome,
  credit,
  setCredit,
  offerUrl,
  setOfferUrl,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  image,
  handleSelectImg,
  currentOffer,
  updateImage,
  setImageDisabled,
  imageDisabled,
  errors,
  description,
  setDescription,
  setOpenModalSlate,
  numMonths,
  setNumMonths,
  handleAddress,
  mapMarker,
}) => {

  const options = [
    { value: '1', label: 'Cash Back Discount (% off)' },
    { value: '2', label: 'BOGO' },
    { value: '3', label: 'Loyalty Points' },
  ];

  const optionsStore = [
    { value: 'IN_STORE', label: 'In Store' },
    { value: 'ONLINE', label: 'Online' },
    { value: 'BOTH', label: 'Both' },
  ];

  const optionsCustomers = [
    { value: 'NEW', label: 'New' },
    { value: 'EXISTING', label: 'Existing' },
    { value: 'ALL', label: 'All' },
  ];

  const optionsIncome = [
    { value: 'LOW', label: 'Low' },
    { value: 'AVERAGE', label: 'Average' },
    { value: 'HIGH', label: 'High' },
    { value: 'ALL', label: 'All' },
  ];

  const optionsCredit = [
    { value: 'LOW', label: 'Low' },
    { value: 'AVERAGE', label: 'Average' },
    { value: 'HIGH', label: 'High' },
    { value: 'ALL', label: 'All' },
  ];

  const showImage = currentOffer
    ? image.preview || currentOffer.offer.image
      ? <ImagePreview src={image.preview || `${currentOffer.offer.image}?${Date.now()}`} />
      : <span>Choose Image</span>
    : image.preview
      ? <ImagePreview src={image.preview} />
      : <span>Choose Image</span>
  
  const updateImageButton = currentOffer
    ? <Row style={{marginTop: '0', justifyContent: 'flex-end'}}>
        <ButtonS disabled={imageDisabled} onClick={updateImage}>Update Image</ButtonS>
      </Row>
    : null
  
  const showMonths = customers.value === 'NEW'
    ? <Row>
        <Description style={{minWidth: '15rem', maxWidth: '15rem'}}>
          This optional number will target customers that have not been active in X months.
        </Description>
        <Input 
          style={{width: '1rem'}}
          type='number'
          value={numMonths}
          max='12'
          onChange={e => setNumMonths(e.target.value)}
        />
      </Row>
    : null

  const showDescription = description
    ? <SlateReader description={description} offerUrl={offerUrl} />
    : null

  const showMap = selectedStore.value === 'IN_STORE' || selectedStore.value === 'BOTH'
    ? <div style={{ height: '17rem', width: '100%' }}>
        <GoogleMapReact
          // bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
          defaultCenter={{ lat: 37.7749295, lng: -122.4194155 }}
          defaultZoom={11}
          zoom={mapMarker.zoom}
          center={{ lat: mapMarker.lat, lng: mapMarker.lng }}
        >
          {mapMarker
            ? <MapMarker
                lat={mapMarker.lat}
                lng={mapMarker.lng}
              />
            : null }
        </GoogleMapReact>
        <GooglePlacesAutocomplete
          // apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
          onSelect={handleAddress}
          inputStyle={mapsInputStyle}
          initialValue={mapMarker.description}
          renderInput={(props) => (
            <Row>
                <AddressInput
                    {...props}
                    autocomplete="off"
                />
            </Row>
          )}
      />
      </div>
    : null

  return (
    <>
      <Form>
        <Row style={{justifyContent: 'flex-start'}}>
          <Column style={{alignSelf: 'flex-start'}}>
            <Row style={{maxHeight: '2.5rem'}}>
              <Label>Name:<Span>*</Span></Label>
              <Column>
                <Input 
                  type='text'
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                {errors.messages ? <Error>{errors.messages.name}</Error> : <></>}
              </Column>
            </Row>
            <Row>
              <Label>Offer Type:<Span>*</Span></Label>
              <StyledSelect 
                id='offer-select'
                value={selectedOption} 
                onChange={(selected) => setSelectedOption(selected)}
                name='offer-select' 
                options={options}
                isSearchable={false}
                placeholder='Select Type'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            <Row>
              <Label>Store/Online:<Span>*</Span></Label>
              <StyledSelect 
                id='offer-store-select'
                value={selectedStore} 
                onChange={(selected) => setSelectedStore(selected)}
                name='offer-store-select' 
                options={optionsStore}
                isSearchable={false}
                placeholder='Select'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            {showMap}
            <Row>
              <Label style={{fontWeight: '700'}}>Target Customers:</Label>
            </Row>
            <Row>
              <Label>Location:</Label>
              <StyledMultiSelect 
                id='offer-location'
                value={selectedLocation} 
                // onChange={(selected) => setSelectedLocation(selected)}
                onChange={(selected) => setSelectedLocation(selected)}
                name='offer-location' 
                options={stateLocations}
                isSearchable={false}
                placeholder='Select Locations'
                // className='custom-location'
                // styles={selectStyles}
                selectAllLabel='Select All'
              />
            </Row>
            <Row>
              <Label>Customer Type:</Label>
              <StyledSelect 
                id='offer-select'
                value={customers} 
                onChange={(selected) => setCustomers(selected)}
                name='offer-select' 
                options={optionsCustomers}
                isSearchable={false}
                placeholder='Select Type'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            {showMonths}
            <Row>
              <Label>Income Score:</Label>
              <StyledSelect 
                id='target-income-select'
                value={income} 
                onChange={(selected) => setIncome(selected)}
                name='target-income-select' 
                options={optionsIncome}
                isSearchable={false}
                placeholder='Select Income'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            <Row>
              <Label>Credit Score:</Label>
              <StyledSelect 
                id='target-credit-select'
                value={credit} 
                onChange={(selected) => setCredit(selected)}
                name='target-credit-select' 
                options={optionsCredit}
                isSearchable={false}
                placeholder='Select Credit'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
          </Column>
          <Column style={{alignSelf: 'flex-start', marginLeft: '5rem'}}>
            <Row style={{maxHeight: '2.5rem'}}>
              <Label>URL:</Label>
              <Column>
                <Input 
                  type='text' 
                  value={offerUrl}
                  onChange={e => setOfferUrl(e.target.value)}
                />
                {errors.messages ? <Error>{errors.messages.offerUrl}</Error> : <></>}
              </Column>
            </Row>
            <Row>
              <Label>Image:</Label>
              <ImageLabel htmlFor='image-upload'>
                {/* {currentOffer ? showCurrentImage : showImage} */}
                {showImage}
              </ImageLabel>
              <ImageInput 
                type='file' 
                accept='image/*'
                onChange={handleSelectImg}
                id='image-upload'
              />
            </Row>
            {updateImageButton}
            <Row style={{justifyContent: 'flex-end'}}>
              <Label><Icon style={{marginRight: '.5rem', marginBottom: '.5rem'}}><i className="far fa-calendar-alt"></i></Icon>Start:<Span>*</Span></Label>
              <StyledDatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                showTimeSelect
                dateFormat="MM/dd/yy h:mm aa"
              />
            </Row>
            <Row style={{justifyContent: 'flex-end', maxHeight: '3rem'}}>
              <Label><Icon style={{marginRight: '.5rem', marginBottom: '.5rem'}}><i className="far fa-calendar-alt"></i></Icon>End:</Label>
              <Column>
                <StyledDatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  showTimeSelect
                  dateFormat="MM/dd/yy h:mm aa"
                  minDate={startDate}
                />
                {errors.messages ? <Error style={{textAlign: 'right'}}>{errors.messages.endDate}</Error> : <></>}
              </Column>
            </Row>
            <Row>
              <Label>Description:</Label>
              <ImageLabel onClick={() => setOpenModalSlate(true)}>
                {description ? 'Edit description' : 'Add a description'}
              </ImageLabel>
            </Row>
            <Row>
              {showDescription}
            </Row>
          </Column>
        </Row>
        {/* <Row style={{justifyContent: 'center'}}>
          <Button onClick={isFormValid}>Save</Button>
        </Row> */}
      </Form>
    </>
  );
}

export default OfferForm;

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Form = styled.div`
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 2.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  font-size: 1.2em;
  margin-right: 1rem;
  font-weight: 500;
  display: flex;
`

const Description = styled.label`
  font-size: 1em;
  margin-right: 1rem;
  font-weight: 400;
  display: flex;
`

const Input = styled.input`
  height: 1.2rem;
  width: 10.5rem;
`

const StyledDatePicker = styled(DatePicker)`
  height: 1.2rem;
  width: 8rem;
  margin-left: 3rem;
`

const StyledSelect = styled(Select)`
  width: 12rem;
  div {
    min-height: 1.3rem;
  }
  option {
    vertical-align: center;
  }
`

const StyledMultiSelect = styled(MultiSelect)`
  width: 12rem;
  ${({ theme }) => theme.components.multiSelectStyles };
  box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);

  input {
    padding: 10px;
  }

  input:focus {
    box-shadow: none;
  }

  div.dropdown-heading {
    padding: 10px;
  }

`

const ButtonS = styled.button`
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : '#fff'};
  color: ${({ theme, disabled }) => disabled ? '#fff' : theme.colors.primary};
  font-size: 1.1em;
  height: 2rem;
  padding: .4rem 1.2rem;
  margin: .5rem .5rem;
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary };
  &:hover {
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary };
  }
`

// const Button = styled.button`
//   background-color: #fff;
//   color: ${({ theme }) => theme.colors.primary};
//   font-size: 1.1em;
//   height: 2.5rem;
//   padding: .4rem 1.2rem;
//   margin: 1rem 1rem;
//   border: 1px solid ${({ theme }) => theme.colors.primary };
//   &:hover {
//     background-color: ${({ theme }) => theme.colors.buttonPrimary };
//     color: #fff;
//     border: 1px solid ${({ theme }) => theme.colors.primary };
//   }
// `

const Span = styled.span`
  color: ${({ theme }) => theme.colors.error };
`

const ImageInput = styled.input`
  border: none;
  padding: 0;
  text-align: right;
  height: auto;
  margin: auto;
  display: none;
`

const ImageLabel = styled.label`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
  padding: .4rem;
  font-weight: 500;
  width: 250px;
  text-align: center;
`

const ImagePreview = styled.img`
  max-height: 200px;
  max-width: 250px;
`

const Error = styled.span`
  font-size: .8em;
  color: ${({ theme }) => theme.colors.error };
  margin-left: .4rem;
  margin-top: .2rem;
`

const AddressInput = styled.input`
    width: 100%;
    font-size: 1em;
`
import React, { useCallback } from 'react';
import styled from 'styled-components';
import CircleBar from '../common/CircleBar'
import CreditCircleBar2 from '../common/CreditCircleBar2';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ProgressProvider from '../common/ProgressProvider';
// import RangeBar from '../common/RangeBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateSlash } from '../../services/utils';

const Overview = ({ setView, optInData, dashboardData, cardData, accountData, offersData, profileData, creditInfo }) => {
//   const getIncomeScore = (n) => {
//     n = Math.round(parseInt(n.substr(1)) / 10) * 10;
//     let data = [{ name: 'income', range: [n-10, n+10], label: `${n-10}k`, label2: `${n+10}k`}]
//     return data;
//   }

  const enrolledCards = cardData && accountData
    ?  <ProgressProvider valueStart={0} valueEnd={cardData.length + accountData.length}>
          {value => <CircleBar value={value} total={cardData.length + accountData.length} text={'Enrolled'} />}
        </ProgressProvider>
    : <CardText style={{ marginTop: 'auto'}}>Please add your credit card.</CardText>

  const offerImages = offersData
    ? offersData.offers.map((o, idx) => (
        o.offer.image 
          ? <Logo key={`offer-logos-${idx}`}src={o.offer.image} alt={o.offer.name} /> 
          : <NoImageContainer key={`offer-logos-${idx}`} id={idx}><NoImageText id={idx}>{o.offer.name}</NoImageText></NoImageContainer>
      ))
    : <NoImageText>There was a problem loading offers</NoImageText>

//   const incomeScore = profileData
//     ? profileData.incomeScore && profileData.creditUpdateDate
//       ? <>
//           <ScoreDate>as of {formatDateSlash(profileData.creditUpdateDate)}</ScoreDate>
//           <Row>
//             <RangeBar data={getIncomeScore(profileData.incomeScore)} />
//           </Row>
//           <IncomeText>Our Estimate Of Your Income</IncomeText>
//         </>
//       : <Column style={{height: 'auto'}}><NoDataText>Income Score Not Available</NoDataText></Column>
//     : <Column style={{height: 'auto'}}><NoDataText>Income Score Not Available</NoDataText></Column>
    
  const creditScore = useCallback(() => { 
    if(!profileData) return null;
    if(profileData.subscription.tier_int === 0){
      return <Column style={{height: 'auto'}}><NoDataText>Subscription required.</NoDataText></Column>
    }
    if(!creditInfo){
      return <Column style={{height: 'auto'}}><NoDataText>Please authenticate your account.</NoDataText></Column>
    }
    return creditInfo.credit_score && creditInfo.credit_updated
      ? <>
          <ScoreDate>as of {formatDateSlash(creditInfo.credit_updated)}</ScoreDate>
          <CreditBarContainer>
            <ProgressProvider valueStart={0} valueEnd={creditInfo.credit_score}>
              {value => <CreditCircleBar2 value={value} total={850} min={300} gradientId={'gradient-credit'} />}
            </ProgressProvider>
          </CreditBarContainer>
        </>
      : <Column style={{height: 'auto'}}><NoDataText>Please authenticate your account.</NoDataText></Column>
  }, [creditInfo, profileData])

  return (
    <Container>
      <TopRow style={{alignItems: 'center'}}>
        <Welcome>
          <Row style={{justifyContent: 'flex-start'}}>
            <TitleIcon>
              <FontAwesomeIcon icon="tachometer-alt"/>
            </TitleIcon>
            <Title>Consumer Dashboard</Title>
          </Row>
          <Text style={{textAlign: 'left'}}>Welcome to the bitdoor consumer dashboard!</Text>
          {/* <Row style={{margin: 'auto', fontSize: '1.1em'}}>
            <Desktop>
              <Column style={{height: 'auto', marginRight: '1rem'}}>
                <ul>
                  <li>View your enrolled cards and accounts</li>
                  <li>View and link offers</li>
                  <li>See your credit score</li>
                </ul>
              </Column>
              <Column style={{height: 'auto'}}>
                <ul>
                  <li>Opt In / Out of specific data usage</li>
                  <li>See which companies are using your data</li>
                  <li>Review the types of data you're sharing</li>
                </ul>
              </Column>
            </Desktop>
            <Mobile>
            <Column style={{height: 'auto'}}>
              <ul>
                <li>View your enrolled cards and accounts</li>
                <li>View and link offers</li>
                <li>See your credit score</li>
                <li>Opt In / Out of specific data usage</li>
                <li>See which companies are using your data</li>
                <li>Review the types of data you're sharing</li>
              </ul>
            </Column>
            </Mobile>
          </Row> */}
        </Welcome>
        {/* <IncomeContainer style={{marginTop: '3rem'}}>
          <IncomeTitle >Your Income Score</IncomeTitle>
          {incomeScore}
        </IncomeContainer> */}
      </TopRow>
      <Cards>
        <Card style={{minHeight: '28rem'}}>
          <CardTitle><Icon><FontAwesomeIcon icon="credit-card" className="icon" /></Icon>Your Cards & Accounts</CardTitle>
          <Column>
            {enrolledCards}
          </Column>
          <Row style={{ margin: 'auto auto 0 auto'}}>
            <Button onClick={() => {setView('enroll')}}>Enroll/View Cards</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><FontAwesomeIcon icon="gift" className="icon" /></Icon>Your Offers</CardTitle>
          <CardText>Everyone loves saving money, right? Below are the latest offers from the brands you love.</CardText>
          {
            offersData
              ? offersData.offers.length
                ? <CarouselContainer>
                    <Carousel
                      autoPlay={4000}
                      animationSpeed={1000}
                      infinite
                      centered
                    >
                      {offerImages}
                    </Carousel>
                  </CarouselContainer>
                : <Column style={{justifyContent: 'center'}}><NoOffersText>No offers available</NoOffersText></Column>
              : null
          }
          <Row>
            <Button onClick={() => {setView('offers')}}>View Offers</Button>
          </Row>
        </Card>
        <Card style={{minWidth: '19rem', maxWidth: '19rem'}}>
          <CardTitle>VantageScore® 3.0 Credit Score</CardTitle>
            {creditScore()}
          <Row>
            <Button onClick={() => {setView('credit')}}>View Report</Button>
          </Row>
        </Card>
        <Card style={{minWidth: '19rem', maxWidth: '19rem'}}>
          <CardTitle><Icon><FontAwesomeIcon icon="check-circle" className="icon" /></Icon>Opt Out</CardTitle>
          <CardText>You control your data, and you can opt out of sharing at any time.</CardText>
          <ProgressProvider valueStart={0} valueEnd={optInData.accounts.length + optInData.cards.length}>
            {value => <CircleBar value={value} total={optInData.accounts.length + optInData.cards.length} text={'Data Sources'} />}
          </ProgressProvider>
          <Row>
            <Button onClick={() => {setView('opt')}}>View Details</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><FontAwesomeIcon icon="id-card" className="icon" /></Icon>Your Shared Data</CardTitle>
          <CardText>Review the types of data that you're sharing. These include:
            <ul>
              <li>
                <Blue>Identifiers</Blue> –Identifiers including name, postal address, unique personal identifier, online identifier, IP address, email address
              </li>
              <li><Blue>Personal Characteristics</Blue> –Bank account number, credit card number, debit card number</li>
              <li><Blue>Commercial Information</Blue> –Purchasing history to make sure you get the offers that align with your preferences</li>
              <li><Blue>Geolocation Data</Blue> –Purchasing locations that make sure the offers you get are convenient</li>
            </ul>
          </CardText>
          <Row>
            <Button onClick={() => {setView('shared data')}}>Review</Button>
          </Row>
        </Card>
      </Cards>
    </Container>
  );
}

export default Overview;

const Container = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const TopRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 2rem 0 2rem;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
  @media (max-width: 414px) {
    margin: 0;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 10rem;
  align-items: center;
  margin: auto;
`

const Welcome = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 1rem;
  justify-content: center;

  @media (max-width: 414px) {
    width: 90%;
    margin-top: 2rem;
  }

`
const Title = styled.div`
  font-size: 2.2em;
  font-weight: 700;
  @media (max-width: 414px) {
    font-size: 1.8em;
  }
  @media (max-width: 340px) {
    font-size: 1.4em;
    padding: 3px;
  }
`
const Text = styled.p`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  text-align: center;
  @media (max-width: 414px) {
	font-size: 1.1em
  }
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 700;
`

const Cards = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (min-width: 1400px) {
    justify-content: space-around;
  }
`

const Card = styled.div`
  margin: 1rem 1rem 2rem 1rem;
  display: flex;
  width: 19rem;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  min-width: 19rem;
  border: 3px solid ${({ theme }) => theme.colors.primary };

  @media (max-width: 756px) {
    min-height: 25rem;
  }

  @media (max-width: 375px) {
    border: none;
    box-shadow: none;
    padding: 0;
  }
`

// const IncomeContainer = styled.div`
//   margin-right: 8.5rem;
//   margin-top: 2rem;
//   display: flex;
//   flex-direction: column;
//   width: 70%;
//   border-radius: .25rem;
//   background-color: #fff;
//   padding: 1.5rem 2.5rem 1.5rem 2.5rem;
//   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);


//   @media (max-width: 1300px) {
//     margin: auto;
//   }

//   @media (max-width: 991px) {
//     min-width: 19rem;
//     width: auto;
//     padding: 2rem 1rem;
//   }
  
//   @media (max-width: 375px) {
//     border: none;
//     box-shadow: none;
//     svg {
//       min-height: 10rem;
//     }  
//   }

// `

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 1.6em;
  font-weight: 700;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  ul li {
    margin-bottom: 10px;
  }
`

const NoDataText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin: 2rem;
  text-align: center;
  vertical-align: middle;
  height: 100%;
`

const NoOffersText = styled.span`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: 3rem auto 0 auto;
  padding: .5rem 1.1rem;
  font-size: 1.1em;
  height: 2.25rem;
  min-width: 9rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding: .2rem;
  color: ${({ theme }) => theme.colors.primary };
`

const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  padding: .2rem;
  font-size: 2em;
  color: ${({ theme }) => theme.colors.primary };
  margin-right: .5rem;
`

const ScoreDate = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.2em;
  font-weight: 400;
`

// const IncomeTitle = styled.div`
//   display: flex;
//   color: ${({ theme }) => theme.colors.text };
//   font-size: 2em;
//   font-weight: 700;
// `

// const IncomeText = styled.div`
//   color: ${({ theme }) => theme.colors.text };
//   font-size: 1.5em;
//   font-weight: 500;
//   margin-top: 1rem;
//   width: 100%;
//   text-align: center;
// `

const CarouselContainer = styled.div`
  width: auto;
  height: 100%;
`

const Logo = styled.img`
  max-height: 10rem;
  max-width: 14rem;
  align-self: center;
  margin: 3rem auto 3rem auto;
`

const CreditBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  jusitify-content: center;
  div.RCP {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2.25rem;
  }
`

const NoImageContainer = styled.div`
  width: 8rem;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoImageText = styled.div`
  align-self: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 15rem;
  color: ${({ theme }) => theme.colors.textSecondary };
`
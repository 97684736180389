import React, { useCallback, useMemo } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import styled from 'styled-components';

const SlateEditor = ({description, setDescription, offerUrl}) => {
  // const [value, setValue] = useState(initialValue)
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  return (
    <Slate editor={editor} value={description} onChange={value => setDescription(value)}>
      <StyledEditable
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={offerUrl ? `Please visit ${offerUrl} for details.` : 'Add a description...'}
        autoFocus
      />
    </Slate>
  )
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default SlateEditor


const StyledEditable = styled(Editable)`
  font-family: ${({ theme }) => theme.fonts };
  max-height: 20rem;
  min-width: 30rem;
  overflow: auto;

  ol, ul {
    line-height: 1.5rem;
    height: auto;
  }

  input, span {
    vertical-align: bottom;
  }

  p {
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    min-width: 14rem;
  }

  @media (max-width: 414px) {
    min-width: 10rem;
  }
`
import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { reset } from '../services/login';
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles } from '../Theme';
import bitdoorLogo from '../images/bitdoor-dark.png';
import PasswordValidation from './common/PasswordValidation'

Modal.setAppElement('#root');

const ResetPassword = ({ message, setMessage, setErrorStatus, emailSt }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
 
  const handleSubmit = useCallback(async() => {
    setInvalidMessage('');
    const tempEmail = email || emailSt;
    const creds = { email: tempEmail, password1: password, password2 }
    try {
        const resp = await reset(creds);
        setMessage(resp.message);
        setOpenModal(true);
      } 
    catch {
        setErrorStatus(true);
      }
  }, [email, emailSt, password, password2, setMessage, setErrorStatus])

  const isFormValid = useCallback(() => {
    const tempEmail = email || emailSt;
    if (tempEmail && password && password2) {
      if (password === password2) {
        handleSubmit();
      } else {
        setInvalidMessage('Passwords do not match')
      }
    } else {
      setInvalidMessage('Form incomplete');
    }
  }, [email, emailSt, password, password2, handleSubmit])

  const supportUrl = () => { window.open('https://www.pentadatainc.com/contact-us', '_blank') }
  const supportButton = error ? <ModalButton onClick={supportUrl}>Support</ModalButton> : <></>

  const signupButton =  message === 'This email is not registered' 
    ? <Link to='/signup'>
        <ModalButton 
            onClick={() => {
            setMessage(null); 
            setOpenModal(false);
            }}
            style={{width: 'auto'}}
        >
            Sign Up
        </ModalButton>
        </Link> 
    : null;

  const handleClose = useCallback(() => {
    setMessage(null);
    setOpenModal(false);
    setError(false);
    setPassword('');
    setPassword2('');
  }, [setMessage])

  const redirect = message === 'Please check your inbox' 
    ? <Link to='/'><ModalButton onClick={handleClose}>Close</ModalButton></Link>
    : <ModalButton onClick={handleClose}>Close</ModalButton>

  return (
    <Container>
      <LoginForm>
        <LogoTitle src={bitdoorLogo} />
        <TitleText>Set Password</TitleText>
          <Form>
            { emailSt
                ? <Input value={emailSt} disabled={true} />
                : <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='email' placeholder='Email' autoComplete='email' />
            }
            <Input value={password} onChange={e => setPassword(e.target.value)} name='password' type='password' placeholder='Password' autoComplete='new-password' />
            <Input value={password2} onChange={e => setPassword2(e.target.value)} name='password confirm' type='password' placeholder='Confirm Password' autoComplete='confirm-password' /> 
          </Form>
          <Row>
            <Message>{invalidMessage}</Message>
          </Row>
          <PasswordValidation password={password}></PasswordValidation>
          <Button onClick={isFormValid} >Submit</Button>
          <Link to='/'><Button>Back to Log In</Button></Link>
      </LoginForm>
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalMessage style={{maxWidth: '21rem'}}>
            {message} &nbsp;
          </ModalMessage>
          <Row>
            {supportButton}
            {signupButton}
            {redirect}
          </Row>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default ResetPassword;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginForm = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;
  width: 100%;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  color: red;
  vertical-align: center;
  height: 1rem;
  display: flex;
  align-items: center;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 18rem;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1em;
  font-weight: 500;
  margin: 1.5rem 1.5rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1em;
  width: auto;
  height: 2.25rem;
  margin: .5rem 1rem 1rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const LogoTitle = styled.img`
  max-width: 16rem;
  height: auto;
  width: auto;
  margin: auto;
`
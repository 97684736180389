import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { formatDate } from '../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiSelect from 'react-multi-select-component';

const companyList = [
  {label: 'Amazon', value: 'Amazon'},
  {label: 'Capital One', value: 'Capital One'},
  {label: 'Best Buy', value: 'Best Buy'},
  {label: 'Walmart', value: 'Walmart'},
  {label: 'American Express', value: 'American Express'},
  {label: 'Apple', value: 'Apple'},
  {label: 'Microsoft', value: 'Microsoft'},
];

const AuthorizeAgentForm = ({ setOpenModal, savePdf, profileData }) => {
  const [checked, setChecked] = useState(false);
  const [download, setDownload] = useState(false);

  const privacyUrl = () => { window.open('https://mybitdoor.com/privacy-policy/', '_blank') }

  const profileName = profileData
    ? profileData.firstName && profileData.lastName
      ? `${profileData.firstName} ${profileData.lastName}`
      : null
    : null

  return (
    <Container>
      <Row style={{justifyContent: 'flex-end'}}>
        <CloseButton onClick={() => setOpenModal(false)}>
          <FontAwesomeIcon icon="times" />
        </CloseButton>
      </Row>
      <Formik
        initialValues={{
          signature: profileName,
          date: formatDate(new Date()),
          companies: []
        }}
        onSubmit={data => {
          savePdf({ 
            signature: data.signature, 
            date: data.date, 
            companies: data.companies, 
            method: download ? 'save' : 'submit' }) 
        }}
        validate={(values) => {
          let errors = {};
          if (!values.signature) {
            errors.signature = 'Name required';
          }
          if (!values.date) {
            errors.date = 'Date required';
          }
          if (!values.companies.length) {
            errors.companies = 'Company required';
          }
          return errors;
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormContent id='authorized-agent-form'>
              <Title>Bitdoor Authorized Agent Form</Title>
              <Subtitle>
                Complete this form so Bitdoor can request your data on your behalf
              </Subtitle>
              <Paragraph>
                California residents have the right to designate a person or business to exercise rights granted to them under the California Consumer Privacy Act (CCPA). By authorizing Bitdoor to act on your behalf, you can direct us to:            
              </Paragraph>
                <StyledUl>
                  <li>
                    Request your data from companies and display that information to you on the Bitdoor Platform.
                  </li>
                  <li>
                    Instruct companies to delete all of your data from their systems.
                  </li>
                  <li>
                    Stop companies from selling your information.            
                  </li>
                </StyledUl>
              <Paragraph>
                Bitdoor will only ever handle and use your data in compliance with its <Link onClick={privacyUrl}>Privacy Policy</Link> and Terms of Use. Some requests may take up to 45 days to fulfill, but most are much quicker.            
              </Paragraph>
              <Paragraph>
                To designate Bitdoor as your authorized agent, simply complete this form:
              </Paragraph>
              <Row style={{justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: '6rem', marginTop: '2rem', marginBottom: '1rem'}}>
                <Field name="signature">
                  {({
                      field,
                      form: {touched, errors},
                      meta,
                    }) => (
                      <Column style={{marginLeft: '.2rem', marginRight: '1rem'}}>
                          <Label>Full Name:</Label>
                          <Input 
                            type="text" 
                            placeholder="" 
                            disabled
                            {...field} 
                            style={meta.error && meta.touched 
                              ? { boxShadow: '0 0 0 1px #DC3545', borderColor: '#DC3545', width: '10rem' }
                              : { width: '10rem' }
                            }
                          />
                          {meta.touched && meta.error && (
                              <Error>{meta.error}</Error>
                          )}
                      </Column>
                  )}
                </Field>
                <Field name="date">
                  {({
                      field,
                      form: {touched, errors},
                      meta,
                    }) => (
                      <Column style={{marginLeft: '.2rem', marginRight: '1rem'}}>
                          <Label>Date:</Label>
                          <Input 
                            disabled
                            type="text" 
                            placeholder="" 
                            {...field} 
                            style={meta.error && meta.touched 
                              ? { boxShadow: '0 0 0 1px #DC3545', borderColor: '#DC3545', width: '5rem' }
                              : { width: '5rem' }
                            }
                          />
                          {meta.touched && meta.error && (
                              <Error>{meta.error}</Error>
                          )}
                      </Column>
                  )}
                </Field>
                <Field name="companies">
                  {({
                      field,
                      form: {touched, errors, setFieldValue},
                      meta,
                    }) => (
                      <Column style={{marginLeft: '.2rem'}}>
                          <Label>Requesting Data From:</Label>
                          {/* <Input 
                            disabled
                            type="text" 
                            placeholder="" 
                            {...field} 
                            style={meta.error && meta.touched 
                              ? { boxShadow: '0 0 0 1px #DC3545', borderColor: '#DC3545', width: '5rem' }
                              : { width: '5rem' }
                            }
                          /> */}
                          <StyledMultiSelect 
                            {...field}
                            options={companyList}
                            value={field.value}
                            isSearchable={true}
                            placeholder='Select Companies'
                            hasSelectAll={false}
                            onChange={(selected) => setFieldValue('companies', selected)}
                            overrideStrings={{"allItemsAreSelected": "All selected"}}
                            className={'multi-select-error'}
                          />
                          {meta.touched && meta.error && (
                              <Error>{meta.error}</Error>
                          )}
                      </Column>
                  )}
                </Field>
              </Row>
              <Row style={{height: '2.2rem'}}>
                <AcknowledgementLabel>
                  <Column style={{alignItems: 'flex-start', justifyContent: 'flex-start', height: '100%', margin: '.5rem'}}>
                    <CheckBox 
                      checked={checked} 
                      type="checkbox" 
                      onChange={() => setChecked(!checked)}
                    />
                  </Column>
                  <Paragraph>
                    I hereby authorize Bitdoor to serve as my designated agent and to act on my behalf, solely as instructed by me on the Bitdoor Platform.
                  </Paragraph>
                </AcknowledgementLabel>
              </Row>
            </FormContent>
            <Row>
              {/* <Button style={{padding: 'auto .5rem'}} disabled={!checked} type="submit" onClick={() => setDownload(true)}><FontAwesomeIcon icon='download' /> PDF</Button> */}
              <Button style={{padding: 'auto .5rem'}} onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button style={{padding: 'auto .5rem'}} disabled={!checked} type="submit" onClick={() => setDownload(false)}>Submit</Button>
            </Row>
            <Row>
              <Paragraph>
                After clicking “Submit,” a PDF version of this form will be produced for your records.
              </Paragraph>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default AuthorizeAgentForm;

const AcknowledgementLabel = styled.label`
  display: contents;
`

const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 48rem;
  max-height: 42rem;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Form = styled.form`
  overflow: auto;
  margin: 0 1rem 1rem 1rem;
`

const FormContent = styled.div`
  padding: 0 1rem 1rem 1rem;
`

const Button = styled.button`
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.buttonPrimary };
  color: #fff;
  font-size: .9em;
  height: 2rem;
  margin-top: 2rem;
  margin-right: 2rem;
  padding: 0.4rem 0.75rem;
  
  svg {
    margin-right: .5rem;
  }

  :hover {
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : '#fff'};
    color: ${({ theme,disabled }) => disabled ? '#fff' : theme.colors.primary };
    border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error };
  font-size: .8em;
  height: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: .2rem;
  margin-top: .2rem;
`

const Title = styled.div`
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;
`

const Subtitle = styled.p`
  font-size: .9em;
  text-align: center;
  text-decoration: underline;
  font-weight: 700;
  margin: 0;
`

const Paragraph = styled.p`
  font-size: .9em;
  text-align: left;
`

const StyledUl = styled.ul`
  font-size: .9em;
  text-align: left;
`

const Label = styled.label`
  font-size: .9em;
`

const Input = styled.input`
  height: 1.5rem;
  box-shadow: none;
  margin-right: 0;
`

// const StyledSelect = styled(Select)`
//   width: 12rem;
//   option {
//     vertical-align: center;
//   }
// `

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`     

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 500;
  cursor: pointer;
`

const CheckBox = styled.input`
  width: 25px;
  height: 25px;
  padding: 0;
  margin-top: 0;
  vertical-align: top;
  &:focus {
    box-shadow: none;
  }
`

const StyledMultiSelect = styled(MultiSelect)`
  width: 12rem;
  ${({ theme }) => theme.components.multiSelectStyles };

  input {
    padding: 10px;
  }

  input:focus {
    box-shadow: none;
  }

  div.dropdown-heading {
    padding: 10px;
  }

`
import React from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import ContactForm from '../ContactForm';
import Accordion from '../common/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Faqs = ({ view, setView, profileData, setErrorStatus, getToken }) => {

  const questions = [
    {q: "Why do you need my personal information?", a: "For your protection. We take steps required to verify your identity—and protect you from fraud, unauthorized access, and personal data theft."},
    {q: "What do I get in return?", a: "Convenient access to cash-back offers, discounts, buy-one/get-one and/or loyalty points—for the products and services you need, from the places you like to shop."},
    {q: "What kind of information is in my shared data?", a: "<ul><li><strong>Identifiers</strong> –Identifiers including name, postal address, unique personal identifier, online identifier, IP address, email address</li><li><strong>Personal Characteristics</strong> –Bank account number, credit card number, debit card number</li><li><strong>Commercial Information</strong> –Purchasing history to make sure you get the offers that align with your preferences</li><li><strong>Geolocation Data</strong> –Purchasing locations that make sure the offers you get are convenient</li></ul>"},
    {q: "Where does the data come from?", a: "From financial institutions, credit bureaus, payment companies, processing companies, and other sources. The data is ONLY collected when you opt-in through the website of companies making the offer, or on the Bitdoor website."},
    {q: "How can I be sure it's secure?", a: "For your security, your card and account credentials are not stored by Bitdoor. This keeps your personal data safe and secure. The card information remains safely stored at your bank, or card provider."},
    {q: "Can my data be tracked without my permission?", a: "No. You must give your consent to track your data. And you can opt-out any time. You’re in control."},
    {q: "How can I opt-out of my data being used?", a: 'All you have to do is click Opt-out next to the company or companies listed in your Opt-in list on the BitDoor user dashboard.'},
    {q: "How do I get paid for providing access to my data?", a: 'You get paid through valuable offers from companies that want to do business with you. Cash-back, discounts, buy-one-get-one-free, loyalty points. Instead of mega tech companies making money off your data, you benefit directly from any and all of the offers that interest you.'},
    {q: "How is my data being used?", a: 'The process is fully transparent. You can see who’s tracking you on the Bitboor user dashboard 24/7. You’ll see which companies are making the offers that save you money when you buy their products and services. Remember you can opt-out (or opt-in) at any time.'},
    {q: "Where can I learn more about your privacy policy?", a: "You can read our complete privacy policy here: <a href='https://mybitdoor.com/privacy-policy/' target='_blank'>https://mybitdoor.com/privacy-policy/</a>"},
  ]

  const collapseQuestions = questions.map((question, idx) => (
    <Accordion key={`q-faqs-${idx}`} title={question.q} text={question.a} />
  ))

  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <CardTitle><Icon><FontAwesomeIcon icon="chalkboard" /></Icon>Tutorial</CardTitle>
        <Label>Congratulations.</Label>
        <CardText style={{marginTop: '0', marginBottom: '0'}}>
          You’re taking the important first step toward gaining control over your personal online data. 
          It takes just a few minutes to be on your way to taking charge of your online information—and getting the card-linked offers that pay you back in return.
          <br/>
          <br/>
          Signing up is easy:
        </CardText>
        <Label>Profile</Label>
        <CardText style={{marginTop: '0', marginBottom: '0'}}>
          First, it’s important to verify your identity:<br />
          <ul style={{listStyleType: 'disc'}}>
            <li>
              Verify profile ﹥ Authenticate
            </li>
            <li>
              Complete form.<br />
            </li>
            <li>
              Answer security questions<br />
            </li>
          </ul>
        </CardText>
        <Label>Credit Information</Label>
        <CardText style={{marginTop: '0', marginBottom: '0'}}>
          Once your profile  is authenticated, you see your credit score instantly—and every time you log in to Bitdoor.<br />
        </CardText>
        <Label>Card-Linked Offers</Label>
        <CardText style={{marginTop: '0', marginBottom: '0'}}>
          Valuable offers are available to you right away. You save money by taking advantage of deals linked directly to your 
          credit or debit cards. No coupons, no searching—the savings automatically apply.<br />
        </CardText>
        <CardTitle style={{marginTop: '2rem'}}><Icon><FontAwesomeIcon icon="question-circle" /></Icon>FAQs</CardTitle>
        <CardText>
          Below are answers to frequently asked questions. Don’t see what you need? Use the form at the bottom of the page to ask us direct.
        </CardText>
        <AccordionContainer>
          {collapseQuestions}
        </AccordionContainer>
        <ContactForm 
          description="Not finding the answer you need? Please use the form below to ask for help." 
          profileData={profileData} 
          getToken={getToken} 
          setErrorStatus={setErrorStatus}
          setView={setView}
        />
      </Content>
    </Container>
  );
}

export default Faqs;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const AccordionContainer = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin-bottom: 3rem;
  vertical-align: middle;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: 414px) {
    margin: 0;
    padding: 2rem;
  }

  @media (max-width: 375px) {
    padding: 1rem;
  }
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.1em;
  margin-bottom: 2rem;
  max-width: 90%;
  ul {
    list-style-type: none;
  }

  @media (max-width: 414px) {
    font-size: .9em;
    max-width: 90%;
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5em;
  font-weight: 700;
  margin-top: 1rem;
`
import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles, modalStylesMerch } from '../Theme';
import { useFormik } from 'formik';
import { BASE_URL } from '../services/utils';
import 'react-google-places-autocomplete/dist/index.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import googleLogo from '../images/g-logo.png';
import facebookLogo from '../images/f-logo.png';
import bitdoorLogo from '../images/bitdoor-dark.png';

Modal.setAppElement('#root');

const Signup = ({ message, setMessage, setErrorStatus, setMessageModal, emailSt, setEmailSt, ssoLogin, setResetPw }) => {
  const [firstNameSt, setFirstNameSt] = useState('');
  const [lastNameSt, setLastNameSt] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalConsent, setOpenModalConsent] = useState(false);
  const [error, setError] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const history = useHistory();

  const setCheckbox = (e) => {
    setReadTerms(e.target.checked)
  }

  const validateSignup = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email required';
    } else if (!/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(values.email)) {
      errors.email = 'Enter valid email';
    }

    if (!values.firstName) {
        errors.firstName = 'First name required';
      }
    
    if (!values.lastName) {
      errors.lastName = 'Last name required';
    }

    return errors;

  };

  const submitAuthentication = useCallback(() => {
    fetch(BASE_URL + '/consumers/signup', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        firstName: firstNameSt,
        lastName: lastNameSt,
        email: emailSt
      })
    })
    .then(res => {
      if (res.status === 201) {
        window.gtag('event', 'conversion', {'send_to': 'AW-463762122/6Wj_COn_sO4BEMrlkd0B'});
        history.push("/set-pw")
      } else if (res.status === 400) {
        setMessage('Cannot register with this email.')
        setMessageModal(true);
      } else if (res.status === 401) {
        setMessage('Account already exist with this email. Please reset password to continue.')
        setResetPw(true);
        setMessageModal(true);
      } else {
        setErrorStatus(true);
      }
    })
    // .then(data => {
    //   if(data.message) {
    //     setMessage(data.message);
    //     setOpenModal(true);
    //   } else {
    //     setErrorStatus(true);
    //   }
    // })
    .catch(err => {
      setErrorStatus(true);
      console.log("Error with authentication request", err)
    })
  }, [setErrorStatus, firstNameSt, lastNameSt, emailSt, setMessage, setMessageModal, history, setResetPw]);

  const submitSignup = useCallback(({ email, firstName, lastName}) => {
    setEmailSt(email);
    setFirstNameSt(firstName);
    setLastNameSt(lastName);
    // setSignupView('authenticate');
    setOpenModalConsent(true);
  }, [setEmailSt]);

  const formikSignup = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validate: validateSignup,
    onSubmit: values => {
      submitSignup(values);
    },
  });

  const supportUrl = () => { window.open('https://mybitdoor.com/contact/#contact', '_blank') }
  const privacyUrl = () => { window.open('https://mybitdoor.com/privacy-policy/', '_blank') }
  const ccpaUrl = () => { window.open('https://mybitdoor.com/privacy-policy/#ccpa', '_blank') }
  const piUrl = () => { window.open('https://mybitdoor.com/privacy-policy/#personal-information', '_blank') }
  const supportButton = error ? <ModalButton onClick={supportUrl}>Support</ModalButton> : <></>

  const handleClose = useCallback(() => {
    setMessage(null);
    setOpenModal(false);
    setError(false);
  }, [setMessage])

  const redirect = message === 'User registered' 
    ? <Link to='/'><ModalButton onClick={handleClose}>Close</ModalButton></Link>
    : <ModalButton onClick={handleClose}>Close</ModalButton>

  const redirectLogin = message === 'User already existed. Contact us at https://mybitdoor.com/contact/#contact'
    ? <Link to='/'><ModalButton onClick={handleClose}>Log In</ModalButton></Link> : null

  const modalContent = 
    <Modal
      isOpen={openModal}
      style={modalStyles}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <ModalMessage style={{maxWidth: '21rem'}}>
          {message} &nbsp;
        </ModalMessage>
        <Row>
          {supportButton}
          {redirectLogin}
          {redirect}
        </Row>
      </ModalContent>
    </Modal>

  const modalContentConsent = 
    <Modal
      isOpen={openModalConsent}
      style={modalStylesMerch}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Row style={{justifyContent: 'flex-end', marginTop: '.5rem', fontSize: '1.2em'}}>
          <CloseButton onClick={() => setOpenModalConsent(false)}>
            <FontAwesomeIcon icon="times" />
          </CloseButton>
        </Row>
        <Column>
          <TermsContent>
            <p>
              By clicking “I Accept – Continue” you agree to our <Link to='/terms'><LinkSpan>Terms </LinkSpan></Link> and you have read and acknowledge our <LinkSpan onClick={privacyUrl}> Privacy Statement </LinkSpan> 
              and <LinkSpan onClick={ccpaUrl}> California Notice at Collection</LinkSpan>. In doing so with your consent and for your benefit, you authorize Bitdoor to collect, use, and share your consumer information in the following ways: 
            </p>
            <ul>
              <li>
                <strong>Consumer Information</strong>. Collect <LinkSpan onClick={piUrl}>these types of consumer information</LinkSpan> about you. 
              </li>
              <li>
                <strong>Custom Ads and Offers</strong>. Use your consumer information to provide you with customized offers, content, and advertising on the Platform, Bitdoor sites, and other third-party sites.     
              </li>
              <li>
                <strong>Improve Services</strong>. Use your consumer information to improve Bitdoor products and services.              
              </li>
              <li>
                <strong>De-Identified</strong>. Use and share your de-identified consumer information as indicated in our <LinkSpan onClick={privacyUrl}>Privacy Statement</LinkSpan>.            
              </li>
              <li>
                <strong>Payment Processors</strong>. Share your consumer information with payment processors, merchants, and other businesses so they can keep track of your account activity, and collect, confirm, 
                and provide Bitdoor with transaction information. These entities may also confirm that purchases or returns match your transactions to determine whether you qualify for offers or check your account’s status. 
                Merchants may use your consumer information to assess the results of ad campaigns, analyze data, provide marketing assistance, or other similar business purposes. 
              </li>
              <li>
                <strong>Credit Bureaus and Banks</strong>. Share your consumer information with credit bureaus and banks so they may make credit decisions for you, which must be authorized by you in each case.                       
              </li>
              <li>
                <strong>Other</strong>. Use your consumer information for other purposes set forth in our <LinkSpan onClick={privacyUrl}>Privacy Statement</LinkSpan>. 
              </li>
            </ul>
          </TermsContent>
          <TermsRow><label><ReadCheckbox type="checkbox" onClick={setCheckbox}/> I read the terms of use</label></TermsRow>
          <TermsRow><Button disabled={!readTerms} style={{marginTop: '0'}} onClick={submitAuthentication}>I Accept - Continue</Button></TermsRow>
        </Column>
      </ModalContent>
    </Modal>

  switch('signup') {

    case 'signup':
      return(
        <Container>
          <LoginForm style={{minHeight: '18rem'}}>
            {/* <Title>bitdoor</Title>
            <TitleText>SIGN UP</TitleText> */}
            <Form onSubmit={formikSignup.handleSubmit}>
              <Column style={{maxWidth: '17rem'}}>
                <LogoTitle src={bitdoorLogo} />
                <TitleText>SIGN UP</TitleText>
                <Input 
                  value={formikSignup.values.email} 
                  onChange={formikSignup.handleChange}
                  name='email' 
                  id='email'
                  type='email' 
                  placeholder='Email' 
                  autoComplete='email'
                />
                <Input 
                  value={formikSignup.values.firstName} 
                  onChange={formikSignup.handleChange}
                  name='firstName' 
                  id='firstName'
                  type='name' 
                  placeholder='First Name' 
                  autoComplete='first-name'
                />
                <Input 
                  value={formikSignup.values.lastName} 
                  onChange={formikSignup.handleChange}
                  name='lastName' 
                  id='lastName'
                  type='name' 
                  placeholder='Last Name' 
                  autoComplete='last-name'
                />
                <Link 
                  to='/'
                  style={{
                    textAlign: 'left', 
                    marginBottom: '.2rem', 
                    marginLeft: '.2rem', 
                    fontSize: '.9em'
                  }}>
                  <LinkSpan>Back to login</LinkSpan>
                </Link>
              </Column>
              <Button type="submit" style={{marginBottom: '0'}}>Continue</Button>
              {formikSignup.errors.email || formikSignup.errors.firstName || formikSignup.errors.lastName
                ? <Error style={{textAlign: 'left', marginLeft: '.5rem', marginTop: '.5rem'}}>{'Email, first and last required'}</Error> 
                : <Error style={{height: '2.5rem'}}>&nbsp;</Error>}
            </Form>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_SSO_ID}
              render={renderProps => (
                <ButtonGoogle 
                  onClick={renderProps.onClick} 
                  disabled={renderProps.disabled}
                > 
                  <ButtonRow>
                    <Logo src={googleLogo} />
                    <span>Sign up with Google</span>
                  </ButtonRow>
                </ButtonGoogle>
              )}
              buttonText="Sign up with Google"
              onSuccess={(res) => { 
                ssoLogin(res, 'google-signin'); 
                history.push('/');
                window.gtag('event', 'conversion', {'send_to': 'AW-463762122/6Wj_COn_sO4BEMrlkd0B'});
              }}
              onFailure={(e) => console.log(e)}
              cookiePolicy={'single_host_origin'}
            />
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_SSO_ID}
              callback={(res) => { 
                ssoLogin(res, 'facebook-signin'); 
                history.push('/');
                window.gtag('event', 'conversion', {'send_to': 'AW-463762122/6Wj_COn_sO4BEMrlkd0B'});
              }}
              onFailure={(e)=>console.log(e)}
              render={renderProps => (
                <ButtonFacebook onClick={renderProps.onClick}>
                  <ButtonRow>
                    <Logo src={facebookLogo} />
                    <span>Sign up with Facebook</span>
                  </ButtonRow>
                </ButtonFacebook>
              )}
            />
          </LoginForm>
          {modalContent}
          {modalContentConsent}
        </Container>
      )
    
    default: return null
  }

}

export default Signup;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TermsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LoginForm = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  min-width: 17rem;
  max-width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: .4rem;
`

const Button = styled.button`
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : '#fff'};
    color: ${({ theme,disabled }) => disabled ? '#fff' : theme.colors.primary };
    border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 18rem;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  margin: 1rem 1rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1em;
  width: auto;
  height: 2.25rem;
  margin: .5rem 1rem 1rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error };
  font-size: .8em;
  height: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: .2rem;
`

const TermsContent = styled.div`
  margin: 1rem 1rem 2rem 1rem;
  width: 27rem;
  height: 100%;
  font-size: .8em;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 25rem;
  overflow: scroll;

  ul {
    padding-inline-start: 20px;
  }

  p {
    margin: 0;
  }

  @media (max-width: 414px) {
    width: 18rem;
    margin: 1rem 0;
  }
`

const LinkSpan = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`

const Logo = styled.img`
  width: 18px;
  margin-right: auto;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  span {
    margin-right: auto;
  }
`

const ButtonFacebook = styled.button`
  background-color: ${({ theme }) => theme.colors.facebook };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
  }
`

const ButtonGoogle = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.textSecondary };
  border: 1px solid ${({ theme }) => theme.colors.toggleOff };
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
  }
`

const ReadCheckbox = styled.input`
  height: auto;
`

const LogoTitle = styled.img`
  max-width: 16rem;
  height: auto;
  width: auto;
  margin: auto;
`
import React from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import ContactForm from '../ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Support = ({ view, setView, profileData, getToken, setErrorStatus }) => {
  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <CardTitle><Icon><FontAwesomeIcon icon="edit" /></Icon>Support</CardTitle>
        <CardText>
          Please use the form below if you have any questions and a representative will get back to you within 
          48 business hours. For immediate questions please email 
          <Link onClick={() => window.open("mailto:customerservice@mybitdoor.com", "_blank")}> customerservice@mybitdoor.com</Link>.
        </CardText>
        <ContactForm 
          profileData={profileData} 
          getToken={getToken} 
          setErrorStatus={setErrorStatus}
          setView={setView}
        />
      </Content>
    </Container>
  );
}

export default Support;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: 768px) {
    height: 100vh;
  };
  @media (max-width: 414px) {
    margin: 1rem;
  };
  @media (max-width: 375px) {
    padding: 1.5rem;
  }
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.1em;
  margin-bottom: 2rem;
`

const Link = styled.a`
  cursor: pointer;
`


import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../services/utils';
import SlateReader from '../common/SlateReader';

const OfferPreview = ({ image, name, offerUrl, selectedOption, startDate, endDate, currentOffer, description }) => {

  const showDummy = currentOffer
    ? image.preview || currentOffer.image
      ? <Logo src={image.preview || `${currentOffer.image}?${Date.now()}`} />
      : <Dummy>
          <DummyTitle>No Image</DummyTitle>
        </Dummy>
    : image.preview
      ? <Logo src={image.preview} alt={'preview'}/>
      : <Dummy>
          <DummyTitle>No Image</DummyTitle>
        </Dummy>

  const showDate = endDate
    ? <DateRow>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</DateRow>
    : <DateRow>{formatDate(startDate)}</DateRow>

  const showDescription = description
    ? <Terms>
        <SlateReader 
          description={description}
          offerUrl={offerUrl}
        />
      </Terms>
    : <Terms></Terms>

  return (
    <Container>
      <Card>
        <Row>
          {showDummy}
          <Description>
            <h3>{name}</h3>
            <strong>{selectedOption.label}</strong>
            {showDate}
          </Description>
          <LinkButton />
        </Row>
        {showDescription}
      </Card>
    </Container>
  );
}

export default OfferPreview;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 35rem;
  overflow-y: scroll;
`

// const Title = styled.div`
//   font-size: 1.8em;
//   font-weight: 700;
// `

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: .5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  border-radius: .25rem;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  transition: box-shadow .3s ease-in-out;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  width: 12rem;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 400;
  padding: .2rem;
  text-align: left;
  margin: auto;

  h3 {
    margin: 0;
  }
`

const Logo = styled.img`
  max-width: 10rem;
  max-height: 8rem;
  margin-left: 2rem;
  margin: auto auto auto 0;
`

const LinkButton = styled.div`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: auto 2rem auto auto;
  font-size: 1.2em;
  height: 3.5rem;
  padding: .7rem 2rem;
  display: none;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Terms = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
  border-top: 1px solid grey;
  color: ${({ theme }) => theme.colors.text };
  text-align: left;
`

const Dummy = styled.div`
  width: 8rem;
  height: 8rem;
  border: 1px solid ${({ theme }) => theme.colors.textSecondary };
`

const DummyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 500;
  margin-top: auto;
  height: 100%;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const DateRow = styled.span`
  width: 14rem;
  font-size: .8em;
`
import React, { useState, useCallback } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { login, mfa_login } from '../services/login';
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles } from '../Theme';
import isLoading from './common/isLoading';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import googleLogo from '../images/g-logo.png';
import facebookLogo from '../images/f-logo.png';
import bitdoorLogo from '../images/bitdoor-dark.png';
import { isUserConsumer, isUserMerchant } from '../services/utils'

Modal.setAppElement('#root');

const Login = ({ 
  tokenState, handleLogout, setMessage, message, getToken, setUserType, setErrorStatus, ssoLogin, sendSlack 
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [signature, setSignature] = useState('');
  const [OTP, setOTP] = useState('');
  const [openModalMFA, setOpenModalMFA] = useState(false);
  const [openSlackModal, setOpenSlackModal] = useState(false)
  const [helpText, setHelpText] = useState('')
  const USER_TYPES = ['consumer', 'merchant']


  const handleMFASubmit = useCallback(async (e) => {
    e.preventDefault();
    setModalMessage('');
    if (!OTP) {
      setModalMessage('OTP required');
      return;
    }
    if (OTP.length !== 6) {
      setModalMessage('Check OTP');
      return;
    }
    try {
      const resp = await mfa_login({ signature: signature, otp: OTP })
      const status = resp.status;
      const userType = resp.userType;

      switch(status){
        case 200:
          if (USER_TYPES.includes(userType)) {
            getToken();
            setModalMessage('');
            setUserType(userType);
          } else {
            setOpenModal(true);
          }
          break;
        case 400:
          setModalMessage('There was a problem with the request, please contact Bitdoor to resolve');
          break;
        case 403:
          setModalMessage('Max attempts reached, please wait 10 minutes and then try to login again.');
          break;
        case 409:
          setModalMessage('Incorrect OTP, please re-enter the code sent to you.');
          break;
        default:
          setModalMessage('There was a problem with the request, please contact Bitdoor to resolve');
      }
    } catch (err) {
      setErrorStatus(true);
      console.log(err);
    }
  }, [signature, getToken, setModalMessage, setUserType, setErrorStatus, OTP, USER_TYPES])


  const handleSubmit = useCallback(async(e) => {
    setMessage('');
    e.preventDefault();
    const creds = { email, password }
    if (!email || !password) {
      setModalMessage('Email/Password required');
      setOpenModalMessage(true);
      return;
    }
    try {
      const { userType, status, signature } = await login(creds)
      if (status === 200) {
        if (isUserConsumer(userType) || isUserMerchant(userType)) {
          getToken();
          setMessage('');
          setUserType(userType);
        } else {
          setOpenModal(true);
        }
        return;
      }
      if (status === 202) {
        setSignature(signature);
        setOpenModalMFA(true);
        return;
      }
      if (status === 401) {
        setModalMessage('Invalid credentials.')
        setOpenModalMessage(true);
      }
      if (status === 429) {
        setHelpText("I am having trouble logging into my account.\n-" + email)
        setOpenSlackModal(true);
        return;
      }
    } catch (err) {
      setErrorStatus(true);
      console.log(err);
    }
  }, [email, password, setMessage, getToken, setUserType, setErrorStatus, setSignature])

  const showMessageModal = openSlackModal
    ? <Modal
      isOpen={openSlackModal}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent style={{width: '20rem'}}>
        <Text style={{ fontSize: '1.1em', marginTop: '1rem', color: '#424242', marginBottom: '1rem'}}><strong>Max login attempts reached.</strong></Text>
        <TextInput required value={helpText} onChange={(e) => setHelpText(e.target.value)}
        name='text' type='text' placeholder='' autoComplete='text' />
        <Row>
          <SmallButton style={{marginRight: '1rem'}} onClick={() => setOpenSlackModal(false)}>Cancel</SmallButton>
          <SmallButton onClick={() => {sendSlack(helpText); setOpenSlackModal(false)}}>Send</SmallButton>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const loginOrRefrsh = tokenState === 'expired'
    ? <RefreshAlert>
      <RefreshText>Session has expired. Please log in with credentials.</RefreshText>
      <Row>
        <RefreshButton onClick={handleLogout}>
          OK
        </RefreshButton>
      </Row>
    </RefreshAlert>
    : <LoginForm>
      {/* <Title>bitdoor</Title> */}
      <LogoTitle src={bitdoorLogo} />
      <TitleText>DASHBOARD LOG IN</TitleText>
      <Form>
        <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='email' placeholder='Email' autoComplete='off' />
        <Input value={password} onChange={e => setPassword(e.target.value)} name='password' type='password' placeholder='Password' autoComplete='off' />
        <ForgotLink><ReactLink to='/set-pw' className='link'>Forgot password?</ReactLink></ForgotLink>
        <Button onClick={handleSubmit}>Sign In</Button>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_SSO_ID}
          // isSignedIn={true}
          render={renderProps => (
            <ButtonGoogle
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <ButtonRow>
                <Logo src={googleLogo} />
                <span>Sign in with Google</span>
              </ButtonRow>
            </ButtonGoogle>
          )}
          buttonText="Sign in with Google"
          onSuccess={(res) => ssoLogin(res, 'google-signin')}
          // onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_SSO_ID}
          callback={(res) => ssoLogin(res, 'facebook-signin')}
          onFailure={(e) => console.log(e)}
          render={renderProps => (
            <ButtonFacebook onClick={renderProps.onClick}>
              <ButtonRow>
                <Logo src={facebookLogo} />
                <span>Sign in with Facebook</span>
              </ButtonRow>
            </ButtonFacebook>
          )}
        />
      </Form>
      <SignUpLink>Not yet a member? <ReactLink className='link' to='/signup'>Sign Up</ReactLink></SignUpLink>
    </LoginForm>

  const showMFA = openModalMFA
    ? <Modal
      isOpen={openModalMFA}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent style={{ width: '24rem' }}>
        <Text style={{ fontSize: '1.2em', margin: 'auto auto 1rem auto' }}><strong>Multi Factor Authentication</strong></Text>
        <Text style={{ margin: 'auto auto 1rem auto' }}>You will receive a OTP through the method that was set up.</Text>
        <Label>Enter One Time Password: </Label>
        <MFARow style={{ margin: '1rem 0 0 0' }}>
          <Input
            value={OTP}
            name='OTP'
            autoComplete='off'
            type='number'
            style={{ width: '6rem', marginBottom: '0', textAlign: 'center' }}
            onChange={(e) => setOTP(e.target.value)}
          />
        </MFARow>
        <Message style={{ height: '1rem' }}>{modalMessage ? modalMessage : ''}</Message>
        <Row>
          <Button onClick={() => { setOpenModalMFA(false) }} style={{ marginRight: '2rem', width: '7rem' }}>Cancel</Button>
          <Button onClick={handleMFASubmit} style={{ width: '7rem' }}>Submit</Button>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  return (
    <Container>
      {loginOrRefrsh}
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalMessage style={{ maxWidth: '21rem' }}>
            Please log in at &nbsp;
            <Link onClick={() => window.open("https://app.pentadatainc.com", "_blank")}>
              app.pentadatainc.com
            </Link>
          </ModalMessage>
          <Row>
            <ModalButton onClick={() => setOpenModal(false)}>Close</ModalButton>
          </Row>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={openModalMessage}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalMessage style={{ maxWidth: '21rem' }}>
            {modalMessage}
          </ModalMessage>
          <Row>
            <ModalButton onClick={() => setOpenModalMessage(false)}>Close</ModalButton>
          </Row>
        </ModalContent>
      </Modal>
      {showMFA}
      {showMessageModal}
    </Container>
  );
}

export default isLoading(Login);


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 1.5rem;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`

const ForgotLink = styled.div`
  text-align: left;
`

const SignUpLink = styled.div`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-top: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`


const SmallButton = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  color: #fff;
  font-size: .9em;
  height: 2rem;
  padding: 0.35rem 0.5rem;
  min-width: 6rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const ButtonFacebook = styled.button`
  background-color: ${({ theme }) => theme.colors.facebook};
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
  }
`

const ButtonGoogle = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.textSecondary};
  border: 1px solid ${({ theme }) => theme.colors.toggleOff};
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
  }
`


const Form = styled.div`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  height: .5rem;
  color: red;
  margin: .5rem;
`

const RefreshAlert = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2rem;
`

const RefreshText = styled.div`
  width: 100%;
  font-size: 1.1em;
  font-weight: 400;
  color: #000;
  margin-bottom: 1rem;
`

const RefreshButton = styled.button`
  width: 3.6rem;
  font-weight: 500;
  margin: .4rem 1rem 0 1rem;
  font-size: 1em;
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  color: #fff;
  height: 1.8rem;
  text-align: center;
  padding: 0.3rem .4rem;
  letter-spacing: 0.0875rem;
`


const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  text-align: center;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1em;
  font-weight: 500;
  margin: 1.5rem 1.5rem;
  min-width: 12rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 1em;
  width: 5rem;
  height: 2.25rem;
  margin: .5rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: #fff;
  }
`

const Link = styled.a`
  cursor: pointer;
`

const Logo = styled.img`
  width: 18px;
  margin-right: auto;
`

const LogoTitle = styled.img`
  max-width: 16rem;
  height: auto;
  width: auto;
  margin: auto;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  span {
    margin-right: auto;
  }
`

const Text = styled.div`
  text-align: center;
  margin: auto 1rem;
  @media (max-width: 414px) {
    max-width: 19rem;
    margin: auto;
  }
`

const MFARow = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  align-items: center;
  margin: 0;
  height: 3rem;
  input {
    height: 1.5rem;
    width: 8rem;
  }
`

const Label = styled.label`
  font-size: 1em;
`

const TextInput = styled.textarea`
  height: 5.5rem;
  margin-bottom: 1rem;
  width: 18rem;
  resize: vertical;
  font-size: 1.1em;
  @media (max-width: 414px) {
    max-width: 90%;
  }
`
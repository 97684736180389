import React,{ useState, useCallback } from 'react';
import ExportButton from '../common/ExportButton';
import SideNav from './SideNav';
import styled from 'styled-components';
import Toggle from '../common/ToggleSwitch';
import { formatDate } from '../../services/utils';
import { BASE_URL, isTokenValid, formatDateMonths } from '../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from 'react-loading';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Modal from 'react-modal';
import { modalStyles } from './../../Theme'

const Opt = ({ view, setView, optInData, dashboardData, getToken, webToken, fetchDashboardData, setErrorStatus, trackingData }) => {

  const [openModal, setOpenModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [showView, setShowView] = useState(false);
  const [optOutOne, setOptOutOne] = useState(true);
  const [optOutAll, setOptOutAll] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [message, setMessage] = useState('');
  const [currentCompany, setCurrentCompany] = useState(null);

  const tableAccountFormatter = (cell, row) => {
    if (row.type === 'card') {
      return (
        <span>x{cell}</span>
      );
    } else {
      return (
        <span style={{textTransform: 'capitalize'}}>{cell}</span>
      );
    }
  };

  const tableDateFormatter = (cell, row) => {
    return (
      <span>{ formatDateMonths(cell) }</span>
    );
  };

  const handleCloseCompany = () => {
    setCurrentCompany(null);
    setOpenModal(false);
  }

  const handleViewCompany = (data) => {
    setCurrentCompany(data);
    setOpenModal(true);
  }

  const handleClick = useCallback((data) => {
    setCurrentData(data);
    setShowView(true)
  },[])

  const handleCancel = useCallback(() => {
    setCurrentData(null);
    setShowView(false);
    setOptOutOne(true);
    setOptOutAll(true);
    setMessage('');
    setConfirm(false);
  }, [])

  const handleToggleOne = useCallback(() => {
    optOutAll && setOptOutOne(!optOutOne);
  }, [optOutOne, optOutAll])

  const handleToggleAll = useCallback(() => {
    setOptOutAll(!optOutAll);
    optOutOne && setOptOutOne(false);
  }, [optOutAll, optOutOne])

  const optOutRequest = useCallback(async({ id, subscriberId, type }) => {
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(`${BASE_URL}/consumers/${type}/${id}/subscribers/${subscriberId}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      .then(res => res.json())
      .then(data => {
        setConfirm(true);
        fetchDashboardData({ token });
        setMessage(`Opted out from ${!optOutAll ? 'all companies' : currentData.company}` )
      })
      .catch(err => {
        console.log("Error opting card out", err)
        setConfirm(true);
        setErrorStatus(true);
      })
    }
  }, [getToken, optOutAll, currentData, fetchDashboardData, setErrorStatus])

  const trackingDataColumn = (cell, row) => {
    return (
      <Link onClick={() => handleViewCompany(row)}><FontAwesomeIcon icon="cloud-download-alt"/></Link>
    );
  };

  const handleOptOut = useCallback(() => {
    if(!optOutOne || !optOutAll) {
      let id = currentData.cardId || currentData.accountId;
      let type = currentData.cardId ? 'cards' : 'accounts';
      if(optOutAll) {
        optOutRequest({ id, subscriberId: currentData.subscriberId, type });
      } else {
        optOutRequest({ id, subscriberId: '', type });
      }
    }
  }, [optOutAll, optOutOne, currentData, optOutRequest])

  const columns = [{
    dataField: 'id',
    text: 'Consumer ID',
    sort: true,
    hidden: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'company',
    text: 'Company',
    sort: true,
    headerStyle: {width: '25%'},
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'data',
    text: 'Download Tracking Data',
    headerStyle: {
      textAlign: 'center'
    },
    style: {
      textAlign: 'center'
    },
    formatter: trackingDataColumn,
  }];

  const incomeColumns = [{
    dataField: 'id',
    text: 'Consumer ID',
    sort: true,
    hidden: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'company',
    text: 'Company',
    sort: true,
    headerStyle: {width: '25%'},
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'lastOptIn',
    text: 'Last Opt In',
    headerStyle: {
      textAlign: 'center'
    },
    style: {
      textAlign: 'center'
    }
  },
  {
    dataField: 'consent',
    text: 'Consent',
    headerStyle: {
      textAlign: 'center'
    },
    style: {
      textAlign: 'center'
    }
  }];

  const trackingDataColumns = [{
    dataField: 'id',
    text: 'Account/Card ID',
    sort: true,
    hidden: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'lastOptIn',
    text: 'Opt In Date',
    sort: true,
    formatter: tableDateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'type',
    text: 'Type',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'account',
    text: 'Account/Card #',
    sort: true,
    headerStyle: {
      textAlign: 'center'
    },
    style: {
      textAlign: 'center'
    },
    formatter: tableAccountFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'consent',
    text: 'Consent URL',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }];

  const showModal = currentCompany
    ? <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Tracking Data"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalRow>
            <ModalTitle>TRACKING DATA</ModalTitle>
            <CloseButton onClick={handleCloseCompany}>
              <FontAwesomeIcon icon="times"/>
            </CloseButton>
          </ModalRow>
          <ModalRow style={{margin: '1rem 0', justifyContent: 'center'}}>
            <ModalTableTitle>
              {currentCompany.company}
            </ModalTableTitle>
          </ModalRow>
          <ToolkitProvider
            keyField="id"
            data={ currentCompany.data }
            columns={ trackingDataColumns }
            exportCSV
            >
            {
              props => (
                <div>
                  <BootstrapTable 
                    { ...props.baseProps } 
                    striped
                    hover
                  />
                  <ModalRow style={{margin: '.5rem auto'}}>
                    <ExportButton 
                      style={{fontSize: '.8em'}} 
                      text={'Download Data'} 
                      { ...props.csvProps } />
                  </ModalRow>
                </div>
              )
            }
            </ToolkitProvider>
        </ModalContent>
      </Modal>
    : <></>

  const accountTable = optInData.accounts.length
   ? <table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Bank</th>
            <th>Date</th>
            <StyledTh>Opt Out Of Data Collection</StyledTh>
          </tr>
        </thead>
        <tbody>
          {optInData.accounts.map((account, idx) => (
            <tr key={`a-opt-${idx}`}>
              <td>{account.company}</td>
              <td>{account.bank}</td>
              <td>{formatDate(account.lastOptIn)}</td>
              {/* <StyledTd><Row><Toggle isOn={account.optIn} /></Row></StyledTd> */}
              <td>
                <Row>
                  <Toggle 
                    id={`toggle-opt-out-accounts-${idx}`}
                    isOn={account.optIn} 
                    handleToggle={() => handleClick(account)}
                  />
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    : <NoDataTitle>No accounts opted in</NoDataTitle>

  const cardTable = optInData.cards.length
   ? <table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Card</th>
            <th>Date</th>
            <StyledTh>Opt Out Of Data Collection</StyledTh>
          </tr>
        </thead>
        <tbody>
          {optInData.cards.map((card, idx) => (
            <tr key={`c-opt-${idx}`}>
              <td>{card.company}</td>
              <td><CardTd>{`x${card.lastFour}`}</CardTd></td>
              <td>{formatDate(card.lastOptIn)}</td>
              <td>
                <Row>
                  <Toggle 
                    id={`toggle-opt-out-cards-${idx}`}
                    isOn={card.optIn} 
                    handleToggle={() => handleClick(card)}
                  />
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    : <NoDataTitle>No cards opted in</NoDataTitle>

  const showConfirmation = !confirm && currentData
    ? <OptOutContainer>
        <Row>
          <OptOutText>{currentData.company}</OptOutText>
          <Toggle id={`out-out-one`} isOn={optOutOne} handleToggle={handleToggleOne} />
        </Row>
        <Row>
          <OptOutText>All companies</OptOutText>
          <Toggle id={`opt-out-all`} isOn={optOutAll} handleToggle={handleToggleAll} />
        </Row>
        <Row style={{justifyContent: 'center'}}>
          <Button onClick={handleCancel} >Cancel</Button>
          <Button onClick={handleOptOut} >Confirm</Button>
        </Row>
      </OptOutContainer>
    : <ConfirmMessage>
        <Row style={{margin: '2rem'}}>{message}</Row>
        <Row><Button onClick={handleCancel}>Back</Button></Row>
      </ConfirmMessage>

const showTrackingTable = trackingData
? trackingData.length
   ? <TableContainer>
       <ToolkitProvider
         keyField="id"
         data={ trackingData }
         columns={ columns }
         exportCSV
       >
         {
           props => (
             <DataTableContainer>
               <BootstrapTable 
                 { ...props.baseProps } 
                 striped
                 hover
               />
               {/* <ExportButton 
                 style={{fontSize: '1.2em'}} 
                 text={'Download Data'} 
                 { ...props.csvProps } 
               /> */}
             </DataTableContainer>
           )
         }
       </ToolkitProvider>
       {showModal}
     </TableContainer>
  : <NoDataTitle>There are no companies tracking your data.</NoDataTitle>
: <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>

const showIncomeTable = dashboardData.income
? dashboardData.income.length
   ? <TableContainer>
       <ToolkitProvider
         keyField='company'
         data={ dashboardData.income }
         columns={ incomeColumns }
         exportCSV
       >
         {
           props => (
             <DataTableContainer>
               <BootstrapTable 
                 { ...props.baseProps } 
                 striped
                 hover
               />
               {/* <ExportButton 
                 style={{fontSize: '1.2em'}} 
                 text={'Download Data'} 
                 { ...props.csvProps } 
               /> */}
             </DataTableContainer>
           )
         }
       </ToolkitProvider>
       {showModal}
     </TableContainer>
  : <NoDataTitle>There are no companies tracking your Income Score.</NoDataTitle>
: <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>

  const optOutOptions = showView
   ? <Content>
        <CardTitle><Icon><FontAwesomeIcon icon="times" className="icon" /></Icon>Opt In Settings</CardTitle>
        <CardText>
          Please note that if you decide to opt-out, you may lose 
          access to valuable services.
        </CardText>
        <Row>
          <CardTitle>
            {currentData.cardId ? "Card x" : <>Account -&nbsp;</>}
            {currentData.cardId ? currentData.lastFour : <Capitalize>{currentData.bank}</Capitalize>}
          </CardTitle>
        </Row>
        <Row>
          {showConfirmation}
        </Row>
      </Content>
   : <Content>
        <CardTitle><Icon><FontAwesomeIcon icon="check-circle" className="icon" /></Icon>Opt Out</CardTitle>
        <CardText>
          These companies are currently using your data to provide you 
          with valuable discounts and other services. You can use the links to 
          opt-out of data collection. Please note that if you decide to opt-out, you may lose 
          access to valuable services. Opting out may take up to several days depending on the company.
        </CardText>
        <CardTitle>Accounts</CardTitle>
        {accountTable}

        <CardTitle>Cards</CardTitle>
        {cardTable}

        <CardTitle>Income Score</CardTitle>
        {showIncomeTable}

        <CardTitle><Icon><FontAwesomeIcon icon="search" className="icon" /></Icon>Who's Tracking You</CardTitle>
        <CardText>
          These companies are currently using your data to provide you with valuable discounts 
          and other services. You can use the links below to download your data for a specific company, or download 
          all of your collected data. Please note that if you decide to opt-out, you may lose access to valuable 
          services.
        </CardText>
        {showTrackingTable}
      </Content>


  return (
    <Container>
      <SideNav view={view} setView={setView} />
      {optOutOptions}
    </Container>
  );
}

export default Opt;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: 768px) {
    height: 100vh;
  }
  @media (max-width: 375px) {
    padding: 1.5rem;
  }
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
  height: 4rem;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.1em;
`

const Icon = styled.div`
  display: flex;
  align-content: center;
  padding: .3rem auto;
  color: ${({ theme }) => theme.colors.primary };
`

const CardTd = styled.span`
  text-transform: none;
`

const Capitalize = styled.span`
  text-transform: capitalize;
`

const StyledTh = styled.th`
  text-align: center;
`

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary };
  margin: 1rem 1rem 2rem 1rem;
  font-size: 1.1em;
  height: 2.5rem;
  width: 6rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`

const OptOutContainer = styled.div`
  width: 70%;
  
  & div {
    justify-content: space-between;
  }
`

const OptOutText = styled.div`
  font-size: 1.2em;
  margin: 1rem;
`

const ConfirmMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.3em;
  margin: 1rem;
`

const TableContainer = styled.div`
  font-size: 1em;
  margin: 0;
  padding: 0;
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.textSecondary };
  cursor: pointer;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  div.react-bootstrap-table {
    max-height: 30rem;
    overflow: scroll;
  }

  div.react-bootstrap-table table {
    width: auto;
    font-size: .8em;
    border: 0;
    text-transform: none;
  }
`

const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalTitle = styled.div`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`

const ModalTableTitle = styled.div`
  font-size: 1.3em;
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
`
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import { modalStyles } from '../../Theme';
import Modal from 'react-modal';
import CreateOffer from './CreateOffer';
import BootstrapTable from 'react-bootstrap-table-next';
import Toggle from '../common/ToggleSmall';
// import { yourOffers } from '../../mockData';
import { formatDateSlash, BASE_URL, isTokenValid } from '../../services/utils';
import isLoading from '../common/isLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Modal.setAppElement('#root');

const YourOffers = ({ view, setView, dashboardData, getToken, fetchOffersData, setMessage, message, setLoading, setErrorStatus }) => {
  const [offerView, setOfferView] = useState('main');
  // const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmP, setOpenModalConfirmP] = useState(false);
  const [openModalErr, setOpenModalErr] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);

  const deleteOffer = useCallback(async({ id }) => {
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(`${BASE_URL}/merchants/offers/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        })
      })
      .then(async(res) => {
        let data = await res.json();
        if (res.ok) {
          fetchOffersData({ token, type: 'merchants' });
          setOfferView('main');
        } else {
          setMessage(data.message);
          setOpenModalErr(true);
        }
      })
      .catch(err => {
        console.log("Error deleting offer", err)
        setErrorStatus(true);
      })
    }
  }, [getToken, fetchOffersData, setMessage, setErrorStatus]);

  const publishOffer = useCallback(async({ isActive, id }) => {
    setLoading(true);
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(`${BASE_URL}/merchants/offers/${id}/publish`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({ 
            is_active: isActive
        })
      })
      .then(async(res) => {
        let data = await res.json();
        setLoading(false);
        if (res.ok) {
          fetchOffersData({ token, type: 'merchants' });
        } else {
          setMessage(data.message);
          setOpenModalErr(true);
        }
      })
      .catch(err => {
        setLoading(false);
        setErrorStatus(true);
        console.log("Error updating offer", err)
      })
    }
  }, [getToken, fetchOffersData, setMessage, setLoading, setErrorStatus]);

  // const handleClose = useCallback(() => {
  //   setOpenModal(false);
  //   setCurrentOffer(null);
  // }, [setOpenModal, setCurrentOffer]);

  const handleToggle = useCallback((data) => {
    if (data.offer.is_active) {
      setCurrentOffer(data);
      setOpenModalConfirmP(true);
    } else {
      publishOffer({ isActive: true, id: data.offer.id })
    }
  }, [publishOffer])

  const tableDateFormatter = (cell, row) => {
    if (cell) {
      return (
        <span>{ formatDateSlash(cell) }</span>
      );
    } else {
      return (
        <span></span>
      )
    }
  };

  // const checkFormatter = (cell, row) => {
  //   return (
  //     <>{cell === true
  //         ? <Check><i className="fas fa-check"></i></Check> 
  //         : <NotCheck><i className="fas fa-times"></i></NotCheck>}</>
  //     );
  // };

  const toggleFormatter = (cell, row) => {
    return (
      <Row style={{height: '1.7rem'}}>
        <Toggle
          id={`toggle-publish-offers-${row.offer.id}`}
          isOn={cell} 
          handleToggle={() => handleToggle(row)}
        />
      </Row>
    )
  }

  const actionsFormatter = (cell, row) => {
    return (
     <ActionsRow>
      <ActionsIcon onClick={() => {setCurrentOffer(row); setOfferView('edit offer')}}><FontAwesomeIcon icon="edit" /></ActionsIcon>
      {/* <ActionsIcon><i className="fas fa-bullseye"></i></ActionsIcon>
      <ActionsIcon><i className="fas fa-trash-alt"></i></ActionsIcon>
      <ActionsIcon>Publish</ActionsIcon>
      <ActionsSpan>|</ActionsSpan>
      <ActionsIcon onClick={() => {setCurrentOffer(row); setOpenModal(true)}}>Target</ActionsIcon>
      <ActionsSpan>|</ActionsSpan>
      <ActionsIcon onClick={() => {setCurrentOffer(row); setOpenModalConfirm(true)}}>Delete</ActionsIcon> */}
     </ActionsRow>
    );
  };

  const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
  }]

  const columns = [{
    dataField: 'offer.id',
    text: 'Offer ID',
    hidden: true,
  }, {
    dataField: 'offer.name',
    text: 'Name',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'offer.create_date',
    text: 'Create Date',
    formatter: tableDateFormatter,
    headerStyle: {width: '8rem', textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    hidden: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'offer.start_date',
    text: 'Start Date',
    formatter: tableDateFormatter,
    headerStyle: {textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'offer.end_date',
    text: 'End Date',
    formatter: tableDateFormatter,
    headerStyle: {textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'offer.is_active',
    text: 'Published',
    headerStyle: {textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    formatter: toggleFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'offer.target',
    text: 'Targeted',
    headerStyle: {width: '8rem', textAlign: 'center'},
    style: {textAlign: 'center'},
    sort: true,
    hidden: true,
    // formatter: checkFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'edit',
    text: 'Edit',
    formatter: actionsFormatter,
    headerStyle: {minWidth: '12rem', textAlign: 'center'},
    style: {textAlign: 'center'},
  }];

  const showModalConfirm = currentOffer
    ? <Modal
        isOpen={openModalConfirm}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Message style={{minWidth: '12rem', maxWidth: '21rem'}}>
            Are you sure you want to delete <br/>{currentOffer.offer.name}?
          </Message>
          <Row>
            <Button onClick={() => {setOpenModalConfirm(false); setMessage('')}}>Cancel</Button>
            <Button onClick={() => {setOpenModalConfirm(false); deleteOffer({id: currentOffer.offer.id})}}>Delete</Button>
          </Row>
        </ModalContent>
      </Modal>
    : <></>

  const showModalConfirmP = currentOffer
    ? <Modal
        isOpen={openModalConfirmP}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Message style={{minWidth: '12rem', maxWidth: '21rem'}}>
            Are you sure you want to pause <br/>{currentOffer.offer.name}?
          </Message>
          <Row>
            <Button onClick={() => {setOpenModalConfirmP(false); setMessage('')}}>Cancel</Button>
            <Button onClick={() => {setOpenModalConfirmP(false); publishOffer({ isActive: false, id: currentOffer.offer.id })}}>Pause</Button>
          </Row>
        </ModalContent>
      </Modal>
    : <></>
  
  const modalErr =
    <Modal
      isOpen={openModalErr}
      style={modalStyles}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{maxWidth: '21rem'}}>
          An error occured please contact customer support using our form. &nbsp;
        </Message>
        {message && <Message>{message}</Message>}
        <Row>
          <Button onClick={() => {setOpenModalErr(false); setView('support'); setMessage('')}}>Support</Button>
          <Button onClick={() => {setOpenModalErr(false); setMessage('')}}>Close</Button>
        </Row>
      </ModalContent>
    </Modal>

  const showTable = dashboardData.offers.length
    ? <BootstrapTable keyField='offer.id' data={dashboardData.offers} columns={columns} defaultSorted={defaultSorted} hover/>
    : <Row>
        <Text>No offers.&nbsp;<Link onClick={() => setView('create offer')}>Click here</Link>&nbsp;to create new offers.</Text>
      </Row>

  switch(offerView) {

    case 'main': 
      return (
        <Container>
          <SideNav view={view} setView={setView} />
          <Content>
            <Card>
              <CardTitle><Icon><FontAwesomeIcon icon="tags" /></Icon>Your Offers</CardTitle>
              <TableContainer>
                {/* <BootstrapTable keyField='offerId' data={dashboardData.offers} columns={columns} hover/> */}
                {showTable}
              </TableContainer>
            </Card>
          </Content>
          {modalErr}
          {showModalConfirm}
          {showModalConfirmP}
        </Container>
      )
    
    case 'edit offer':
      return (
        <>
          <CreateOffer 
            view={view}
            setView={setView}
            setOfferView={setOfferView}
            getToken={getToken}
            currentOffer={currentOffer}
            setCurrentOffer={setCurrentOffer}
            setOpenModalConfirm={setOpenModalConfirm}
            fetchOffersData={fetchOffersData}
            setErrorStatus={setErrorStatus}
          />
          {showModalConfirm}
          {modalErr}
        </>
      )

    default: return null;
  }
}

export default isLoading(YourOffers);

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
  color: ${({ theme }) => theme.colors.text };
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1em;
`

const ActionsIcon = styled.div`
  color: ${({ theme }) => theme.colors.text };
  cursor: pointer;
  margin: .2rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`

// const ActionsSpan = styled.div`
//   margin: .2rem;
//   font-weight: 500;
// `

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
  margin-bottom: 2rem;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 18rem;
`

const TableContainer = styled.div`
  font-size: 1em;
`

// const Check = styled.span`
//   color: ${({ theme }) => theme.colors.primary }
// `

// const NotCheck = styled.span`
//   color: ${({ theme }) => theme.colors.toggleOff }
// `

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.1em;
  height: 2rem;
  padding: .4rem 1.2rem;
  margin: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.1em;
  margin: 1rem 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text };
`

const Text = styled.div`
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
`

const Link = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
`
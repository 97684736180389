import React from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import ContactForm from '../ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Support = ({ view, setView, getToken, profileData, setErrorStatus }) => {
  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <Card>
          <CardTitle><Icon><FontAwesomeIcon icon="edit" /></Icon>Support</CardTitle>
          <CardText>
            Please use the form below if you have any questions and a representative will get back to you within 
            48 business hours. For immediate questions please email 
            <Link onClick={() => window.open("mailto:customerservice@mybitdoor.com", "_blank")}> customerservice@mybitdoor.com</Link>.
          </CardText>
          <ContactForm 
            getToken={getToken} 
            profileData={profileData}
            setErrorStatus={setErrorStatus}
            setView={setView}
          />
        </Card>
      </Content>
    </Container>
  );
}

export default Support;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.1em;
  margin-bottom: 2rem;
`

const Link = styled.a`
  cursor: pointer;
`


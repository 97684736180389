import React, { useState, useRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

function Accordion({ title, text }) {
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');
  const themeContext = useContext(ThemeContext);
  const content = useRef(null);

  const toggle = () => {
    setActive(active === '' ? 'active' : '');
    setHeight(
      active === 'active' ? '0px' : `${content.current.scrollHeight}px`
    )
  }

  return (
    <Container>
      <Button onClick={toggle}
        style={{ backgroundColor: `${active === 'active' ? themeContext.colors.primary : '#fff'}`, color: `${active === 'active' ? '#fff' : themeContext.colors.primary}` }}
      >
        <Title>{title}</Title>
        <Icon style={{ transform: `${active === 'active' ? 'rotate(90deg)': ''}`}}><i className="fas fa-chevron-right"></i></Icon>
      </Button>
      <Body ref={content} style={{ maxHeight: `${height}` }}>
        <Text
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Body>
    </Container>
  );
}

export default Accordion;

const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  width: 100%;
  height: 2.5rem;
  padding: .2rem;
  border-radius: 3px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;

  &:hover {
    background-color: #ccc;
  }

`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.3em;
  padding: .2rem .5rem;
  @media (max-width: 414px) {
    font-size: .9em;
  }
  @media (max-width: 375px) {
    font-size: .9em;
  }
  @media (max-width: 320px) {
    font-size: .8em;
  }
`

const Body = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.3s ease;
`

const Text = styled.div`
  padding: 1rem .5rem 0 .5rem;
  vertical-aign: middle;
  color: ${({ theme }) => theme.colors.textSecondary }
`

const Icon = styled.div`
  margin-left: auto;
  margin-right: .5rem;
  font-weight: 700;
  transition: transform 0.3s ease;
`
import React from 'react';
import styled from 'styled-components';
import { formatDateLong } from '../../services/utils';
import SlateReader from '../common/SlateReader';
import { Desktop, Mobile } from '../../Theme';

const OfferDetails = ({ offerData, handleBack, setOpenModal }) => {

  const showDummy = offerData
    ? offerData.offer.image
      ? <Logo src={offerData.offer.image} alt={offerData.offer.name} />
      : <Dummy>
        <DummyTitle>No Image</DummyTitle>
        </Dummy>
    : null
  
  const showDate = offerData
    ? offerData.offer.end_date
      ? <Date>{`${formatDateLong(offerData.offer.start_date)} - ${formatDateLong(offerData.offer.end_date)}`}</Date>
      : <Date>{formatDateLong(offerData.offer.start_date)}</Date>
    : null

  const showDescription = offerData
    ? offerData.offer.description
      ? <SlateReader description={offerData.offer.description} offerUrl={offerData.offer.url} />
      : null
    : null
  
  if (offerData) {
    return (
      <Container>
        <Desktop>
          <Card>
            <Row>
              {showDummy}
              <Description>
                <h3>{offerData.offer.name}</h3>
                <strong>{offerData.offer.offer_type}</strong>
                {showDate}
              </Description>
              {
                offerData.card
                  ? <Column>
                      <Description>Linked Card: {offerData.card.lastFour}</Description>
                    </Column>
                  : <Button onClick={() => setOpenModal(true)}>Link Offer</Button>
              }
            </Row>
            <Terms>
              {showDescription}
            </Terms>
            <Row>
              <Link onClick={handleBack}>Back to Offers</Link>
            </Row>
          </Card>
        </Desktop>
        <Mobile>
            <Row>
              {showDummy}
            </Row>
            <Row style={{margin: '2rem auto'}}>
              <Description>
                <h3>{offerData.offer.name}</h3>
                <strong>{offerData.offer.offer_type}</strong>
              </Description>
              <ButtonMobile onClick={() => setOpenModal(true)}>Link Offer</ButtonMobile>
            </Row>
            {showDate}
          <Terms>
            {showDescription}
          </Terms>
          <Row>
            <Link onClick={handleBack}>Back to Offers</Link>
          </Row>
        </Mobile>
      </Container>
    );
  }
}

export default OfferDetails;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

// const Title = styled.div`
//   font-size: 1.8em;
//   font-weight: 700;
// `

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: .25rem;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  transition: box-shadow .3s ease-in-out;
  border: 3px solid ${({ theme }) => theme.colors.primary };

  @media (max-width: 414px) {
    margin: .5rem 1rem;
  }
  @media (max-width: 375px) {
    box-shadow: none;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  flex-direction: column;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  width: 12rem;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 400;
  padding: .2rem;
  text-align: left;
  margin: auto;

  h3 {
    margin: 0;
  }

  @media (max-width: 414px) {
    font-size: 1em;
  }
`

const Logo = styled.img`
  max-width: 10rem;
  margin: auto auto auto 2rem;
  @media (max-width: 768px) {
    margin: auto;
  }
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: auto 2rem auto auto;
  font-size: 1.2em;
  height: 3.5rem;
  padding: .7rem 2rem;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const ButtonMobile = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: auto;
  font-size: 1em;
  height: 2.5em;
  padding: .5rem .7rem;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Link = styled.span`
  font-weight: 500;
  font-size: 1.2em;
  margin: 1rem auto;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.buttonPrimary };
`

const Terms = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
  margin-top: 2rem;
  padding: 2rem;
  font-size: 1.2em;
  border-top: 1px solid grey;
  color: ${({ theme }) => theme.colors.text };
  text-align: left;
  @media (max-width: 768px) {
    max-width: 90%;
  }
  @media (max-width: 414px) {
    padding: 1rem;
  }
`

const Dummy = styled.div`
  width: 8rem;
  height: 8rem;
  margin-left: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.textSecondary };
  @media (max-width: 768px) {
    margin: auto;
  }
`

const DummyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 500;
  margin-top: auto;
  height: 100%;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const Date = styled.span`
  width: 18rem;
  margin-top: .2rem;
  font-size: 1em;
  @media (max-width: 414px) {
    text-align: center;
  }
`

import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import { modalStyles } from '../../Theme';
import Modal from 'react-modal';
import OfferForm from './OfferForm';
import OfferPreview from './OfferPreview';
import { BASE_URL, formatDateLongR, formatDatePicker, isTokenValid } from '../../services/utils';
import isLoading from '../common/isLoading';
import imageCompression from 'browser-image-compression';
import SlateEditor from '../common/SlateEditor';
import { initialDescription } from '../common/SlateComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { mapsInputStyle } from '../../Theme';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

Modal.setAppElement('#root');

const CreateOffer = ({ view, setView, getToken, fetchOffersData, currentOffer, setErrorStatus,
  setCurrentOffer, setOfferView, setOpenModalConfirm, setLoading, dashboardData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [openModalSlate, setOpenModalSlate] = useState(false);
  const [openModalErr, setOpenModalErr] = useState(false);
  const [name, setName] = useState('');
  const [offerUrl, setOfferUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState({ value: '3', label: 'Loyalty Points' });
  const [selectedStore, setSelectedStore] = useState({ value: 'BOTH', label: 'Both' });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState('');
  const [customers, setCustomers] = useState({ value: 'ALL', label: 'All' });
  const [income, setIncome] = useState({ value: 'ALL', label: 'All' });
  const [credit, setCredit] = useState({ value: 'ALL', label: 'All' });
  const [image, setImage] = useState({ preview: '', compressed: '', raw: '' });
  const [imageDisabled, setImageDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState(initialDescription);
  const [numMonths, setNumMonths] = useState(0);
  const [mapMarker, setMapMarker] = useState({ lat: 37.7749, lng: -122.4194, zoom: 11, description: null });


  useEffect(() => {
    if (currentOffer) {
      setName(currentOffer.offer.name);
      setOfferUrl(currentOffer.offer.url);
      setSelectedOption({value: currentOffer.offer.offer_type, label: currentOffer.offer.offer_type});
      setSelectedStore({value: currentOffer.offer.location, label: currentOffer.offer.location});
      setCustomers({value: currentOffer.offer.customer_type, label: currentOffer.offer.customer_type});
      setIncome({value: currentOffer.offer.income_score, label: currentOffer.offer.income_score});
      setCredit({value: currentOffer.offer.credit_score, label: currentOffer.offer.credit_score});
      setStartDate(formatDatePicker(currentOffer.offer.start_date));
      setNumMonths(currentOffer.offer.num_months)
      if (currentOffer.offer.end_date) {
        setEndDate(formatDatePicker(currentOffer.offer.end_date));
      }
      if (currentOffer.offer.state !== null) {
        if(currentOffer.offer.state.length) setSelectedLocation(currentOffer.offer.state);
      }
      if(currentOffer.offer.description !== null) {
        if(currentOffer.offer.description.length) {
          setDescription(currentOffer.offer.description);
        }
      }
      if(currentOffer.location.latitude) {
        setMapMarker({ 
          lat: currentOffer.location.latitude, 
          lng: currentOffer.location.longitude, 
          zoom: 12,
          description: currentOffer.location.address && currentOffer.location.city
            ? `${currentOffer.location.address}, ${currentOffer.location.city}`
            : ''
        })
      }
    }
  }, [currentOffer, dashboardData])

  const uploadImage = useCallback(async({ offerId, token, method }) => {
    const file = new File([image.compressed], image.compressed.name)
    const formData = new FormData();
    formData.append('filep', file);
    fetch(`${BASE_URL}/merchants/offers/${offerId}/image`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      }),
      body: formData
    })
    .then(async(res) => {
      let data = await res.json();
      setLoading(false);
      if (res.ok) {
        if (method === 'PUT') setMessage('Image update');
        if (method === 'POST') setMessage('New offer created');
        if (method === 'UPDATE') setMessage('Offer updated!');
        setOpenModalMessage(true);
        fetchOffersData({ token, type: 'merchants' });
        setImageDisabled(true);
      } else {
        setMessage(data.message);
        setOpenModalErr(true);
      }
    })
    .catch(err => {
      console.log('error uploading image', err);
      setErrorStatus(true);
      setLoading(false);
    })
  }, [image, fetchOffersData, setLoading, setErrorStatus])

  const updateImage = useCallback(async() => {
    setLoading(true);
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      uploadImage({ token, offerId: currentOffer.offer.id, method: 'PUT' })
    }
  }, [currentOffer, uploadImage, getToken, setLoading])

  const createOffer = useCallback(async({ method }) => {
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      setLoading(true);
      fetch(`${BASE_URL}/merchants/offers${currentOffer ? `/${currentOffer.offer.id}` : ''}`, {
        method,
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          offer: {
            url: offerUrl,
            name: name,
            start_date: formatDateLongR(startDate),
            end_date: endDate && formatDateLongR(endDate),
            offer_type: selectedOption.label,
            location: selectedStore.value,
            customer_type: customers.value,
            credit_score: credit.value,
            income_score: income.value,
            state: selectedLocation,
            description,
            num_months: parseInt(numMonths)
          },
          location: {
            google_id: mapMarker.place_id,
            state: mapMarker.state,
            address: mapMarker.address,
            city: mapMarker.city,
            country: mapMarker.country,
            postal_code: mapMarker.postalCode,
            latitude: parseFloat(mapMarker.lat),
            longitude: parseFloat(mapMarker.lng)
          }
        })
      })
      .then(async(res) => {
        let data = await res.json();
        setOpenModal(false);
        if (res.ok) {
          if (image.compressed && data.offerId) {
            uploadImage({ token, offerId: data.offerId || currentOffer.offer.id, method: 'POST' })
          } else {
            setLoading(false);
            setMessage(data.message);
            setOpenModalMessage(true);
            fetchOffersData({ token, type: 'merchants' });
          }
        } else {
          setMessage(data.message);
          setLoading(false);
          setOpenModalErr(true);
        }
      })
      .catch(err => {
        console.log('error creating offer', err);
        setErrorStatus(true);
        setLoading(false);
      })
    }
  }, [getToken, fetchOffersData, currentOffer, offerUrl, name, startDate, endDate, selectedOption, numMonths, selectedStore, setErrorStatus,
      selectedLocation, customers, credit, income, image, uploadImage, setLoading, description, mapMarker])

  const updateOffer = useCallback(async() => {
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      setLoading(true);
      fetch(`${BASE_URL}/merchants/offers/${currentOffer.offer.id}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          offer: {
            url: offerUrl,
            name: name,
            start_date: formatDateLongR(startDate),
            end_date: endDate && formatDateLongR(endDate),
            type: selectedOption.label,
            location: selectedStore.value,
            customer_type: customers.value,
            credit_score: credit.value,
            income_score: income.value,
            state: selectedLocation,
            description,
            num_months: parseInt(numMonths)
          },
          location: {
            google_id: mapMarker.place_id,
            state: mapMarker.state,
            address: mapMarker.address,
            city: mapMarker.city,
            country: mapMarker.country,
            postal_code: mapMarker.postalCode,
            latitude: parseFloat(mapMarker.lat),
            longitude: parseFloat(mapMarker.lng)
          }
        })
      })
      .then(async(res) => {
        let data = await res.json();
        setOpenModal(false);
        if (res.ok) {
          if (image.compressed && !imageDisabled) {
            uploadImage({
              token,
              offerId: currentOffer.offer.id,
              method: 'UPDATE'
            })
          } else {
            setLoading(false);
            setMessage(data.message);
            setOpenModalMessage(true);
            fetchOffersData({ token, type: 'merchants' });
          }
        } else {
          setLoading(false);
          setMessage(data.message);
          setOpenModalErr(true);
        }
      })
      .catch(err => {
        console.log('error updating offer', err);
        setErrorStatus(true);
      })
    }
  }, [getToken, fetchOffersData, currentOffer, offerUrl, name, startDate, endDate, selectedOption, description, selectedStore, setErrorStatus,
      numMonths, selectedLocation, customers, credit, income, setLoading, image, uploadImage, imageDisabled, mapMarker])

  const isFormValid = useCallback(() => {
    if (name && startDate) {
      setOpenModal(true);
    } else {

      let messages = {}

      if (!name) {
        messages.name = 'Name required'
      }

      if (!startDate) {
        messages.startDate = 'Start date required'
      }
      
      if (endDate && endDate < startDate) {
        messages.endDate = 'End date must be after start date'
      }
      
      setErrors({messages})
      setMessage('Please check required fields');
      setOpenModalMessage(true);

    }
  }, [name, startDate, endDate])

  const handleCancel = () => {
    setOfferView('main');
    setCurrentOffer(null);
  };

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const handleSelectImg = useCallback(async(e) => {
    // e.persist()
    if (e.target.files.length) {
      const imageFile = e.target.files[0];
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 500,
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        const convertedCompressed = await (blobToFile(compressedFile, compressedFile.name));
        setImage({
          preview: URL.createObjectURL(compressedFile),
          // raw: e.target.files[0],
          compressed: convertedCompressed
        });
        setImageDisabled(false);
      } catch (error) {
        console.log('error uploading image', error);
        setErrorStatus(true);
      }
    }
  }, [setErrorStatus])

  const handleAddress = useCallback(async(data) => {
    try {
          const response = await geocodeByPlaceId(data.place_id);
          const latLng = await getLatLng(response[0])
          const addr = response[0].formatted_address.split(', ');
          const stateZip = addr[2].split(' ');
          setMapMarker({
            address: addr[0],
            city: addr[1],
            state: stateZip[0],
            country: addr[3],
            postalCode: stateZip[1],
            lat: latLng.lat,
            lng: latLng.lng,
            zoom: 12,
            place_id: data.place_id,
            description: `${addr[0]}, ${addr[1]}`
          })
       }
    catch {
            console.log('Error retrieving address');
            setErrorStatus(true);
          }
  }, [setErrorStatus, setMapMarker])

  const showTitle = currentOffer
    ? <Row style={{justifyContent: 'space-between'}}>
        <CardTitle><Icon><FontAwesomeIcon icon="edit" /></Icon>Edit Offer</CardTitle>
        <Link onClick={() => {setOpenModalConfirm(true)}}>Delete Offer</Link>
      </Row>
    : <CardTitle><Icon><FontAwesomeIcon icon="plus-square" /></Icon>Create Offer</CardTitle>
  
  const showButton = currentOffer
    ? <Row style={{justifyContent: 'center'}}>
        <Button onClick={handleCancel}>Back</Button>
        <Button onClick={isFormValid}>Update</Button>
      </Row>
    : <Row style={{justifyContent: 'center'}}>
        <Button onClick={isFormValid}>Save</Button>
      </Row>


  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <Card>
          {showTitle}
          <OfferForm
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            name={name}
            setName={setName}
            customers={customers}
            setCustomers={setCustomers}
            income={income}
            setIncome={setIncome}
            credit={credit}
            setCredit={setCredit}
            offerUrl={offerUrl}
            setOfferUrl={setOfferUrl}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            image={image}
            handleSelectImg={handleSelectImg}
            currentOffer={currentOffer}
            imageDisabled={imageDisabled}
            setImageDisabled={setImageDisabled}
            updateImage={updateImage}
            errors={errors}
            description={description}
            setDescription={setDescription}
            setOpenModalSlate={setOpenModalSlate}
            numMonths={numMonths}
            setNumMonths={setNumMonths}
            handleAddress={handleAddress}
            mapMarker={mapMarker}
          />
          {showButton}
        </Card>
      </Content>
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Row style={{justifyContent: 'center'}}>
            <Title>Preview</Title>
          </Row>
          <OfferPreview 
            image={image}
            selectedOption={selectedOption}
            startDate={startDate}
            endDate={endDate}
            name={name}
            currentOffer={currentOffer}
            description={description}
            offerUrl={offerUrl}
          />
          <Row style={{justifyContent: 'center'}}>
            <Button style={{height: '3rem'}} onClick={() => setOpenModal(false)}>Cancel</Button>
            {currentOffer 
              ? <Button style={{height: '3rem'}} onClick={updateOffer}>Save Offer</Button>
              : <Button style={{height: '3rem'}} onClick={() => createOffer({ method: 'POST' })}>Save Offer</Button>
            }
          </Row>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={openModalMessage}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContentMessage>
          <Message>{message}</Message>
          <Row style={{justifyContent: 'center', marginBottom: '0'}}>
            {message === 'Created new offer!' && <ButtonS onClick={() => {setOpenModalMessage(false); setView('your offers'); setMessage('')}}>Offers</ButtonS>}
            {message === 'Image updated' && <ButtonS onClick={() => {setOpenModalMessage(false); setOfferView('main'); setMessage('')}}>Offers</ButtonS>}
            <ButtonS onClick={() => {setOpenModalMessage(false); setMessage('')}}>Close</ButtonS>
          </Row>
        </ModalContentMessage>
      </Modal>
      <Modal
        isOpen={openModalErr}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContentMessage>
          <Message style={{maxWidth: '21rem'}}>
            An error occured please contact customer support using our form. &nbsp;
          </Message>
          {message}
          <Row style={{justifyContent: 'center', marginBottom: '0'}}>
            <ButtonS onClick={() => {setOpenModalErr(false); setView('merchant support'); setMessage('')}}>Support</ButtonS>
            <ButtonS onClick={() => {setOpenModalErr(false); setMessage('')}}>Close</ButtonS>
          </Row>
        </ModalContentMessage>
      </Modal>
      <Modal
        isOpen={openModalSlate}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent style={{textAlign: 'left', maxWidth: '35rem', height: 'auto'}}>
          <Row style={{justifyContent: 'flex-end', margin: '0'}}>
           <CloseButton onClick={() => setOpenModalSlate(false)}>
             <FontAwesomeIcon icon="times" />
           </CloseButton>
         </Row>
          <SlateEditor description={description} setDescription={setDescription} />
          <Row style={{height: '2rem', marginBottom: '0', justifyContent: 'flex-end'}}>
            <ButtonS style={{fontSize: '1em'}} onClick={() => setOpenModalSlate(false)}>Done</ButtonS>
          </Row>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default isLoading(CreateOffer);

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
  color: ${({ theme }) => theme.colors.text };
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`
const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const ButtonS = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.1em;
  height: 2rem;
  padding: .4rem 1.2rem;
  margin: .5rem .5rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.1em;
  height: 2.5rem;
  padding: .5rem 1.2rem;
  margin: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 18rem;
  padding: 0; 
`

const ModalContentMessage = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
  min-width: 12rem;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.1em;
  margin: 1rem 2rem;
  text-align: center;
  min-width: 12rem;
  color: ${({ theme }) => theme.colors.text };
`

const Link = styled.span`
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => theme.colors.error};
  }
`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 700;
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`
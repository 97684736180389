import React from 'react';
import SideNav from './SideNav';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'react-loading';


const Tracking = ({ view, setView, companyList }) => {
  const sharedData = companyList
    ? companyList.useCases
      ? <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <StyledTh>Identifiers</StyledTh>
              <StyledTh>Personal Characteristics</StyledTh>
              <StyledTh>Commercial Information</StyledTh>
              <StyledTh>Geolocation Data</StyledTh>
            </tr>
          </thead>
          <tbody>
            {companyList.map((c, idx) => (
              <tr key={idx}>
                <td>{c.company}</td>
                <StyledTd>{c.useCases.identifiers ? <Check><FontAwesomeIcon icon="check" /></Check> : <NotCheck><FontAwesomeIcon icon="check" /></NotCheck>}</StyledTd>
                <StyledTd>{c.useCases.personalCharacteristics ? <Check><FontAwesomeIcon icon="check" /></Check> : <NotCheck><FontAwesomeIcon icon="check" /></NotCheck>}</StyledTd>
                <StyledTd>{c.useCases.commercialInformation ? <Check><FontAwesomeIcon icon="check" /></Check> : <NotCheck><FontAwesomeIcon icon="check" /></NotCheck>}</StyledTd>
                <StyledTd>{c.useCases.geolocationData ? <Check><FontAwesomeIcon icon="check" /></Check> : <NotCheck><FontAwesomeIcon icon="check" /></NotCheck>}</StyledTd>
              </tr>
            ))}
          </tbody>
        </table>
      : <NoDataTitle>There are no companies using your shared data.</NoDataTitle>
    : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>
            
  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <CardTitle><Icon><FontAwesomeIcon icon="id-card" className="icon" /></Icon>Your Shared Data</CardTitle>
        <CardText>
          These companies are currently using your data to provide you with valuable discounts 
          and other services. Below, you see the types of data being used. Please note that if you decide to opt-out, 
          you may lose access to valuable services.
        </CardText>
        {sharedData}
        <Information>
          <p>
            <Bold>Identifiers</Bold> –Identifiers including name, postal address, unique personal identifier, online identifier, IP address, email address
          </p>
          <p>
            <Bold>Personal Characteristics</Bold> –Bank account number, credit card number, debit card number
          </p>
          <p>
            <Bold>Commercial Information</Bold> –Purchasing history to make sure you get the offers that align with your preferences
          </p>
          <p>
            <Bold>Geolocation Data</Bold> –Purchasing locations that make sure the offers you get are convenient
          </p>
        </Information>
      </Content>
    </Container>
  );
}

export default Tracking;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: 768px) {
    height: 100vh;
  }
  @media (max-width: 414px) {
    height: 100%;
  }
  @media (max-width: 375px) {
    padding: 1.5rem;
  }
`

const CardTitle = styled.div`
  display: flex;
  color: #424242;
  font-size: 1.6em;
  font-weight: 700;
  align-content: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.1em;
  @media (max-width: 375px) {
    margin-top: 2rem;
  }
`

const Icon = styled.div`
  display: flex;
  align-content: center;
  padding: .3rem auto;
  color: ${({ theme }) => theme.colors.primary };
`

const Check = styled.span`
  color: ${({ theme }) => theme.colors.primary }
`

const NotCheck = styled.span`
  color: ${({ theme }) => theme.colors.toggleOff }
`

const Information = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  margin: 2rem 4rem;
  font-size: 1.1em;
  @media (max-width: 414px) {
    margin: 1rem 2rem;
  }
`

const Bold = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary };
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
  height: 4rem;
  @media (max-width: 375px) {
    text-align: center;
  }
`

const StyledTd = styled.td`
  text-align: center;
`

const StyledTh = styled.th`
  text-align: center;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import ProgressBar from 'react-customizable-progressbar';
import GradientSVG from './GradientSVG';
import { creditRange } from '../../services/utils';

const CreditCircleBar2 = ({ value, total, min, gradientId, lower, higher }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <GradientSVG 
        startColor="green"
        midColor="yellow"
        endColor="red"
        gradientId={'gradient-credit'}
        rotation={0}
      /> 
      <ProgressBar
        radius={90}
        // progress={(value/total)*100}
        progress={(value-300)/550*100}
        cut={120}
        rotate={-210}
        strokeWidth={6}
        strokeColor="#ffffff"
        trackStrokeWidth={25}
        // trackStrokeColor="#007119"
        trackStrokeColor={`url(#${gradientId})`}
        // trackStrokeLinecap="butt"
        pointerRadius={6}
        pointerStrokeWidth={3}
        pointerStrokeColor="#fff"
        pointerFillColor="#fff"
      >
        <div 
          style={{ 
            // fontFamily: themeContext.fontsNumbers, 
            position: 'absolute',
            top: 70,
            left: 70,
            color: themeContext.colors.text 
          }}
        >
          <Column>
            <Value>{value}</Value>
            <Rating>{creditRange(parseInt(value))}</Rating>
          </Column>
        </div>
        <div 
          style={{ 
            fontSize: 14, 
            marginTop: -65, 
            marginLeft: -10, 
            fontWeight: 'bold', 
            fontFamily: themeContext.fontNumbers, 
            color: themeContext.colors.text 
            }}
          >
          300
        </div>
        <div 
          style={{ 
            fontSize: 14, 
            marginTop: -21, 
            marginLeft: 212, 
            fontWeight: 'bold', 
            fontFamily: themeContext.fontNumbers, 
            color: themeContext.colors.text 
            }}
          >
          850
        </div>
      </ProgressBar>
    </>
  );
}
export default CreditCircleBar2;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Rating = styled.span`
  font-size: 1.5em;
  text-align: center;
  font-weight: 500;
  top: 4rem;
  left: 20px;
`

const Value = styled.span`
  font-size: 4em;
  font-family: ${({ theme }) => theme.fontNumbers };
`
import React from 'react';
import Overview from './Overview';
import Opt from './Opt';
import Offers from './Offers';
import SharedData from './SharedData';
import ConsumerRights from './ConsumerRights';
import Enroll from './Enroll';
import CreditScore from './CreditScore';
import Support from './Support';
import Faqs from './Faqs';
import Profile from '../Profile';

const ConsumerDashboard = ({ view, setView, optInData, dashboardData, companyList, trackingData, offersData, setErrorStatus, handleLogout, fetchProfileData, userType, creditInfo, fetchCreditInfo,
  webToken, getToken, fetchAccountsData, accountData, fetchCardsData, cardData, fetchDashboardData, profileData, deleteAccount, message, messageModal, setMessage, setMessageModal, setContactUs,
  fetchOffersHistory, offersDataHistory, fetchOffersData, pdfData, sendSlack
}) => {

  switch(view) {
    case 'overview':
      return(
        <Overview 
          setView={setView} 
          dashboardData={dashboardData}
          optInData={optInData}
          cardData={cardData}
          accountData={accountData}
          offersData={offersData}
          profileData={profileData}
          creditInfo={creditInfo}
        />
      )
    
    case 'opt':
      return(
        <Opt 
          view={view} 
          setView={setView} 
          dashboardData={dashboardData}
          optInData={optInData}
          webToken={webToken}
          getToken={getToken}
          fetchDashboardData={fetchDashboardData}
          setErrorStatus={setErrorStatus}
          trackingData={trackingData}
        />
      )

    case 'shared data':
      return(
        <SharedData 
          view={view} 
          setView={setView} 
          companyList={companyList} 
        />
      )

    case 'offers':
      return(
        <Offers 
          view={view} 
          setView={setView} 
          cardData={cardData}
          getToken={getToken}
          webToken={webToken}
          offersData={offersData}
          setErrorStatus={setErrorStatus}
          offersDataHistory={offersDataHistory}
          fetchOffersHistory={fetchOffersHistory}
          fetchOffersData={fetchOffersData}
        />
      )

    case 'enroll':
      return(
        <Enroll 
          view={view} setView={setView} 
          dashboardData={dashboardData.cards} 
          webToken={webToken} 
          fetchCardsData={fetchCardsData}
          fetchAccountsData={fetchAccountsData}
          cardData={cardData}
          accountData={accountData}
          getToken={getToken}
          profileData={profileData}
          setErrorStatus={setErrorStatus}
          message={message}
          setMessage={setMessage}
          setMessageModal={setMessageModal}
          pdfData={pdfData}
          sendSlack={sendSlack}
        />
      )

    case 'credit':
      return(
        <CreditScore 
          view={view} 
          setView={setView} 
          profileData={profileData}
          creditInfo={creditInfo}
        />
      )

    case 'consumer rights':
      return(
        <ConsumerRights view={view} setView={setView} />
      )

    case 'support':
      return(
        <Support 
          view={view} 
          setView={setView} 
          getToken={getToken}
          profileData={profileData}
          setErrorStatus={setErrorStatus}
        />
      )

    case 'faqs':
      return(
        <Faqs 
          view={view} 
          setView={setView} 
          getToken={getToken}
          profileData={profileData}
          setErrorStatus={setErrorStatus}
        />
      )
    
    case 'profile':
      return(
        <Profile 
          view={view} 
          setView={setView} 
          profileData={profileData}
          deleteAccount={deleteAccount}
          message={message}
          handleLogout={handleLogout}
          setErrorStatus={setErrorStatus}
          getToken={getToken}
          setMessage={setMessage}
          messageModal={messageModal}
          setMessageModal={setMessageModal}
          fetchProfileData={fetchProfileData}
          userType={userType}
          fetchCreditInfo={fetchCreditInfo}
          setContactUs={setContactUs}
          sendSlack={sendSlack}
        />
      )
    
    default: return null
  }

}

export default ConsumerDashboard;

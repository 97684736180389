import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles } from '../Theme';
import { BASE_URL, isTokenValid } from '../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Modal.setAppElement('#root');

const ContactForm = ({ description, getToken, profileData, setErrorStatus, setView }) => {
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  const [text, setText ] = useState('');
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleSubmit = useCallback(async(e) => {
    let profileName = profileData.companyName ? profileData.companyName : `${profileData.firstName} ${profileData.lastName}`;
    e.preventDefault();
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus) && profileData) {
      fetch(`${BASE_URL}/contact-us`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          message: 
          `Name: ${profileName}
          Email: ${profileData.email} 
          Message: ${text}`
        })
      })
      .then(res => res.json())
      .then(({ message }) => {
        if (message) {
          setOpenModalMessage(true);
          setModalMessage(message);
        } else {
          setErrorStatus(true);
        }
      })
      .catch(err => {
        console.log("Error with request", err)
        setErrorStatus(true);
      })
    } else {
      setErrorStatus(true);
    }
  }, [getToken, profileData, text, setErrorStatus])

  const formText = description
    ? <CardText>{description}</CardText>
    : ''

  return (
    <Container>
      <CardTitle><Icon><FontAwesomeIcon icon="envelope-open-text" /></Icon>Contact Us</CardTitle>
      <CardText>{formText}</CardText>
      <Form onSubmit={handleSubmit}>
        {/* <Row>
          <Column>
            <Label>First Name: <Span>*</Span></Label>
            <Input disabled value={profileData.firstName} onChange={e => setFirstName(e.target.value)} name='first name' type='name' placeholder='' autoComplete='first-name' />
          </Column>
          <Column>
            <Label>Last Name: <Span>*</Span></Label>
            <Input value={lastName} onChange={e => setLastName(e.target.value)} name='last name' type='name' placeholder='' autoComplete='last-name' />
          </Column>
          <Column>
            <Label>Email: <Span>*</Span></Label>
            <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='name' placeholder='' autoComplete='email' />
          </Column>
        </Row> */}
        <Row>
          <Column>
            <Label>What can we help you with?<Span>*</Span></Label>
            <TextInput required value={text} onChange={e => setText(e.target.value)} name='text' type='text' placeholder='' autoComplete='text' />
          </Column>
        </Row>
        <RowMobile>
          <Button>Submit</Button>
        </RowMobile>
      </Form>
      <Modal
        isOpen={openModalMessage}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalMessage style={{maxWidth: '21rem'}}>
            {modalMessage}
          </ModalMessage>
          <Row style={{justifyContent: 'center'}}>
            <ModalButton onClick={() => {setOpenModalMessage(false); setModalMessage(''); setView('overview')}}>Close</ModalButton>
          </Row>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default ContactForm;

const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const RowMobile = styled.div`
  display: flex;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  margin-top: .5rem;
  font-weight: 500;
  font-size: 1.05em;
  color: ${({ theme }) => theme.colors.textSecondary };
  max-width: 90%;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
  
  div > div:nth-child(2) {
    margin: auto 1.5rem;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-bottom: .5rem;
  margin-top: 1rem;
`

const Span = styled.span`
  color: #DC3545;
`

// const Input = styled.input`
//   height: 2rem;
//   margin-right: 1rem
//   margin-bottom: 1rem;
// `

const TextInput = styled.textarea`
  height: 3.5rem;
  margin-bottom: 1rem;
  resize: vertical;
  font-size: 1.4em;
  @media (max-width: 414px) {
    max-width: 90%;
  }
`

const Button = styled.button`
  color: #fff;
  font-size: 1.1em;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  height: 2.25rem;
  padding: .6rem 1.1rem;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.1em;
  font-weight: 500;
  margin: 1.5rem 1.5rem;
  min-width: 12rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1em;
  width: 5rem;
  height: 2.25rem;
  margin: .5rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`
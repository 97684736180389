import moment from 'moment';

// export const BASE_URL = process.env.REACT_APP_LOCAL_URL;

export const BASE_URL = process.env.REACT_APP_ENV === 'production' 
  ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_STAGE_URL

export const formatDate = (date) => {
  return moment(date).format('MM/DD/YY')
}

export const formatDateLongR = (date) => {
  return moment(date).format('YYYYMMDDhhmmss')
}

export const formatDateLong = (date) => {
  return moment(date, 'YYYYMMDDhhmmss').format('MM/DD/YYYY')
}

export const formatDateMonths = (date) => {
  return moment(date, 'YYYYMMDD').format('MMMM DD, YYYY')
}

export const formatDateSlash = (date) => {
  return moment(date, 'YYYYMMDD').format('MM/DD/YYYY')
}

export const formatDatePicker = (date) => {
  return moment(date, 'YYYYMMDDhhmmss').toDate()
}

export const formatTUDate = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const months = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
];

export const getRNG = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const getCompanies = (data) => {
  let list = [];
  data.accounts.forEach(({ company, accountId, useCases, optIn }) => {
    if (!list.some(d => d.company === company)) {
      let temp = {
        company,
        id: accountId,
        useCases
      }
      list.push(temp);
    }
  })
  data.cards.forEach(({ company, cardId, useCases, optIn }) => {
    if (!list.some(d => d.company === company)) {
      let temp = {
        company,
        id: cardId,
        useCases
      }
      list.push(temp);
    }
  })
  return list;
};

export const filterTrackingData = (data) => {
  let list = [];
  data.accounts.forEach(({ company, accountId, bank, consent, subscriberId, optIn, lastOptIn }) => {
    if (!list.some(d => d.id === subscriberId)) {
      let temp = {
        company,
        id: subscriberId,
        data: [
          { type: 'bank', account: bank, id: accountId, consent, optIn, lastOptIn }
        ]
      }
      list.push(temp);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === subscriberId) {
          let temp = {
            type: 'bank', account: bank, id: accountId, consent, optIn, lastOptIn
          }
          list[i].data.push(temp);
          break;
        }
      }
    }
  })
  data.cards.forEach(({ company, cardId, lastFour, consent, subscriberId, lastOptIn, optIn }) => {
    if (!list.some(d => d.id === subscriberId)) {
      let temp = {
        company,
        id: subscriberId,
        data: [
          { type: 'card', account: lastFour, id: cardId, consent, optIn, lastOptIn }
        ]
      }
      list.push(temp);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === subscriberId) {
          let temp = {
            type: 'card', account: lastFour, id: cardId, consent, optIn, lastOptIn
          }
          list[i].data.push(temp);
          break;
        }
      }
    }
  })
  return list;
};

export const filterOptOut = (data) => {
  let list = { accounts: [], cards: [] };
  data.accounts.forEach(({ company, bank, accountId, optIn, subscriberId }) => {
    if (optIn) {
      let temp = {
        company,
        bank,
        accountId,
        subscriberId,
        optIn
      }
      list.accounts.push(temp);
    }
  })
  data.cards.forEach(({ company, lastFour, cardId, optIn, subscriberId }) => {
    if (optIn) {
      let temp = {
        company,
        lastFour,
        cardId,
        subscriberId,
        optIn
      }
      list.cards.push(temp);
    }
  })
  return list;
};

export const getCardOptions = (data) => {
  let options = [];
  data.forEach(({ cardId, lastFour }) => {
    let temp = {
      label: `x${lastFour}`,
      value: cardId
    }
    options.push(temp);
  })
  return options;
};

export const stateLocations = [
  { label: 'ALABAMA', value: 'AL' },
  { label: 'ALASKA', value: 'AK' },
  { label: 'ARIZONA', value: 'AZ' },
  { label: 'ARKANSAS', value: 'AR' },
  { label: 'CALIFORNIA', value: 'CA' },
  { label: 'COLORADO', value: 'CO' },
  { label: 'CONNECTICUT', value: 'CT' },
  { label: 'DELAWARE', value: 'DE' },
  { label: 'FLORIDA', value: 'FL' },
  { label: 'GEORGIA', value: 'GA' },
  { label: 'HAWAII', value: 'HI' },
  { label: 'IDAHO', value: 'ID' },
  { label: 'ILLINOIS', value: 'IL' },
  { label: 'INDIANA', value: 'IN' },
  { label: 'IOWA', value: 'IA' },
  { label: 'KANSAS', value: 'KS' },
  { label: 'KENTUCKY', value: 'KY' },
  { label: 'LOUISIANA', value: 'LA' },
  { label: 'MAINE', value: 'ME' },
  { label: 'MARYLAND', value: 'MD' },
  { label: 'MASSACHUSETTS', value: 'MA' },
  { label: 'MICHIGAN', value: 'MI' },
  { label: 'MINNESOTA', value: 'MN' },
  { label: 'MISSISSIPPI', value: 'MS' },
  { label: 'MISSOURI', value: 'MO' },
  { label: 'MONTANA', value: 'MT' },
  { label: 'NEBRASKA', value: 'NE' },
  { label: 'NEVADA', value: 'NV' },
  { label: 'NEW HAMPSHIRE', value: 'NH' },
  { label: 'NEW JERSEY', value: 'NJ' },
  { label: 'NEW MEXICO', value: 'NM' },
  { label: 'NEW YORK', value: 'NY' },
  { label: 'NORTH CAROLINA', value: 'NC' },
  { label: 'NORTH DAKOTA', value: 'ND' },
  { label: 'OHIO', value: 'OH' },
  { label: 'OKLAHOMA', value: 'OK' },
  { label: 'OREGON', value: 'OR' },
  { label: 'PENNSYLVANIA', value: 'PA' },
  { label: 'RHODE ISLAND', value: 'RI' },
  { label: 'SOUTH CAROLINA', value: 'SC' },
  { label: 'SOUTH DAKOTA', value: 'SD' },
  { label: 'TENNESSEE', value: 'TN' },
  { label: 'TEXAS', value: 'TX' },
  { label: 'UTAH', value: 'UT' },
  { label: 'VERMONT', value: 'VT' },
  { label: 'VIRGINIA', value: 'VA' },
  { label: 'WASHINGTON', value: 'WA' },
  { label: 'WEST VIRGINIA', value: 'WV' },
  { label: 'WISCONSIN', value: 'WI' },
  { label: 'WYOMING', value: 'WY' }
];

export const incomeRange = {
  "A": { low: 0, upper: 29999 },
  "B": { low: 30000, upper: 49999 },
  "C": { low: 50000, upper: 59999 },
  "D": { low: 60000, upper: 69999 },
  "E": { low: 70000, upper: 79999 },
  "F": { low: 80000, upper: 89999 },
  "G": { low: 90000, upper: 99999 },
  "H": { low: 100000, upper: 124999 },
  "I": { low: 125000, upper: 149999 },
  "J": { low: 150000, upper: 174999 },
  "K": { low: 175000, upper: 199999 },
  "L": { low: 200000, upper: 999999 },
}

export const creditRange = (score) => {
  if (score >= 781) {
    return 'Excellent';
  } else if (score >= 720 && score < 781) {
    return 'Good';
  } else if (score >= 658 && score < 720) {
    return 'Fair';
  } else if (score >= 601 && score < 658) {
    return 'Poor';
  } else {
    return 'Very Poor';
  }
}

export const generateRandomString = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const isTokenValid = (token) => {
  return token === 'valid' ? true : false
}

export const USER_TYPES = {
  CONSUMER: "consumer",
  MERCHANT: "merchant"
}

export const isUserConsumer = (userType) => {
  return userType === USER_TYPES.CONSUMER ? true : false;
}

export const isUserMerchant = (userType) => {
  return userType === USER_TYPES.MERCHANT ? true : false;
}
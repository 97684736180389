import React from 'react';
import { 
  // useLocation, 
  useParams 
} from 'react-router-dom';
import styled from 'styled-components';
import Iframe from 'react-iframe';

const Account = () => {
  let { qstr } = useParams();
  // const useQuery = () => new URLSearchParams(qstr);
  // let query = useQuery();

  // console.log('partnerId', query.get('partnerId'))
  // console.log('signature', query.get('signature'))
  // console.log('timestamp', query.get('ttl'))
  // console.log('type', query.get('type'))
  // console.log('webhook', query.get('webhook'))
  // console.log('webhookContentType', query.get('webhookContentType'))
  // console.log('customerId', query.get('customerId'))
  return (
    <Container>
      {qstr &&
        <Iframe 
          url={`${process.env.REACT_APP_FINICITY_BASE_URL}?${qstr}&${process.env.REACT_APP_FINICITY_PARTNER_ID_P}&${process.env.REACT_APP_FINICITY_WEBHOOK}&${process.env.REACT_APP_FINICITY_WEBHOOK_TYPE}`}
          width="100%"
          height="100%"
          id="custom-iframe"
          className="custom-iframe"
          display="initial"
          position="relative"
        />
      }
    </Container>
  );
}

export default Account;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

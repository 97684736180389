import React from 'react';
import Overview from './Overview';
import CreateOffer from './CreateOffer';
import YourOffers from './YourOffers';
import Analytics from './Analytics';
import Profile from '../Profile';
import Support from './Support';

const MerchantDashboard = ({ view, setView, dashboardData, getToken, fetchOffersData, message, setMessage, 
    profileData, setErrorStatus, deleteAccount, handleLogout, userType, fetchProfileData, messageModal, setMessageModal }) => {

  switch(view) {

    case 'overview':
      return(
        <Overview 
          setView={setView} 
        />
      )

    case 'create offer':
      return(
        <CreateOffer 
          setView={setView} 
          getToken={getToken}
          fetchOffersData={fetchOffersData}
          setErrorStatus={setErrorStatus}
        />
      )
      
      case 'your offers':
        return(
          <YourOffers 
            setView={setView} 
            dashboardData={dashboardData}
            fetchOffersData={fetchOffersData}
            getToken={getToken}
            message={message}
            setMessage={setMessage}
            setErrorStatus={setErrorStatus}
          />
        )

      case 'analytics':
        return(
          <Analytics 
            setView={setView} 
            dashboardData={dashboardData}
          />
        )

      case 'merchant profile':
        return(
          <Profile 
            setView={setView} 
            message={message}
            profileData={profileData}
            deleteAccount={deleteAccount}
            handleLogout={handleLogout}
            userType={userType}
            setErrorStatus={setErrorStatus}
            getToken={getToken}
            setMessage={setMessage}
            messageModal={messageModal}
            setMessageModal={setMessageModal}
            fetchProfileData={fetchProfileData}
          />
        )

      case 'merchant support':
        return(
          <Support 
            setView={setView} 
            getToken={getToken}
            profileData={profileData}
            setErrorStatus={setErrorStatus}
          />
        )
        
    default: return null
  }
}
export default MerchantDashboard;
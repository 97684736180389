import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideNav = ({ view, setView }) => {
  return (
    <Container>
      <Row style={{fontWeight: `${view === 'overview' ? '700' : ''}`}}>
        <Link onClick={() => setView('overview')}>
          <Icon><FontAwesomeIcon icon="chart-bar" /></Icon>Overview
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'opt' ? '700' : ''}`}}>
        <Link onClick={() => setView('opt')}>
          <Icon><FontAwesomeIcon icon="check-circle" /></Icon>Opt Out
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'shared data' ? '700' : ''}`}}>
        <Link onClick={() => setView('shared data')}>
          <Icon><FontAwesomeIcon icon="id-card" /></Icon>Your Shared Data
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'offers' ? '700' : ''}`}}>
        <Link onClick={() => setView('offers')}>
          <Icon><FontAwesomeIcon icon="gift" /></Icon>Your Offers
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'enroll' ? '700' : ''}`}}>
        <Link onClick={() => setView('enroll')}>
          <Icon><FontAwesomeIcon icon="credit-card" /></Icon>Your Accounts & Cards
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'credit' ? '700' : ''}`}}>
        <Link onClick={() => setView('credit')}>
          <Icon><FontAwesomeIcon icon="bullseye" /></Icon>Your Credit Score
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'consumer rights' ? '700' : ''}`}}>
        {/* <Link onClick={() => setView('consumer rights')}> */}
        <Link onClick={() => window.open('https://mybitdoor.com/privacy-policy/', '_blank')}>
          <Icon><FontAwesomeIcon icon="users" /></Icon>Your Consumer Rights
        </Link>
      </Row>
    </Container>
  );
}

export default SideNav;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  min-width: 15rem;
  width: 17rem;
  background-color: ${({ theme }) => theme.colors.primary };
  color: #fff;
  padding: 2rem 1rem;
  @media (max-width: 768px) {
    display: none;
  }
`

const Row = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1rem;
`

const Link = styled.span`
  cursor: pointer;
  display: flex;
`

const Icon = styled.div`
  width: 2.5rem;
  margin-right: 0;
  margin-left: .5rem;
`

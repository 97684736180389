import React, { useContext } from 'react';
import { Context } from 'react-square-payment-form';
import styled from 'styled-components';

const CustomPayButton = ({ text }) => {
 const context = useContext(Context);

  const handleSubmit = evt => {
    evt.preventDefault();
    context.onCreateNonce();
  }

  return (
    <Button onClick={handleSubmit}>{text}</Button>
  );
}

export default CustomPayButton;

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: .9em;
  height: 2rem;
  padding: .4rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`
import React, { Component } from 'react';

class GradientSVG extends Component {
  render() {
    let { startColor, endColor, gradientId, rotation, midColor } = this.props;

    let gradientTransform = `rotate(${rotation})`;

    return (
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={gradientId} gradientTransform={gradientTransform}>
            <stop offset="0%" stopColor={startColor} />
            <stop offset="50%" stopColor={midColor} />
            <stop offset="100%" stopColor={endColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default GradientSVG;

import React, { useCallback } from 'react';
import styled from 'styled-components';
// import visa from '../../images/visa.png';
// import mastercard from '../../images/mastercard.png';
// import americanex from '../../images/americanex.png';
import { BASE_URL, isTokenValid } from '../../services/utils';
import { cardInputStyles } from '../../Theme';
import { Formik, Field } from 'formik';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

const EnrollForm2 = ({ setOpenModal, setLoading, fetchCardsData, setMessage, setModalMessage, setOpenModalErr, getToken, setOpenModalNotification, profileData, setErrorStatus }) => {

  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    wrapperProps,
    getZIPProps
  } = usePaymentInputs();

  const enrollCard = useCallback(async({ cardNumber, expiryDate, cvc, zip }) => {
    setLoading(true);
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(BASE_URL + '/cards', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          first_name: profileData.firstName,
          last_name: profileData.lastName,
          pan: cardNumber.replace(/ /g, ""),
          exp_month: parseInt(expiryDate.slice(0, 2), 10),
          exp_year: parseInt(expiryDate.slice(-2)) + 2000,
          cvv: cvc,
          postal_code: zip
          // address: street,
          // phone: phoneNumber,
          // email
        })
      })
      .then(res => res.json())
      .then(({ message, card_id }) => {
        setLoading(false);
        if (card_id) {
          fetchCardsData({ token });
          setOpenModal(false);
          setMessage('Card successfully added.');
          setOpenModalNotification(true);
          // window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          setModalMessage(message);
          // setOpenModal(false);
          setOpenModalErr(true);
          // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
      .catch(err => {
        console.log("Error enrolling card", err)
        setErrorStatus(true);
      })
    }
  }, [getToken, 
      setMessage, 
      setModalMessage, 
      setOpenModal, 
      setOpenModalErr, 
      setOpenModalNotification, 
      fetchCardsData,
      profileData,
      setLoading,
      setErrorStatus
      ])

  const handleCancel = () => {
    setOpenModal(false); 
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Container>
      {/* <CardTitle>Add A Card</CardTitle> */}
      <Formik
        initialValues={{
          cardNumber: '',
          expiryDate: '',
          cvc: '',
          zip: ''
        }}
        onSubmit={data => enrollCard(data)}
        validate={() => {
          let errors = {};
          if (meta.erroredInputs.cardNumber) {
            errors.cardNumber = meta.erroredInputs.cardNumber;
          }
          if (meta.erroredInputs.expiryDate) {
            errors.expiryDate = meta.erroredInputs.expiryDate;
          }
          if (meta.erroredInputs.cvc) {
            errors.cvc = meta.erroredInputs.cvc;
          }
          if (meta.erroredInputs.zip) {
            errors.zip = meta.erroredInputs.zip;
          }
          return errors;
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <PaymentsInputContainer>
              <PaymentInputsWrapper {...wrapperProps} styles={cardInputStyles}>
                <svg {...getCardImageProps({ images })} />
                <Field name="cardNumber">
                  {({ field }) => (
                    <Input {...getCardNumberProps({ onBlur: field.onBlur, onChange: field.onChange })} placeholder='Card Number'/>
                  )}
                </Field>
                <Field name="expiryDate">
                  {({ field }) => (
                    <Input {...getExpiryDateProps({ onBlur: field.onBlur, onChange: field.onChange })} />
                  )}
                </Field>
                <Field name="cvc">
                  {({ field }) => <Input {...getCVCProps({ onBlur: field.onBlur, onChange: field.onChange })} />}
                </Field>
                <Field name="zip">
                  {({ field }) => (
                    <Input {...getZIPProps({ onBlur: field.onBlur, onChange: field.onChange })} placeholder='Zip'/>
                  )}
                </Field>
                {/* <Label><Icon src={visa} /><Icon src={mastercard} /><Icon src={americanex} style={{height: '2.4rem'}} /></Label> */}
              </PaymentInputsWrapper>
            </PaymentsInputContainer>
            {/* <Button marginTop="major-2" type="submit">
              Submit
            </Button> */}
            <CenterRow>
              <AgreeText>By clicking Add Card, you agree to the&nbsp;
                <Link onClick={() => window.open('https://mybitdoor.com/bitdoor-end-user-terms-and-conditions/')}>Terms &amp; Conditions</Link>
                  &nbsp;and&nbsp;
                <Link onClick={() => window.open('https://mybitdoor.com/privacy-policy/', '_blank')}>Privacy Policy</Link>
              </AgreeText>
            </CenterRow>
            <CenterRow>
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Button type="submit">Add Card</Button>
            </CenterRow>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default EnrollForm2;

const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 20rem;
  }
  @media (max-width: 414px) {
    max-width: 17rem;
  }
`
  
  const PaymentsInputContainer = styled.div`
  min-height: 6rem;
  margin-top: 2rem;
  @media (max-width: 768px) {
    min-height: 3rem;
  }
`
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
// `

// const Row = styled.div`
//   display: flex;
//   width: 100%;
// `

const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

// const Icon = styled.img`
//   height: 1.5rem;
//   margin-left: .4rem;
// `

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
`

// const Label = styled.label`
//   color: ${({ theme }) => theme.colors.textSecondary };
//   margin-bottom: .5rem;
//   display: flex;
//   align-items: center;
// `

// const Span = styled.span`
//   color: ${({ theme }) => theme.colors.error };
// `

const Input = styled.input`
  height: 2rem;
  margin-bottom: 1rem;
  :focus {
    box-shadow: none;
  }
`

const AgreeText = styled.span`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-size: .9em;
  margin: 1rem 0 0 0;
  text-align: center;
`

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: .9em;
  height: 2rem;
  padding: .4rem 1.2rem;
  margin: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

// const Error = styled.div`
//   color: ${({ theme }) => theme.colors.error };
//   font-size: .8em;
// `

const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
`
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { selectStyles } from '../../Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TargetForm = ({ view, setView, setOpenModal, currentOffer, handleClose, customers, setCustomers, income, setIncome, credit, setCredit }) => {
  // const [customers, setCustomers] = useState(null);
  // const [income, setIncome] = useState(null);
  // const [credit, setCredit] = useState(null);

  useEffect(() => {
    if (currentOffer) {
      if (customers === null) {
        setCustomers({value: currentOffer.customerType, label: currentOffer.customerType})
      }
      if (income === null) {
        setIncome({value: currentOffer.incomeScore, label: currentOffer.incomeScore})
      }
      if (credit === null) {
        setCredit({value: currentOffer.creditScore, label: currentOffer.creditScore})
      }
    }
  }, [customers, income, credit, currentOffer, setIncome, setCredit, setCustomers])

  const optionsCustomers = [
    { value: 'NEW', label: 'New' },
    { value: 'EXISTING', label: 'Existing' },
    { value: 'ALL', label: 'All' },
  ];

  const optionsIncome = [
    { value: 'LOW', label: 'Low' },
    { value: 'AVERAGE', label: 'Average' },
    { value: 'HIGH', label: 'High' },
    { value: 'ALL', label: 'All' },
  ];

  const optionsCredit = [
    { value: 'LOW', label: 'Low' },
    { value: 'AVERAGE', label: 'Average' },
    { value: 'HIGH', label: 'High' },
    { value: 'ALL', label: 'All' },
  ];

  return (
    <Container>
      <ModalTitleRow>
        <CardTitle>Target Customers</CardTitle>
        <CloseButton onClick={handleClose}><FontAwesomeIcon icon="times" /></CloseButton>
      </ModalTitleRow>
      <Content>
          <Form>
            <Row>
              <Label>Customer Type:</Label>
              <StyledSelect 
                id='offer-select'
                value={customers} 
                onChange={(selected) => setCustomers(selected)}
                name='offer-select' 
                options={optionsCustomers}
                isSearchable={false}
                placeholder='Select Type'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            <Row>
              <Label>Income Score:</Label>
              <StyledSelect 
                id='target-income-select'
                value={income} 
                onChange={(selected) => setIncome(selected)}
                name='target-income-select' 
                options={optionsIncome}
                isSearchable={false}
                placeholder='Select Income'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            <Row>
              <Label>Credit Score:</Label>
              <StyledSelect 
                id='target-credit-select'
                value={credit} 
                onChange={(selected) => setCredit(selected)}
                name='target-credit-select' 
                options={optionsCredit}
                isSearchable={false}
                placeholder='Select Credit'
                className='custom-select'
                styles={selectStyles}
              />
            </Row>
            <Row style={{justifyContent: 'center', marginBottom: '0', marginTop: '7rem'}}>
              {/* <Button onClick={() => setOpenModal(false)}>Save</Button>
              <Button onClick={() => {setOpenModal(false); setView('your offers')}}>Save & Publish</Button> */}
            </Row>
          </Form>
      </Content>
    </Container>
  );
}

export default TargetForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text };
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 500;
  align-items: center;
  padding: .5rem 0;
  margin-left: auto;
`

const Form = styled.div`
  margin: 0 2rem;
  height: auto;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const ModalTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-size: 1em;
  margin-right: 1rem;
  font-weight: 500;
  display: flex;
`

const StyledSelect = styled(Select)`
  width: 12rem;
  div {
    min-height: 1.3rem;
  }
  option {
    vertical-align: center;
  }
`

// const Button = styled.button`
//   background-color: #fff;
//   color: ${({ theme }) => theme.colors.primary};
//   font-size: 1.1em;
//   height: 2.5rem;
//   padding: .4rem 1.2rem;
//   margin: 1rem 1rem;
//   border: 1px solid ${({ theme }) => theme.colors.primary };
//   &:hover {
//     background-color: ${({ theme }) => theme.colors.buttonPrimary };
//     color: #fff;
//     border: 1px solid ${({ theme }) => theme.colors.primary };
//   }
// `

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  font-size: 1.3em;
  margin: auto 0 auto auto;
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import EnrollForm2 from './EnrollForm2';
import { formatDate, formatDateLong, isTokenValid } from '../../services/utils';
import Modal from 'react-modal';
import { modalStyles, modalStylesI, selectStyles } from '../../Theme';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { 
  PaginationProvider, 
  // PaginationTotalStandalone, 
  PaginationListStandalone, 
  SizePerPageDropdownStandalone 
  } from 'react-bootstrap-table2-paginator';
// import ExportButton from '../common/ExportButton';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { BASE_URL } from '../../services/utils';
import Select from 'react-select';
import isLoading from '../common/isLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import AuthorizeAgentForm from './AuthorizeAgentForm';
import AAFormPdf from './AAFormPdf';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
const mccJson = require('../../services/mcc_codes_f.json');
const mccJsonR = require('../../services/mcc_codes_r.json');
Modal.setAppElement('#root');

const Enroll = ({
  view, setView, webToken, fetchCardsData, fetchAccountsData, cardData, message, sendSlack,
  accountData, getToken, profileData, setLoading, setErrorStatus, setMessage, setMessageModal, pdfData
}) => {
  const [currentTransactions, setCurrentTransactions] = useState(null);
  // const [message, setMessage] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalTr, setOpenModalTr] = useState(false);
  const [openModalErr, setOpenModalErr] = useState(false);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalAuthorizedAgent, setOpenModalAuthorizedAgent] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [genPdf, setGenPdf] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [openModalAddAccount, setOpenModalAddAccount] = useState(false);
  const [openModalContinue, setOpenModalContinue] = useState(false)
  const [currentPDF, setCurrentPDF] = useState('')

  const fetchTransactions = useCallback(async({ endpoint, id }) => {
    setLoading(true);
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(`${BASE_URL}/${endpoint}/${id}/transactions`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (data.transactions) {
          setCurrentTransactions(data.transactions);
          setOpenModalTr(true);
        } else {
          setModalMessage(data.message);
          setOpenModalErr(true);
        }
      })
      .catch(err => {
        console.log("Error fetching data", err)
        setLoading(false);
        setErrorStatus(true);
      })
    }
  }, [getToken, setLoading, setErrorStatus])

  const deleteCardAccount = useCallback(async({ id, type }) => {
    setLoading(true);
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(`${BASE_URL}/${type}/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      .then(async(res) => {
        let data = await res.json();
        setLoading(false);
        if (res.status === 202) {
          setMessage(data.message);
          setOpenModalNotification(true);
          type === 'cards' ? fetchCardsData({token}) : fetchAccountsData({token});
        } else {
          setModalMessage(data.message);
          setOpenModalErr(true);
        }
        setCurrentCard(null);
        setCurrentAccount(null);
      })
      .catch(err => {
        console.log("Error deleting card", err)
        setErrorStatus(true);
      })
    }
  }, [getToken, fetchCardsData, fetchAccountsData, setLoading, setErrorStatus, setMessage])

  const addAccount = useCallback((url) => {
    setOpenModalAddAccount(true);
    window.finicityConnect.launch(url, {
      selector: '#connect-container',
      overlay: 'rgba(59, 59, 59, 0.75)',
      success: function(data) {
        setMessage('New accounts may take a few minutes to process. Please try refreshing accounts.');
        setOpenModalNotification(true);
        setOpenModalAddAccount(false);
      },
      cancel: function() {
        console.log('The user cancelled the iframe');
        setOpenModalAddAccount(false);
      },
      error: function(err) {
        console.error('Runtime error', err);
        setOpenModalAddAccount(false);
      },
      loaded: function() {
        console.log('Finished loading');
      },
      route: function(event) {
        console.log('Progressing');
      }
    })
  }, [setMessage])

  const initAccount = useCallback(async() => {
    setLoading(true);
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      try {
        let response = await fetch(`${BASE_URL}/accounts/init`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          })
        })
        let data = await response.json()
        if (data.url) {
          setLoading(false);
          addAccount(data.url);
        } else {
          setErrorStatus(true);
          setLoading(false);
        }
      }
      catch(e) {
        setLoading(false);
        setErrorStatus(true);
        console.log(e)
      }
    }
  }, [getToken, setLoading, setErrorStatus, addAccount])

  const updateTransCat = useCallback(async() => {
    const tmp = currentTransactions;
    tmp[currentCategoryId.idx].category[0] = currentCategory.label;
    setCurrentTransactions(tmp);
  }, [currentCategory, currentCategoryId, currentTransactions, setCurrentTransactions])

  const submitMcc = useCallback(async() => {
    updateTransCat();
    setLoading(true);
    const { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      try {
        const res = await fetch(
          `${BASE_URL}/transactions/${currentCategoryId.id}/mcc/${currentCategory.value}`, 
          {
            method: 'PUT',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          })
        })
        if (res.status !== 200) {
          setMessage('There was a problem with your request');
          setMessageModal(true);
        }
        setLoading(false);
      }
      catch(e) {
        setLoading(false);
        console.log(e)
        setErrorStatus(true);
        setMessageModal(true);
      }
    }
  }, [getToken, setLoading, setErrorStatus, currentCategory, currentCategoryId, setMessage, setMessageModal, updateTransCat])

  const submitPdf = useCallback(async(blob) => {
    const formData = new FormData();
    formData.append('filep', blob);

    const { token, tokenStatus } = await getToken();

    if (isTokenValid(tokenStatus)) {
      try {
        const res = await fetch(`${BASE_URL}/consumers/data`, {
          method: 'POST',
          headers: new Headers({
            'Authorization': `Bearer ${token}`
          }),
          body: formData
        })
        if (res) {
          setLoading(false);
          setMessage('Form has been submitted. Our team will contact you soon with updates.')
          setOpenModalAuthorizedAgent(false);
          setOpenModalNotification(true);
        }
      }
      catch(e) {
        setErrorStatus(true);
        setLoading(false);
      }
    }
    
  }, [getToken, setLoading, setErrorStatus, setMessage])

  const savePdf = async({ signature, date, companies, method }) => {
    setLoading(true);
    const pdf = new jsPDF();
    companies.forEach(async(c, idx) => {
      setGenPdf({
        signature,
        date,
        checked: true,
        company: c.value
      });
      const input = document.getElementById('authorized-agent-form-pdf');
      window.scrollTo(0,0);
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      if (idx > 0) {
        pdf.addPage();
        pdf.setPage(idx+1);
      }
      pdf.addImage(imgData, 'PNG', 8, 10, '', '', '', 'FAST');
      if (idx === companies.length - 1) {
        if (method === 'save') {
          setLoading(false);
          pdf.save('bitdoor_authorized_agent_form.pdf');
        } else {
          const blob = pdf.output('blob', 'authorized_agent_form.pdf');
          setDownloadPdf(pdf);
          submitPdf(blob);
        }
      }
    });
  };

  const formatDateObj = (dates) => {
    return new Date(dates.slice(0,4) + "-" + dates.slice(4,6) + "-" + dates.slice(6,8) + "T" + 
    dates.slice(8,10) + ":" + dates.slice(10,12) + ":" + dates.slice(12,14) + "Z").toLocaleString() 
  }

  const sendDownloadRequest = (pdf) =>{
    sendSlack(`<!channel> \nHi, I would like to review my Data Portability request made on ${formatDateObj(pdf)}.
    \nFile: ${profileData.id}_${pdf}.pdf 
    \n\nRegards, \n${profileData.firstName} \n${profileData.email}`)
  }

  const handleSavePdf = useCallback(() => {
    if (downloadPdf) {
      downloadPdf.save('bitdoor_authorized_agent_form.pdf');
    }
  }, [downloadPdf]);

  const refresh = useCallback(async() => {
    if (!disabled) {
      setDisabled(true);
      let { token, tokenStatus } = await getToken();
      if (isTokenValid(tokenStatus)) {
        fetchAccountsData({ token });
      }
      setTimeout(() => {
        setDisabled(false);
      }, 2000)
    }
  }, [disabled, fetchAccountsData, getToken])

  const AAFormDom = genPdf
    ? <HiddenContainer>
        <AAFormPdf values={genPdf} />
      </HiddenContainer>
    : null

  const pdfDLButton = downloadPdf
    ? <Button onClick={handleSavePdf}><FontAwesomeIcon icon='download' /> PDF</Button>
    : null

  const cardTable = cardData
    ? cardData.length
      ? <table>
          <thead>
            <tr>
              <th>Last 4</th>
              <th className="m-iX m-iPlus">Opt In Date</th>
              {/* <StyledTh>Transaction History</StyledTh> */}
              <StyledTh className="m-iX m-iPlus">Remove</StyledTh>
            </tr>
          </thead>
          <tbody>
            {cardData.map((card, idx) => (
                <tr key={`c-enroll-${idx}`}>
                  <td><CardTd>{`x${card.lastFour}`}</CardTd></td>
                  <td className="m-iX m-iPlus">{formatDate(card.lastOptIn)}</td>
                  {/* <StyledTd><Link style={{fontSize: '.9em'}} onClick={() => fetchTransactions({id: card.cardId, endpoint: 'cards'})}>View Transactions</Link></StyledTd> */}
                  <StyledTd className="m-iX m-iPlus"><TrashIcon onClick={() => {setCurrentCard(card); setOpenModalConfirm(true)}}><FontAwesomeIcon icon="trash-alt" /></TrashIcon></StyledTd>
                </tr>
            ))}
          </tbody>
        </table>
      : <NoDataTitle>No cards enrolled</NoDataTitle>
    : null

  const accountTable = accountData
    ? accountData.length
      ? <table>
          <thead>
            <tr>
              <th>Name</th>
              <th className="m-iX m-iPlus">Opt In Date</th>
              <StyledTh>Transaction History</StyledTh>
              <StyledTh className="m-iX m-iPlus">Remove</StyledTh>
            </tr>
          </thead>
          <tbody>
            {accountData.map((account, idx) => (
                <tr key={`a-enroll-${idx}`}>
                  {account.accountName !== null && account.accountName !== '' && account.accountName !== undefined
                    ? <td>{`${account.bank} - ${account.accountName}`}</td>
                    : <td>{`${account.bank}`}</td>
                  }
                  <td className="m-iX m-iPlus">{formatDate(account.lastOptIn)}</td>
                  <StyledTd><Link style={{fontSize: '.9em'}} onClick={() => fetchTransactions({id: account.accountId, endpoint: 'accounts'})}>View Transactions</Link></StyledTd>
                  <StyledTd className="m-iX m-iPlus"><TrashIcon onClick={() => {setCurrentAccount(account); setOpenModalConfirm(true)}}><FontAwesomeIcon icon="trash-alt" /></TrashIcon></StyledTd>
                </tr>
            ))}
          </tbody>
        </table>
      : <NoDataTitle>No accounts enrolled</NoDataTitle>
    : null

    const pdfTable = pdfData
    ? pdfData.length
      ? <table>
          <thead>
            <tr>
              <th>Date</th>
              <StyledTh className="m-iX m-iPlus">Download</StyledTh>
            </tr>
          </thead>
          <tbody>
            {pdfData.map((pdf, idx) => (
                <tr key={`a-enroll-${idx}`}>
                  <td>{formatDateObj(pdf)}</td>
                  <StyledTd className="m-iX m-iPlus"><TrashIcon onClick={() => { setCurrentPDF(pdf); setOpenModalContinue(true);/*sendDownloadRequest(pdf)*/}}><FontAwesomeIcon icon="download" /></TrashIcon></StyledTd>
                </tr>
            ))}
          </tbody>
        </table>
      : <NoDataTitle>No Previous Requests</NoDataTitle>
    : null

  const tableDateFormatter = (cell, row) => {
    return (
      <span>{ formatDateLong(cell) }</span>
    );
  };

  const tableCurrencyFormatter = (cell, row) => {
    return (
      <span>{cell.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
    );
  };

  const tableCatFormatter = (cell, row, rowIndex) => {
    return (
      <Category onClick={() => {
        setOpenModalCategory(true); 
        if (row.mcc_penta || row.mcc) {
          setCurrentCategory({ 
            value: row.mcc_penta, 
            label: mccJsonR[row.mcc_penta || row.mcc], 
          });
        } else {
          if (cell) {
            setCurrentCategory({ 
              value: cell, 
              label: cell, 
            });
          } else {
            setCurrentCategory(null);
          }
        }
        setCurrentCategoryId({
          id: row.transactionId, 
          idx: rowIndex 
        })
      }}>
        {row.mcc_penta
          ? mccJsonR[row.mcc_penta]
          : row.mcc
            ? mccJsonR[row.mcc]
            : cell !== null 
              ? cell 
              : <>Add Category</>}
      </Category>
    );
  };

  const transactionsColumns = [{
    dataField: 'id',
    text: 'Transaction ID',
    sort: true,
    hidden: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'datetime',
    text: 'Date',
    sort: true,
    formatter: tableDateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'merchantName',
    text: 'Merchant',
    sort: true,
    hidden: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'category',
    text: 'Category',
    sort: true,
    formatter: tableCatFormatter,
    headerClasses: "m-iPlus",
    classes: "m-iPlus",
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'amount',
    text: 'Amount',
    sort: true,
    formatter: tableCurrencyFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }];

  const customTotal = (from, to, size) => (
    <DataTableText style={{margin: '0'}}>
      Showing { from } to { to } of { size } transactions
    </DataTableText>
  );

  const options = {
    custom: true,
    totalSize: currentTransactions ? currentTransactions.length : null,
    paginationSize: 3,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    firstPageText: '<<',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: '>>',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    paginationTotalRenderer: customTotal,
    // sizePerPageList: [{ // can delete in prod
    //   text: '2', value: 2
    // }, {
    //   text: '4', value: 4
    // }, {
    //   text: 'All', value: currentTransactions ? currentTransactions.length : 50
    // }]
    // showTotal: true,
  };

  const showModalTr = currentTransactions
    ? <Modal
       isOpen={openModalTr}
       style={modalStyles}
       contentLabel="Transactions History"
       overlayClassName="modal-overlay"
     >
       <ModalContentTransactions>
         <ModalRow>
           <ModalTitle>TRANSACTIONS HISTORY</ModalTitle>
           <CloseButton onClick={() => setOpenModalTr(false)}>
             <FontAwesomeIcon icon="times" />
           </CloseButton>
         </ModalRow>
         {currentTransactions.length
          ? <PaginationProvider
              pagination={ paginationFactory(options) }
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <ToolkitProvider
                      keyField="transactionId"
                      data={ currentTransactions }
                      columns={ transactionsColumns }
                      exportCSV={ { exportAll: true } }
                      // search
                    >
                      {
                        props => (
                          <DataTableContainer>
                            <BootstrapTable
                              { ...props.baseProps }
                              // selectRow={ selectRow }
                              { ...paginationTableProps }
                              striped
                              hover
                            />
                            {currentTransactions.length > 2
                              ? <Row style={{justifyContent: 'space-between', marginTop: '.5rem'}}>
                                  {/* <PaginationTotalStandalone
                                    { ...paginationProps }
                                  /> */}
                                  <StandAloneContainer>
                                    <SizePerPageDropdownStandalone
                                      { ...paginationProps }
                                    />
                                    {/* <DataTableText>
                                      items/page
                                    </DataTableText> */}
                                  </StandAloneContainer>
                                  <PaginationListStandalone
                                    { ...paginationProps }
                                  />
                                </Row>
                              : null
                            }
                            {/* <ExportButton style={{fontSize: '1.2em'}} text={'Download Data'} { ...props.csvProps } /> */}
                          </DataTableContainer>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                )
              }
            </PaginationProvider>
          : <Message>No transactions found</Message>
          }
          <ModalRow style={{justifyContent: 'center'}}>
            <Button style={{minHeight: '2rem', fontSize: '.9em'}} onClick={() => setOpenModalTr(false)}>Close</Button>
          </ModalRow>
       </ModalContentTransactions>
     </Modal>
   : <></>

   const showModalCategory = openModalCategory
    ? <Modal
        isOpen={openModalCategory}
        style={modalStyles}
        contentLabel="Categories"
        overlayClassName="modal-overlay"
      >
        <ModalContentTransactions>
          <ModalRow>
            <ModalTitle>Category</ModalTitle>
            <CloseButton onClick={() => setOpenModalCategory(false)}>
                <FontAwesomeIcon icon="times" />
            </CloseButton>
          </ModalRow>
          <Message style={{maxWidth: '22rem', marginTop: '2rem'}}>
            If the category listed does not match the transaction, please submit a new category by using the dropdown below.
          </Message>
          <ModalRow style={{margin: '1rem 0', justifyContent: 'center'}}>
              <StyledSelect 
                value={currentCategory}
                onChange={(selected) => setCurrentCategory({ 
                      value: selected.value,
                      label: selected.label
                  })
                }
                // onChange={
                //   (selected) => setCurrentCategory(prevSt => { 
                //     return({
                //       ...prevSt,
                //       value: selected.value,
                //       label: selected.label
                //     }) 
                //   })
                // }
                options={mccJson}
                placeholder='Search'
                isSearchable={true}
                styles={selectStyles}
                // className='custom-select'
              />
          </ModalRow>
            <ModalRow style={{justifyContent: 'center'}}>
              <Button onClick={() => setOpenModalCategory(false)}>Close</Button>
              <Button onClick={() => {setOpenModalCategory(false); submitMcc()}}>Submit</Button>
            </ModalRow>
        </ModalContentTransactions>
      </Modal>
    : <></>

   const showModalConfirm = currentCard || currentAccount
    ? <Modal
        isOpen={openModalConfirm}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Message style={{minWidth: '12rem', maxWidth: '21rem'}}>
            {currentCard && <>Are you sure you want to remove x{currentCard.lastFour}?</>}
            {currentAccount && <>Are you sure you want to remove {currentAccount.bank} {currentAccount.accountName && ` - ${currentAccount.accountName}`}?</>}
          </Message>
          <Row>
            <Button onClick={() => {setOpenModalConfirm(false); setMessage(''); setCurrentCard(null); setCurrentAccount(null)}}>Cancel</Button>
            {currentCard && <Button onClick={() => {setOpenModalConfirm(false); deleteCardAccount({id: currentCard.cardId, type: 'cards'})}}>Remove</Button>}
            {currentAccount && <Button onClick={() => {setOpenModalConfirm(false); deleteCardAccount({id: currentAccount.accountId, type: 'accounts'})}}>Remove</Button>}
          </Row>
        </ModalContent>
      </Modal>
    : <></>

   const showModalAuthorizedAgent = openModalAuthorizedAgent
    ? <Modal
        isOpen={openModalAuthorizedAgent}
        style={modalStyles}
        contentLabel="Form"
        overlayClassName="modal-overlay"
      >
        <>
          <AuthorizeAgentForm 
            setOpenModal={setOpenModalAuthorizedAgent}
            savePdf={savePdf}
            profileData={profileData}
          />
          {AAFormDom}
        </>
      </Modal>
    : <></>

    const showOpenModalContinue = openModalContinue
      ? <Modal
          isOpen={openModalContinue}
          style={modalStyles}
          contentLabel="Form"
          overlayClassName="modal-overlay"
        >
          <ModalContent>
            <Message style={{maxWidth: '18rem'}}>
              You are about to send a request to recieve the PDF generated on {formatDateObj(currentPDF)}.
            </Message>
            <Row>
              <Button onClick={() => {setOpenModalContinue(false)}}>Cancel</Button>
              <Button onClick={() => 
                {sendDownloadRequest(currentPDF); 
                  setOpenModalContinue(false); 
                  setCurrentPDF('');}}>
                Submit
              </Button>
            </Row>
          </ModalContent>
        </Modal>
      : <></>

   const showModalAddAccount = openModalAddAccount
    ? <Modal
        isOpen={openModalAddAccount}
        style={modalStylesI}
        contentLabel="Form"
        overlayClassName="modal-overlay"
      >
        <ModalContentA>
          <div id='connect-container' style={{height: '600px', width: '400px'}}>
          </div>
        </ModalContentA>
      </Modal>
    : <></>
  
  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Content>
        <Row style={{marginBottom: '1rem'}}>For your security and privacy, we do not store card numbers or bank account sensitive details on our systems. At your request, we may encrypt and transmit certain data to secure financial institutions to provide you with offers and other services.</Row>
          <Row style={{justifyContent: 'space-between'}}>
            <CardTitle><Icon><FontAwesomeIcon icon="credit-card" /></Icon>Cards</CardTitle>
            <AddLink onClick={() => {setOpenModal(true); setMessage('')}}><FontAwesomeIcon icon="plus" /></AddLink>
          </Row>
          <TableContainer>
            {cardTable}
          </TableContainer>
          <Row style={{justifyContent: 'space-between'}}>
            <CardTitle><Icon><FontAwesomeIcon icon="money-check" /></Icon>Accounts</CardTitle>
            <Row>
              <RefreshIcon onClick={refresh} disabled={disabled}>
                <FontAwesomeIcon icon="sync-alt" className="icon" />
              </RefreshIcon>
              <AddLink onClick={initAccount}><FontAwesomeIcon icon="plus" /></AddLink>
            </Row>
          </Row>
          <TableContainer>
            {accountTable}
          </TableContainer>
          <CCPAContainer>
            <CardTitle style={{fontweight: '500', fontSize: '1.2em'}}>Don't see your data?</CardTitle>
            <CardTitle style={{display: 'block', fontWeight: '500', fontSize: '1.1em'}}>Request your data using this form <Link onClick={() => setOpenModalAuthorizedAgent(true)}>here</Link>.</CardTitle>
          </CCPAContainer>
          <Row style={{justifyContent: 'space-between'}}>
            <CardTitle><Icon><FontAwesomeIcon icon={faFilePdf} /></Icon>Previous Requests</CardTitle>
          </Row>
          <TableContainerScroll>
            {pdfTable}
          </TableContainerScroll>
      </Content>
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Tracking Data"
        overlayClassName="modal-overlay"
      >
        <ModalContentC>
          <ModalRow>
            <ModalTitle>ADD A CARD</ModalTitle>
            <CloseButton onClick={() => setOpenModal(false)}>
              <FontAwesomeIcon icon="times" />
            </CloseButton>
          </ModalRow>
          <ModalRow style={{margin: '1rem 0', justifyContent: 'center'}}>
            <EnrollForm2
              setOpenModal={setOpenModal} 
              webToken={webToken} 
              fetchCardsData={fetchCardsData} 
              setMessage={setMessage} 
              getToken={getToken}
              setModalMessage={setModalMessage}
              setOpenModalErr={setOpenModalErr}
              setOpenModalNotification={setOpenModalNotification}
              profileData={profileData}
              setLoading={setLoading}
              setErrorStatus={setErrorStatus}
            />
          </ModalRow>
        </ModalContentC>
      </Modal>
      <Modal
        isOpen={openModalErr}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Message style={{maxWidth: '21rem'}}>
            An error occured please contact customer support using our form. &nbsp;
          </Message>
          {modalMessage && <Message style={{maxWidth: '21rem'}}>{modalMessage}</Message>}
          <Row>
            <Button onClick={() => {setOpenModalErr(false); setView('support'); setModalMessage('')}}>Support</Button>
            <Button onClick={() => {setOpenModalErr(false); setModalMessage('')}}>Close</Button>
          </Row>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={openModalNotification}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent style={{padding: '0 1rem'}}>
          <Message style={{minWidth: '17rem', maxWidth: '21rem', margin: '1rem 0'}}>
            {message}
          </Message>
          <Row>
            {pdfDLButton}
            <Button onClick={() => {setOpenModalNotification(false); setMessage(''); setDownloadPdf(null)}}>Close</Button>
          </Row>
        </ModalContent>
      </Modal>
      {showModalConfirm}
      {showModalTr}
      {showModalCategory}
      {showModalAuthorizedAgent}
      {showModalAddAccount}
      {showOpenModalContinue}
    </Container>
  );
}

export default isLoading(Enroll);

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 375px) {
    padding: 1.5rem;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

// const Title = styled.div`
//   width: 100%;
//   font-size: 1.8em;
//   color: ${({ theme }) => theme.colors.text };
//   font-weight: 700;
//   margin: 3rem 4rem 1rem 4rem;
//   padding: .2rem;
//   @media (max-width: 768px) {
//     display: none;
//   }
// `

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Link = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 500;
  vertical-align: top;
`

const Category = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
`

const AddLink = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary };
  font-size: 1.1em;
  font-weight: 500;
  display: flex;
  align-items: center;
`

// const Card = styled.div`
//   ${({ theme }) => theme.animations.fadeIn };
//   margin: 1rem 3rem;
//   display: flex;
//   flex-direction: column;
//   border-radius: .25rem;
//   background-color: #fff;
//   padding: 2.5rem;
//   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
//   @media (max-width: 768px) {
//     margin: .2rem 0 0 0;
//     box-shadow: none;
//   }
// `

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const CardTd = styled.span`
  text-transform: none;
`

const StyledTd = styled.td`
  text-align: center;
  vertical-align: center;
`

const StyledTh = styled.th`
  text-align: center;
  vertical-align: center;
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
`

const TableContainer = styled.div`
  margin: 2rem auto 1rem auto;
  width: 100%;
`

const TableContainerScroll = styled(TableContainer)`
  max-height: 12rem;
  overflow: auto;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.1em;
  margin: 1rem 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text };
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  max-height: 40rem;
`

const ModalContentC = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  max-height: 40rem;
  @media (max-width: 768px) {
    min-width: 26rem;
  }
  @media (max-width: 414px) {
    min-width: 80%;
  }
`

const ModalContentA = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  @media (max-width: 414px) {
    width: 18rem;
  }
`

const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalTitle = styled.div`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
  min-width: 25rem;
  @media (max-width: 768px) {
    min-width: 18rem;
  }
  @media (max-width: 414px) {
    min-width: 8rem;
  }
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`

const ModalContentTransactions = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  div.react-bootstrap-table {
    max-height: 30rem;
    overflow: auto;
  }

  div.react-bootstrap-table table {
    width: 30rem;
    font-size: .8em;
    border: 0;
    text-transform: none;
    margin: auto;
  }

  @media (max-width: 414px) {
    div.react-bootstrap-table table {
      width: 100%;
    }
  }
`

// const ModalTableTitle = styled.div`
//   font-size: 1.3em;
//   color: ${({ theme }) => theme.colors.textSecondary };
//   font-weight: 500;
// `

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1em;
  height: 2rem;
  padding: .4rem 1.2rem;
  margin: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary };

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary };
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const StyledSelect = styled(Select)`
  width: 14rem;
  margin: 2rem auto;
  div {
    max-height: 6rem;
  }
  div div div {
    max-height: auto;
    overflow-y: hidden;
    vertical-align: middle;
  }
  option {
    vertical-align: center;
    max-height: 3rem;
  }
  input {
    box-shadow: none;
  }
`

const TrashIcon = styled.span`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.error };
  }
`

const RefreshIcon = styled.div`
  display: flex;
  font-size: .8em;
  align-items: center;
  padding: .2rem;
  cursor: pointer;

  svg {
    color: ${({ disabled, theme }) => disabled ? theme.colors.textSecondary : theme.colors.primary };
    ${({ disabled, theme }) => disabled && theme.animations.spin };
  }
`

const HiddenContainer = styled.div`
  opacity: 0;
  height: 0;
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

// const StyledPaginationListStandalone = styled(PaginationListStandalone)`
//   ul {
//     font-size: .9em;
//   }
// `

const DataTableText = styled.div`
  margin-left: 1rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const CCPAContainer = styled.div`
  ${({ theme }) => theme.mobile.mPlus };
  margin-bottom: 1rem;
`
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Overview = ({ setView }) => {

  return (
    <Container>
      <Welcome>
        <Title>Merchants Dashboard</Title>
        {/* <Text>Welcome to the Bitdoor Merchants Dashboard. Using the tools below, you can:</Text>
        <ul>
          <li>Text</li>
          <li>Text</li>
          <li>Text</li>
        </ul> */}
      </Welcome>
      <Cards>
        <Card style={{minWidth: '19rem', maxWidth: '19rem', minHeight: '24rem'}}>
          <CardTitle>Create Offer</CardTitle>
          <CardText>
            <Icon><FontAwesomeIcon icon="plus-square" className="icon" /></Icon>
          </CardText>
          <Row>
            <Button onClick={() => {setView('create offer')}}>Start</Button>
          </Row>
        </Card>
        <Card style={{minWidth: '19rem', maxWidth: '19rem', minHeight: '24rem'}}>
          <CardTitle>Your Offers</CardTitle>
          <CardText>
            <Icon><FontAwesomeIcon icon="tags" className="icon" /></Icon>
          </CardText>
          <Row>
            <Button onClick={() => {setView('your offers')}}>Show</Button>
          </Row>
        </Card>
        <Card style={{minWidth: '19rem', maxWidth: '19rem', minHeight: '24rem'}}>
          <CardTitle>Analytics</CardTitle>
          <CardText>
            <Icon><FontAwesomeIcon icon="chart-line" className="icon" /></Icon>
          </CardText>
          <Row>
            <Button onClick={() => {setView('analytics')}}>Details</Button>
          </Row>
        </Card>
      </Cards>
    </Container>
  );
}

export default Overview;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) {
    height: auto;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const Welcome = styled.div`
  width: 100%;
  text-align: left;
  margin: 2rem 5rem;
`
const Title = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 700;
`

const Cards = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5rem 5rem;
  @media (max-width: 768px) {
    margin: auto;
  }
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  margin: 1rem 1rem 2rem 1rem;
  display: flex;
  width: 19rem;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  
  @media (min-width: 1400px) {
    width: 19rem;
  }
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 2em;
  font-weight: 700;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: 3rem auto 0 auto;
  padding: .5rem 1.1rem;
  font-size: 1.1em;
  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding: .2rem;
  font-size: 6rem;
  color: ${({ theme }) => theme.colors.primary };
`

import React from 'react';
import styled from 'styled-components';

const AAFormPdf = ({ values }) => {
  const privacyUrl = () => { window.open('https://mybitdoor.com/privacy-policy/', '_blank') }

  return (
    <Container>
      <Form>
        <FormContent id='authorized-agent-form-pdf'>
          <Title>Bitdoor Authorized Agent Form</Title>
          <Paragraph>
            California residents have the right to designate a person or business to exercise rights granted to them under the California Consumer Privacy Act (CCPA). By authorizing Bitdoor to act on your behalf, you can direct us to:            
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                Request your data from companies and display that information to you on the Bitdoor Platform.
              </li>
              <li>
                Instruct companies to delete all of your data from their systems.
              </li>
              <li>
                Stop companies from selling your information.            
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            Bitdoor will only ever handle and use your data in compliance with its <Link onClick={privacyUrl}>Privacy Policy</Link> and Terms of Use. Some requests may take up to 45 days to fulfill, but most are much quicker.            
          </Paragraph>
          <Paragraph>
            To designate Bitdoor as your authorized agent, simply complete this form:
          </Paragraph>
          <Row style={{justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: '6rem', marginTop: '2rem', marginBottom: '1rem'}}>
            <Column style={{marginLeft: '.2rem', marginRight: '1rem'}}>
              <Label>Full Name:</Label>
              <Input 
                type="text" 
                placeholder=""
                value={values.signature}
              />
            </Column>
            <Column style={{marginLeft: '.2rem', marginRight: '1rem'}}>
              <Label>Date:</Label>
              <Input 
                disabled
                type="text" 
                placeholder="" 
                value={values.date}
              />
            </Column>
            <Column style={{marginLeft: '.2rem'}}>
              <Label>Requesting Data From:</Label>
              <Input 
                disabled
                type="text" 
                placeholder="" 
                value={values.company}
              />
            </Column>
          </Row>
          <Row style={{height: '2.2rem'}}>
            <Column style={{alignItems: 'flex-start', justifyContent: 'flex-start', height: '100%', margin: '.5rem'}}>
              <CheckBox 
                checked={values.checked} 
                type="checkbox" 
              />
            </Column>
            <Paragraph>
              I hereby authorize Bitdoor to serve as my designated agent and to act on my behalf, solely as instructed by me on the Bitdoor Platform.
            </Paragraph>
          </Row>
        </FormContent>
      </Form>
    </Container>
  );
}

export default AAFormPdf;

const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 48rem;
  max-height: 50rem;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Form = styled.form`
  overflow: auto;
  margin: 0 1rem 1rem 1rem;
`

const FormContent = styled.div`
  padding: 0 1rem 1rem 1rem;
`

const Title = styled.div`
  font-size: 1.4em;
  font-weight: 500;
  text-align: center;
`

const Paragraph = styled.p`
  font-size: .9em;
  text-align: left;
`

const Label = styled.label`
  font-size: .9em;
`

const Input = styled.input`
  height: 1.5rem;
  box-shadow: none;
  margin-right: 0;
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 500;
  cursor: pointer;
`

const CheckBox = styled.input`
  width: 13px;
  height: 13px;
  padding: 0;
  margin-top: 0;
  vertical-align: top;
  &:focus {
    box-shadow: none;
  }
`
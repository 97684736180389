import React, { useCallback } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import CreditCircleBar2 from '../common/CreditCircleBar2';
import ProgressProvider from '../common/ProgressProvider';
// import VisibilitySensor from 'react-visibility-sensor';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import ExportButton from './common/ExportButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Desktop, Mobile } from '../../Theme';
// import { tu, tu2 } from '../../mockData';
import paginationFactory, { 
  PaginationProvider, 
  PaginationListStandalone, 
  SizePerPageDropdownStandalone 
  } from 'react-bootstrap-table2-paginator';
import tuLogo from '../../images/tu-logo.svg';
import { formatDateSlash } from '../../services/utils';

const CreditScore = ({ view, setView, profileData, creditInfo }) => {

  const tuUrl = () => { window.open('https://www.transunion.com/credit-disputes/dispute-your-credit', '_blank') }

  const tableDateFormatter = (cell, row) => {
    if (cell) {
      return (
        <span>
          {/* { formatDateSlash(cell) } */}
          {cell}
        </span>
      );
    } else {
      return <></>
    }
  };

  const tableCurrencyFormatter = (cell, row) => {
    return (
    //   <span>${cell.toFixed(2)}</span>
      <span>${cell}</span>
    );
  };

  const customTotal = (from, to, size) => (
    <DataTableText style={{margin: '0'}}>
      Showing { from } to { to } of { size } accounts
    </DataTableText>
  );

  const checkCreditReport = () => {
    if (!creditInfo) return null;
    if (!Object.keys(creditInfo.credit_report).length) return null;
    return Object.keys(creditInfo.credit_report).length
  }

  const options = {
    custom: true,
    totalSize: checkCreditReport(),
    paginationSize: 3,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    firstPageText: '<<',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: '>>',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{ // can delete in prod
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: checkCreditReport()
    }],
    // showTotal: true,
  };

  const columns = [{
    dataField: 'creditorName',
    text: 'Creditor',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'accountNumber',
    text: 'Acccount #',
    sort: true,
    headerClasses: 'm-ipad',
    classes: 'm-ipad',
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'industry',
    text: 'Industry',
    sort: true,
    headerClasses: 'm-ipad',
    classes: 'm-ipad',
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'status',
    text: 'Status',
    sort: true,
    classes: 'm-iPlus',
    headerClasses: 'm-iPlus',
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'dateOpened',
    text: 'Open Date',
    sort: true,
    headerClasses: 'm-ipad',
    classes: 'm-ipad',
    formatter: tableDateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'dateReported',
    text: 'Close Date',
    sort: true,
    headerClasses: 'm-ipad',
    classes: 'm-ipad',
    hidden: true,
    formatter: tableDateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'currentBalance',
    text: 'Balance',
    sort: true,
    formatter: tableCurrencyFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon="sort" className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon="sort-up" className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon="sort-down" className="custom-sort-icon-active" />
        );
      return null;
    }
  }];

  const creditScore = useCallback(() => {
    if(profileData.subscription.tier_int === 0){
      return <Column style={{height: '100%', margin: 'auto'}}><NoDataText>Subscription required.</NoDataText></Column>
    }
    if (!creditInfo){
      return <Column style={{height: '100%', margin: 'auto'}}><NoDataText>Please authenticate your account.</NoDataText></Column>
    }
    return creditInfo.credit_score && creditInfo.credit_updated
      ? <>
          {/* <ScoreDate>as of {formatDateSlash(creditInfo.credit_update)}</ScoreDate> */}
          <Desktop>
            <ScoreDate>as of {formatDateSlash(creditInfo.credit_updated)}</ScoreDate>
          </Desktop>
          <Mobile>
            <ScoreDate style={{fontSize: '1em'}}>as of {formatDateSlash(creditInfo.credit_updated)}</ScoreDate>
          </Mobile>
          <CreditBarContainer>
            <ProgressProvider valueStart={0} valueEnd={creditInfo.credit_score}>
              {value => <CreditCircleBar2 value={value} total={850} min={300} gradientId={'gradient-credit'} />}
            </ProgressProvider>
          </CreditBarContainer>
        </>
      : <Column style={{height: '100%', margin: 'auto',}}><NoDataText>Please authenticate your account.</NoDataText></Column>
  }, [creditInfo, profileData])

  const creditReport = useCallback(() => { 
    if(profileData.subscription.tier_int === 0){
      return <Column style={{height: '6rem', margin: 'auto',}}><NoDataText>Subscription required.</NoDataText></Column>
    }
    if(!creditInfo){
      return <Column style={{height: '6rem', margin: 'auto',}}><NoDataText>Please authenticate your account</NoDataText></Column>
    }
    return Object.keys(creditInfo.credit_report).length > 0
      ? <DataTableContainer>
          <PaginationProvider
              pagination={ paginationFactory(options) }
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <ToolkitProvider
                      keyField="accountNumber"
                      data={ creditInfo.credit_report }
                      columns={ columns }
                      exportCSV={ { exportAll: true } }
                      // search
                    >
                      {
                        props => (
                          <DataTableContainer>
                            <BootstrapTable
                              { ...props.baseProps }
                              // selectRow={ selectRow }
                              { ...paginationTableProps }
                              striped
                              hover
                            />
                            {creditInfo.credit_report.length > 2
                              ? <Row style={{justifyContent: 'space-between', marginTop: '.5rem'}}>
                                  {/* <PaginationTotalStandalone
                                    { ...paginationProps }
                                  /> */}
                                  <StandAloneContainer>
                                    <SizePerPageDropdownStandalone
                                      { ...paginationProps }
                                    />
                                    {/* <DataTableText>
                                      items/page
                                    </DataTableText> */}
                                  </StandAloneContainer>
                                  <PaginationListStandalone
                                    { ...paginationProps }
                                  />
                                </Row>
                              : null
                            }
                            {/* <ExportButton style={{fontSize: '1.2em'}} text={'Download Data'} { ...props.csvProps } /> */}
                          </DataTableContainer>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                )
              }
            </PaginationProvider>
        </DataTableContainer>
      : <Column style={{height: '6rem', margin: 'auto',}}><NoDataText>Please authenticate your account</NoDataText></Column>
  }, [profileData, creditInfo, columns, options])

  const consumerStatement = useCallback(() => {
    if(profileData.subscription.tier_int === 0){
      return <Column style={{height: '6rem', margin: 'auto',}}><NoDataText>Subscription required.</NoDataText></Column>
    }
    if(!creditInfo){
      return <Column style={{height: '6rem', margin: 'auto',}}><NoDataText>Please authenticate your account</NoDataText></Column>
    }
    return creditInfo.consumer_statement
      ? <StatementContainer>
          <TextArea>
            {creditInfo.consumer_statement}
          </TextArea>
        </StatementContainer>
      : <Column style={{height: '6rem', margin: 'auto',}}><NoDataText>There are no consumer statements on your report</NoDataText></Column>
  }, [profileData, creditInfo])

  return (
    <Container>
      <SideNav view={view} setView={setView} />
      <Desktop>
        <Content>
          <Row>
            <Column style={{marginRight: 'auto', marginLeft: 'auto'}}>
              <CardTitle>VantageScore® 3.0 Credit Score</CardTitle>
              {creditScore()}
            </Column>
            <Column style={{width: '50%', margin: 'auto'}}>
              <CardTitle>Key Factor(s) Affecting</CardTitle>
              <CardTitle>Credit Score:</CardTitle>
              <Row>
                <Column><CircleNumber>1</CircleNumber></Column>
                <Column>
                  <FactorsTitle>Length of credit history</FactorsTitle>
                  <CardText>
                    In general, a longer credit history will increase your score. However, even people who havevn't been using
                    credit for long may have high scores, depending on how the rest of their credit report looks.
                  </CardText>
                </Column>
              </Row>
              <Row>
                <Column><CircleNumber>2</CircleNumber></Column>
                <Column>
                  <FactorsTitle>Payment History</FactorsTitle>
                  <CardText>
                    The first thing any lender wants to know is whether you've paid past credit accounts on time. This helps a lender
                    figure out the amount of risk it will take on when extending credit. This is the most imporant factor in a credit score.
                  </CardText>
                </Column>
              </Row>
            </Column>
          </Row>
          <CardTitle>Your TransUnion® Credit Report</CardTitle>
            {creditReport()}
          <CardTitle>Consumer Statement</CardTitle>
            {consumerStatement()}
          <Footer>
            <FooterRow>
              Vantage 3.0 Score and credit report provided by <Logo src={tuLogo} />
            </FooterRow>
            <FooterRow>
              <strong>What is TransUnion?</strong> TransUnion is one of three major credit reporting agencies that provide consumers with a credit score. 
              Bitdoor shows you your TransUnion VantageScore credit score. Creditors may use your VantageScore or your credit score from 
              any of the three agencies to make a decision on a credit or loan application. Your credit score may vary between the three major agencies.
            </FooterRow>
            <FooterRow>
              <strong>Problem with your report?</strong> If you think your credit report contains any errors, contact <Link onClick={tuUrl}>TransUnion</Link> directly. 
              Every year, you are entitled to a free credit report that includes info from each of the three reporting agencies at www.annualcreditreport.com.
            </FooterRow>
          </Footer>
        </Content>
      </Desktop>
      <Mobile>
        <Content>
          <Column style={{minHeight: '18rem'}}>
            <CardTitleM>VantageScore® 3.0 Credit Score</CardTitleM>
            {creditScore()}
          </Column>
          <Column style={{margin: '2rem auto 0 auto', minHeight: '28rem'}}>
            <CardTitle style={{marginBottom: '1rem'}}>Key Factor(s) Affecting Credit Score:</CardTitle>
            <Row>
              <Column><CircleNumber>1</CircleNumber></Column>
              <Column>
                <FactorsTitle>Length of credit history</FactorsTitle>
                <CardText>
                  In general, a longer credit history will increase your score. However, even people who havevn't been using
                  credit for long may have high scores, depending on how the rest of their credit report looks.
                </CardText>
              </Column>
            </Row>
            <Row>
              <Column><CircleNumber>2</CircleNumber></Column>
              <Column>
                <FactorsTitle>Payment History</FactorsTitle>
                <CardText>
                  The first thing any lender wants to know is whether you've paid past credit accounts on time. This helps a lender
                  figure out the amount of risk it will take on when extending credit. This is the most imporant factor in a credit score.
                </CardText>
              </Column>
            </Row>
          </Column>
          <CardTitle style={{minHeight: '2rem'}}>Your TransUnion® Credit Report</CardTitle>
            {creditReport()}
          <CardTitle>Consumer Statement</CardTitle>
            {consumerStatement()}
          <Footer>
            <FooterRow>
              Vantage 3.0 Score and credit report provided by <Logo src={tuLogo} />
            </FooterRow>
            <FooterRow>
              <strong>What is TransUnion?</strong> TransUnion is one of three major credit reporting agencies that provide consumers with a credit score. 
              Bitdoor shows you your TransUnion VantageScore credit score. Creditors may use your VantageScore or your credit score from 
              any of the three agencies to make a decision on a credit or loan application. Your credit score may vary between the three major agencies.
            </FooterRow>
            <FooterRow>
              <strong>Problem with your report?</strong> If you think your credit report contains any errors, contact <Link onClick={tuUrl}>TransUnion</Link> directly. 
              Every year, you are entitled to a free credit report that includes info from each of the three reporting agencies at www.annualcreditreport.com.
            </FooterRow>
          </Footer>
        </Content>
      </Mobile>
    </Container>
  );
}

export default CreditScore;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: 768px) {
    height: auto;
    overflow: auto;
  }

  @media (max-width: 512px) {
    padding: 2rem;
  }

  @media (max-width: 375px) {
    padding: 1rem;
  }
`

const Row = styled.div`
  display: flex;
`

const FooterRow = styled.div`
  margin-top: 1rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const StatementContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
`

const TextArea = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.1em;
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 2em;
  font-weight: 700;
  margin-top: 1rem;
  @media (max-width: 375px) {
    margin-top: 1rem;
  }
  @media (max-width: 414px) {
    font-size: 1.2em;
  }
`
const CardTitleM = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 2em;
  font-weight: 700;
  margin-top: 1rem;
  min-height: 2.5rem
  @media (max-width: 512px) {
    min-height: 3rem;
  }
  @media (max-width: 414px) {
    font-size: 1.2em;
  }
`

const FactorsTitle = styled.div`
  color: ${({ theme }) => theme.colors.text };
  margin-top: 1rem;
  font-size: 1.2em;
  font-weight: 700;
`

const ScoreDate = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 1px;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 2rem;
`

const CircleNumber = styled.span`
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  padding: 5px;
  font-size: 1.3em;
  font-weight: 700;
  margin: 1rem .8rem auto 0rem;

  background: ${({ theme }) => theme.colors.primary };
  color: #fff;
  text-align: center;

  @media (max-width: 768px) {
    width: 1rem;
    height: 1rem;
    font-size: .9em;
    padding: auto;
    margin: 1.2rem 1rem auto 0rem;
  }
`

const CreditBarContainer = styled.div`
  width: 100%;
  height: 100%;
  jusitify-content: center;
  div.RCP {
    width: 100%;
    margin-top: 1rem;
    margin-left: 3rem;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
    div.RCP {
      margin: auto;
    }
  }
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
  table td {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6rem;
    vertical-align: middle;
    @media (max-width: 768px) {
      max-width: 4rem;
    }
  }
  @media (max-width: 768px) {
    font-size: .9em;
    min-height: 10rem;
  }
`

const NoDataText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin: auto;
  text-align: center;
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const DataTableText = styled.div`
  margin-left: 1rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const Footer = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-top: 1rem;
  font-size: 1em;
  font-weight: 500;
`

const Link = styled.span`
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 700;
  cursor: pointer;
  vertical-align: top;
`

const Logo = styled.img`
  height: 1.5rem;
`
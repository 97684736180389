import { BASE_URL, isUserConsumer, isUserMerchant } from './utils'

export const decipherToken = (token) => {
  return JSON.parse(atob(token.split('.')[1]));
}

export const login = async(creds) => {
  const res = await fetch(`${BASE_URL}/login`, {
    method: 'POST',
    headers: new Headers({'Content-Type': 'application/json'}),
    body: JSON.stringify(creds)
  })
  const status = res.status;
  const { token, refresh_token, signature } = await res.json();
  if (token) {
    const payload = decipherToken(token);
    const userType = payload.type;
    if (isUserConsumer(userType) || isUserMerchant(userType)) {
      setToken({ token, refresh_token });
    }
    return ({ status, userType });
  } else {
    return ({ status, signature });
  }
}

export const mfa_login = async(creds) => {
  try {
    const res = await fetch(`${BASE_URL}/mfa-login`, {
      method: 'POST',
      headers: new Headers({'Content-Type': 'application/json'}),
      body: JSON.stringify(creds)
    })
    const status = res.status;
    if (status === 200) {
      const { token, refresh_token, message } = await res.json()
      if (token) {
        const payload = decipherToken(token);
        const userType = payload.type;
        if (isUserConsumer(userType)  || isUserMerchant(userType)) {
          setToken({ token, refresh_token});
        }
        return ({ userType, status });
      } else {
        return ({ message, status });
      }
    } else {
      return ({ status })
    }
  }
  catch(e) {
    console.log(e);
    return null;
  }
}

export const refreshToken = (token) => {
  return fetch(`${BASE_URL}/subscribers/refresh`, {
    method: 'POST',
    headers: new Headers({
      'Authorization': `Bearer ${token}`
    })
  })
  .then(res => {
    if (res.ok) return res.json();
    throw new Error('Problem refreshing token');
  })
  .then(({ token }) => setToken({ token }))
}

export const setToken = ({ token, refresh_token }) => {
  token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
  if (refresh_token) localStorage.setItem('refresh token', refresh_token);
}

export const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh token');
}

export const reset = (creds) => {
  return fetch(BASE_URL + '/reset-password', {
    method: 'POST',
    headers: new Headers({'Content-Type': 'application/json'}),
    body: JSON.stringify(creds)
  })
  .then(res => {
    return res.json();
  })
  .then(({ message }) => {
    return ({ message })
  })
}

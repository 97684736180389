import React, { useContext } from 'react';
import { ResponsiveContainer, BarChart, Bar, Cell, YAxis, XAxis, CartesianGrid, LabelList } from 'recharts';
import styled, { ThemeContext } from 'styled-components';
// import CustomToolTip from './CustomToolTip';

const VerticalChart = ({ data, text }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container> 
      <Label>{text}</Label>
      <ResponsiveContainer height={200} width="50%" wrapperStyle={{padding: 0}}>
        <BarChart height={400} data={data} margin={{ top: 30}}>
          <CartesianGrid vertical={false}/>
          <YAxis yAxisId="BAR" dataKey={"value"} unit="%" hide={true} />
          <LabelList dataKey="value" position="insideTop" />
          <Bar yAxisId="BAR" dataKey={"value"} fill={"#fff"} radius={[10, 10, 0, 0]}>
            {data.map((entry, index) => (
              <Cell cursor="default" fill={themeContext.colors.primary} key={`cell-${index}`}/>  
            ))}
            <LabelList dataKey="value" position="insideCenter" 
              style={{ fontSize: '2em', fill: '#fff', fontWeight: '700', marginRight: '1rem' }}
            />
          </Bar>
          <XAxis dataKey="name" tickLine={false}/>
          {/* <Tooltip content={<CustomToolTip />}/> */}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
}

export default VerticalChart;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: auto;
  align-items: center;
`

const Label = styled.label`
  margin-top: .5rem;
  margin-bottom: 0;
  font-size: 1.5em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
`
import React, { useState, useCallback, useEffect } from 'react';
import SideNav from './SideNav';
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles, selectStylesCards } from '../../Theme';
import Select from 'react-select';
import { BASE_URL, getCardOptions, isTokenValid } from '../../services/utils';
import OfferDetails from './OfferDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Modal.setAppElement('#root');

const Offers = ({ view, setView, cardData, getToken, offersData, setErrorStatus, offersDataHistory, fetchOffersHistory, fetchOffersData }) => {
  const [offerView, setOfferView] = useState('main');
  const [currentOffer, setCurrentOffer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalOffer, setOpenModalOffer] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [message, setMessage] = useState(null);

  // const S4 = () => {    
  //   return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
  // }

  useEffect(() => {
    if (cardData) {
      if (cardData.length) {
        let options = getCardOptions(cardData);
        setSelectedOption(options[0]);
      }
    }
  }, [cardData, setSelectedOption])

  const fetchLinkOffer = useCallback(async () => {
    let { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      fetch(`${BASE_URL}/cards/${selectedOption.value}/offers`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({ offer_id: currentOffer.offer.id })
      })
      .then(res => {
        if (res.status === 201) {
          setOpenModal(false);
          setOpenModalOffer(true);
          setMessage('');
          fetchOffersHistory({ token });
          fetchOffersData({ token, type: 'consumers' });
        } else {
          setMessage('There was a problem linking your offer.');
        }
      })
      .catch(err => {
        console.log("Error linking card", err)
        setErrorStatus(true);
      })
    }
  }, [getToken, selectedOption, currentOffer, setErrorStatus, fetchOffersHistory, fetchOffersData])

  const updateOfferView = useCallback(async (offerId) => {
    let { token } = await getToken();
    if (token) {
      fetch(`${BASE_URL}/consumers/offers/${offerId}/views`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Content-Length': '0',
          'Authorization': `Bearer ${token}`
        })
      })
      .then(res => res.json())
      .catch(err => console.log(err))
    }
  }, [getToken])

  const handleClick = useCallback((e) => {
    setCurrentOffer(offersData.offers[e.target.id]);
    setOfferView('details');
    updateOfferView(offersData.offers[e.target.id].offer.id);
  }, [offersData, updateOfferView]);

  const handleClickHistory = useCallback((e) => {
    setCurrentOffer(offersDataHistory.offers[e.target.id]);
    setOfferView('details');
  }, [offersDataHistory]);

  // const handleHistoryClick = useCallback(async (e) => {
  //   let { token, tokenStatus } = await getToken();
  //   if (isTokenValid(tokenStatus)) {
  //     fetch(`${BASE_URL}/consumers/linked-offers`, {
  //       method: 'GET',
  //       headers: new Headers({
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${token}`
  //       })
  //     })
  //       .then(res => res.json())
  //       .then(res => {
  //         res.offers.map((r) => { return r.offer.id += S4()})
  //         setHistory(res)
  //       })
  //       .catch(err => {
  //         console.log("Error linking card", err)
  //         setErrorStatus(true);
  //       })
  //   }
  // }, [getToken, setHistory, setErrorStatus]);

  const handleBack = () => {
    setCurrentOffer(null);
    setOfferView('main');
    setMessage('');
  };

  const handleLink = useCallback(() => {
    if (selectedOption) {
      fetchLinkOffer();
    } else {
      setMessage('Please select a card to link offer');
    }
  }, [selectedOption, fetchLinkOffer]);

  const windowOpen = (url) => {
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    return window.open(url, '_blank');
  }

  const offerCards = offersData
    ? offersData.offers.length
      ? offersData.offers.map((o, idx) => (
          <Card key={`offer-cards-${idx}`} id={idx} onClick={handleClick}>
            <Column id={idx}>
              {o.offer.image ?
                <Logo src={o.offer.image} id={idx} alt={o.offer.name} /> :
                <NoImageContainer id={idx}><NoImageText id={idx}>{o.offer.name}</NoImageText></NoImageContainer>}
              {/* <Logo src={o.offer.image} id={idx} alt={o.offer.name} /> */}
              <CardText id={idx}>{o.offer.offer_type}</CardText>
            </Column>
          </Card>
        ))
      : <NoOffersText>No new offers</NoOffersText>
    : <NoOffersText>There was a problem loading offers.</NoOffersText>

  const offerCardsHistory = offersDataHistory
    ? offersDataHistory.offers.length
      ? offersDataHistory.offers.map((o, idx) => (
        <Card key={`offer-cards-history-${idx}`} id={idx} onClick={handleClickHistory}>
          <Column id={idx}>
            {o.offer.image ?
              <Logo src={o.offer.image} id={idx} alt={o.offer.name} /> :
              <NoImageContainer id={idx}><NoImageText id={idx}>{o.offer.name}</NoImageText></NoImageContainer>}
            {/* <Logo src={o.offer.image} id={idx} alt={o.offer.name} /> */}
            <CardText id={idx}>{o.offer.offer_type}</CardText>
          </Column>
        </Card>
        ))
      : <Description>You have not linked any offers yet.</Description>
    : <Description>There was a problem loading offers history.</Description>

  switch (offerView) {

    case 'main':
      return (
        <Container>
          <SideNav view={view} setView={setView} />
          <Content>
            <Title>Card Linked Offers</Title>
            <Description>
              Everyone loves saving money, right? Below are the latest offers from the brands you love. <br />
              <Bold>Come back often to see more offers and save more money.</Bold><br />
              To link offers, make sure you have a credit card linked in "Your Accounts & Cards". <br />
              Once an offer is linked, you can redeem offers by making purchases from the merchant using the linked card. <br />
            </Description>
            <Cards>
              {offerCards}
            </Cards>
            <Title>Linked Offers</Title>
            <Cards>
              {offerCardsHistory}
            </Cards>
          </Content>
        </Container>
      )

    case 'details':
      return (
        // maybe add ternary to check if data is there when not using mock data
        <Container>
          <SideNav view={view} setView={setView} />
          <ContentDetails>
            <TitleDetails>Card-Linked Offers</TitleDetails>
            <Row>
              <OfferDetails
                offerData={currentOffer}
                handleBack={handleBack}
                setOpenModal={setOpenModal}
              />
            </Row>
          </ContentDetails>
          <Modal
            isOpen={openModal}
            style={modalStyles}
            contentLabel="Link Offer"
            overlayClassName="modal-overlay"
          >
            <ModalContent>
              <ModalRow style={{ marginTop: '0', justifyContent: 'space-between' }}>
                <ModalTitle>LINK CARD</ModalTitle>
                <CloseButton onClick={() => { setOpenModal(false); setMessage('') }}>
                  <FontAwesomeIcon icon="times" />
                </CloseButton>
              </ModalRow>
              <ModalRow>
                <Label style={{ marginRight: '1.5rem' }}>Select Card:</Label>
                <StyledSelect
                  value={selectedOption}
                  onChange={(selected) => setSelectedOption(selected)}
                  options={getCardOptions(cardData)}
                  isSearchable={false}
                  // className='custom-select'
                  styles={selectStylesCards}
                />
              </ModalRow>
              {message ? <Error>{message}</Error> : <Error>&nbsp;</Error>}
              <ModalRow>
                <Link onClick={() => setView('enroll')}>Add a Card</Link>
              </ModalRow>
              <ModalRow style={{ marginBottom: '0' }}>
                <Button onClick={() => { setMessage(''); setOpenModal(false) }}>Cancel</Button>
                <Button onClick={handleLink}>Link</Button>
              </ModalRow>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={openModalOffer}
            style={modalStyles}
            contentLabel="Redeem Offer"
            overlayClassName="modal-overlay"
          >
            <ModalContent style={{ minHeight: '18rem' }}>
              <Message style={{ fontSize: '1.3em', fontWeight: '500' }}>Offer Linked!</Message>
              <Message style={{ marginTop: '1.5rem' }}>You can now redeem your offers through the merchant directly or by using the link below.</Message>
              {/* <ModalTitle>LINK CARD</ModalTitle>
              <CloseButton onClick={() => setOpenModalOffer(false)}>
                <i className="fas fa-times"></i>
              </CloseButton> */}
              <ModalRow style={{ marginBottom: '0' }}>
                <Button onClick={() => { setOpenModalOffer(false); windowOpen(currentOffer.offer.url) }}>Redeem</Button>
                <Button onClick={() => { setOpenModalOffer(false) }}>Close</Button>
              </ModalRow>
            </ModalContent>
          </Modal>
        </Container>
      )
    default: return null;
  }
}

export default Offers;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
  }
  @media (max-width: 375px) {
    padding: 1.5rem;
  }
`

const ContentDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
  @media (max-width: 414px) {
    padding: 0;
  }
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem;
  align-items: center;
  height: auto;
  min-height: 16rem;
`

const Title = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  padding: .2rem;
`

const TitleDetails = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  padding: .2rem;
  @media (max-width: 414px) {
    margin: 2rem;
  }
`

const Description = styled.div`
  ${({ theme }) => theme.animations.fadeIn};
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;
  padding: .2rem;
  text-align: left;
`

const Bold = styled.div`
  font-weight: 700;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn}
  flex: 0 0 11%;
  margin: .5rem .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 10rem;
  height: 8rem;
  border-radius: .25rem;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  transition: box-shadow .3s ease-in-out;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.primary };

  :hover {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.21);
    border: 2px solid ${({ theme }) => theme.colors.primary };
    color: ${({ theme }) => theme.colors.primary };
  }
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  font-weight: 700;
  margin: auto auto 0 auto;
`

const Logo = styled.img`
  max-width: 8rem;
  max-height: 6rem;
  align-self: center;
  margin: auto auto auto auto;
`

const NoImageContainer = styled.div`
  height: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoImageText = styled.div`
  align-self: center;
  margin: auto;
  color: ${({ theme }) => theme.colors.textSecondary};
`

const NoOffersText = styled.div`
  font-size: 1.1em;
  align-self: center;
  margin: auto;
  color: ${({ theme }) => theme.colors.textSecondary};
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  max-height: 40rem;
  min-height: 18rem;
  max-width: 27rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalRow = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`

const ModalTitle = styled.div`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  min-width: 25rem;
  @media (max-width: 414px) {
    min-width: 17rem;
  }
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff};
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
     color: ${({ theme }) => theme.colors.primary};
  }
`

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1em;
  height: 2rem;
  min-width: 7rem;
  padding: .4rem 1.2rem;
  margin: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const StyledSelect = styled(Select)`
  min-width: 9rem;
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.1em;
`

const Message = styled.label`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  font-size: 1.1em;
  margin: auto 2rem;
  width: 22rem;
`

const Error = styled.label`
  text-align: center;
  font-size: .9em;
  margin: 0 auto;
`

const Link = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 1em;
  cursor: pointer;
`
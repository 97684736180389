import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import SideNavC from './consumer/SideNav';
import SideNavM from './merchant/SideNav';
import Loading from 'react-loading';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { Desktop, Mobile, modalStyles, modalStylesO, selectStyles, selectStylesNoBox } from '../Theme';
import isLoading from './common/isLoading';
// import AuthorizedAgentForm from './AuthorizeAgenForm';
import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
} from 'react-square-payment-form';
import 'react-square-payment-form/lib/default.css';
import CustomPayButton from './common/CustomPayButton';
import { 
  BASE_URL, 
  formatTUDate, 
  formatDateLong, 
  formatDateSlash, 
  generateRandomString, 
  isTokenValid, 
  isUserConsumer, 
  isUserMerchant
} from '../services/utils';
import AuthForm from './consumer/AuthForm';
import Toggle from './common/ToggleSmall';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

Modal.setAppElement('#root');

const tiers = { 0: 'Free', 1: 'Pro', 2: 'Premium' };

const tierCost = { 'pro_month': 4.99, 'pro_year': 49.99, 'prem_month': 9.99, 'prem_year': 99 };

const optionsPro = [
  { value: true, label: '$4.99/month' },
  { value: false, label: '$49/year' },
];

const optionsPrem = [
  { value: true, label: '$9.99/month' },
  { value: false, label: '$99/year' },
];

const mfaOptions = [
  { value: 'email', label: 'Email' },
  { value: 'sms', label: 'Text' },
  { value: 'voice', label: 'Voice' }
]

const Profile = ({ 
  view, setView, profileData, deleteAccount, message, handleLogout, setLoading, setContactUs,
  getToken, setErrorStatus, setMessage, setMessageModal, fetchProfileData, userType, fetchCreditInfo,
  sendSlack
}) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalDeleted, setOpenModalDeleted] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [openModalPaymentDel, setOpenModalPaymentDel] = useState(false);
  const [openModalFreeze, setOpenModalFreeze] = useState(false);
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false);
  const [openSlack, setOpenSlack] = useState(false)
  const [errorMessages, setErrorMessages] = useState([]);
  const [update, setUpdate] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openQuestions, setOpenQuestions] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [address, setAddress] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [city, setCity] = useState(null);
  const [addrState, setAddrState] = useState(null);
  const [country, setCountry] = useState(null);
  const [dob, setDob] = useState(null);
  const [authNonce, setAuthNonce] = useState(null);
  const [answers, setAnswers] = useState({});
  const [selectedTier, setSelectedTier] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [selectPro, setSelectPro] = useState({ value: true, label: '$4.99/month' });
  const [selectPrem, setSelectPrem] = useState({ value: true, label: '$9.99/month' });
  const [firstNameSt, setFirstNameSt] = useState(null);
  const [lastNameSt, setLastNameSt] = useState(null);
  const [MFAEnable, setMFAEnable] = useState(false);
  const [MFADisable, setMFADisable] = useState(false);
  const [MFA, setMFA] = useState({ value: 'email', label: 'Email' });
  const [OTP, setOTP] = useState('');
  const [MFAPhone, setMFAPhone] = useState('');
  const [MFAVerify, setMFAVerify] = useState(false);
  const [helpText, setHelpText] = useState('')

  useEffect(() => {
    if (!authNonce) {
      setAuthNonce(generateRandomString(20));
    }
  }, [authNonce])

  const handleDelete = useCallback(async() => {
    setLoading(true);
    const { status } = await deleteAccount();
    if (status === 200) {
      setLoading(false);
      setOpenModalConfirm(false);
      setOpenModalDeleted(true);
    }
  }, [deleteAccount, setLoading])

  const handleAnswer = useCallback((e) => {
    let tmp = answers;
    const questionId = e.target.name;
    const answerId = e.target.value;
    tmp[questionId] = answerId;
    setAnswers(tmp);
  }, [answers])

  const formatAnswers = (answers) => {
    let formattedAnswers = [];
    for (const p in answers) {
      let temp = {
        questionId: p,
        answerId: answers[p]
      }
      formattedAnswers.push(temp)
    }
    return formattedAnswers;
  }

  const submitAnswers = useCallback(async ({ ssn, phone }) => {
    setLoading(true);
    const { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      try {
        const res = await fetch(BASE_URL + '/consumers/verify', {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
          body: JSON.stringify({
            answers: formatAnswers(answers),
            fulfillmentKey: questions.fulfillmentKey,
            authNonce,
            dob: formatTUDate(dob),
            firstName: firstNameSt,
            lastName: lastNameSt,
            address,
            city,
            state: addrState,
            postalCode
          })
        })
        switch (res.status) {
          case 200:
            const data = await res.json();
            setOpenQuestions(false);
            if (!data) return null;
            if (data.questions) {
              setQuestions(prevState => {
                return ({
                  ...prevState,
                  questions: data.questions
                })
              });
              setOpenAuth(false);
              setOpenQuestions(true);
            }
            setAnswers({});
            break;
          case 202:
            setOpenQuestions(false);
            setMessage('Thank you, your account has been verified.');
            setMessageModal(true);
            fetchCreditInfo({ token });
            fetchProfileData({ token });
            break;
          case 203:
            setOpenQuestions(false);
            setMessage('Thank you, you have been verified but we are not able to get your credit info at this time.');
            setMessageModal(true);
            fetchProfileData({ token });
            break;
          case 401:
            setHelpText(`Hi, I cannot verify my account information. Please reach out to help me. \n\nBest, \n${profileData.firstName} \n${profileData.email}`);
            setOpenSlack(true); 
            break;
          default:
            setOpenQuestions(false);
            //setMessage('We were not able to authenticate you at this time.');
            setMessageModal(true);
        }
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
        setErrorStatus(true);
        setOpenQuestions(false);
        setAnswers({});
        console.log("Error with verification request", e)
      }
    }
  }, [setMessage, setLoading, questions, setMessageModal, answers, getToken,
    setErrorStatus, authNonce, dob, fetchCreditInfo, fetchProfileData, address,
    addrState, firstNameSt, lastNameSt, postalCode, city, profileData
  ]);

  const submitAuthentication = useCallback(async ({ ssn, phone, firstName, lastName }) => {
    setLoading(true);
    const { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      try {
        const res = await fetch(BASE_URL + '/consumers/authenticate', {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
          body: JSON.stringify({
            // firstName: profileData.firstName,
            // lastName: profileData.lastName,
            firstName,
            lastName,
            ssn,
            postalCode,
            city,
            address,
            state: addrState,
            phone,
            country,
            dob: formatTUDate(dob),
            authNonce
          })
        })
        const data = await res.json();
        setAnswers({});
        if (res.status === 200) {
          if (data.questions) {
            setQuestions(data);
            setOpenAuth(false);
            setOpenQuestions(true);
          } else {
            setMessage('Not able to verify.');
            setMessageModal(true);
            setContactUs(true);
          }
        } else if (res.status === 401) {
          setMessage('Not able to verify.');
          setMessageModal(true);
          setContactUs(true);
          setOpenAuth(false);
        } else {
          setMessage('We were not able to authenticate you at this time. Please contact us through our support page');
          setContactUs(true);
          setMessageModal(true);
        }
        setLoading(false);
      }
      catch (e) {
        setLoading(false);
        setErrorStatus(true);
        console.log("Error with authentication request", e)
      }
    }
  }, [setMessage, address, addrState, country, city, postalCode, dob, setContactUs,
    setErrorStatus, setQuestions, authNonce, getToken, setLoading, setMessageModal]);

  const addPaymentMethod = useCallback(async ({ nonce, cardData }) => {
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (isTokenValid(tokenStatus)) {
        const res = await fetch(`${BASE_URL}/subscribers/billing` , {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
          body: JSON.stringify({
            cardNonce: nonce,
            postalCode: cardData.billing_postal_code
          })
        })
        if (res.status === 201) {
          setMessage('Payment successfuly added.');
          setMessageModal(true);
          setOpenModalPayment(false);
          fetchProfileData({
            token, 
            endpoint: userType === 'merchant' ? 'subscribers' : 'consumers'
          });
        } else {
          setErrorStatus(true);
        }
        setLoading(false);
      }
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [setLoading, setErrorStatus, getToken, fetchProfileData, setMessage, setMessageModal, userType])

  const delPaymentMethod = useCallback(async () => {
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (isTokenValid(tokenStatus)) {
        const res = await fetch(`${BASE_URL}/subscribers/billing` , {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
          body: JSON.stringify({
            paymentId: Object.keys(profileData.billing)[0]
          })
        })
        if (res.status === 200) {
          setMessage('Payment method removed.');
          setMessageModal(true);
          fetchProfileData({
            token, 
            endpoint: userType === 'merchant' ? 'subscribers' : 'consumers'
          });
        } else {
          setErrorStatus(true);
        }
        setLoading(false);
      }
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [setLoading, profileData, setErrorStatus, getToken, setMessageModal, setMessage, fetchProfileData, userType])

  const unfreezeCredit = useCallback(async () => {
    setOpenModalFreeze(false);
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (isTokenValid(tokenStatus)) {
        const res = await fetch(`${BASE_URL}/consumers/credit-freeze/false` , {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
        })
        if (res.status === 200) {
          fetchProfileData({ token });
          fetchCreditInfo({ token });
        } else {
          setErrorStatus(true);
        }
        setLoading(false);
      }
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [getToken, fetchProfileData, fetchCreditInfo, setLoading, setErrorStatus])

  const changeTier = useCallback(async () => {
    const type = profileData.subscription.tier_int < selectedTier ? 'upgrade' : 'downgrade';
    setOpenModalUpgrade(false);
    if (!Object.keys(profileData.billing).length > 0) {
      setMessage('Please add a payment method.');
      setMessageModal(true);
      return;
    }
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (isTokenValid(tokenStatus)) {
        const res = await fetch(`${BASE_URL}/consumers/tiers/${type}` , {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
          body: JSON.stringify({
            newTier: selectedTier,
            monthly
          })
        })
        if (res.status === 200) {
          setMessage('Plan successfully updated');
          setMessageModal(true)
          fetchProfileData({ token });
          //   fetchCreditInfo({ token });
        } else {
          setErrorStatus(true);
        }
        setLoading(false);
      }
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [getToken, fetchProfileData, setLoading, setErrorStatus, setMessage, setMessageModal, profileData, selectedTier, monthly])

  // const changeTierSchedule = useCallback(async() => {
  //   setLoading(true);
  //   try {
  //     const { token, tokenStatus } = await getToken();
  //     if (isTokenValid(tokenStatus)) {
  //       const res = await fetch(`${BASE_URL}/consumers/tiers/change-schedule` , {
  //         method: 'PUT',
  //         headers: new Headers({
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${token}`
  //         })
  //       })
  //       if (res.status === 200) {
  //         setMessage('Plan successfully updated');
  //         setMessageModal(true)
  //         fetchProfileData({ token });
  //       } else {
  //         setErrorStatus(true);
  //       }
  //       setLoading(false);
  //     }
  //   }
  //   catch(e) {
  //     setErrorStatus(true);
  //     setLoading(false);
  //     console.log(e)
  //   }
  // }, [getToken, fetchProfileData, setLoading, setErrorStatus, setMessage, setMessageModal])

  const updatePaymentMethod = useCallback(async ({ nonce, cardData }) => {
    setUpdate(false);
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (isTokenValid(tokenStatus)) {
        const del = await fetch(`${BASE_URL}/subscribers/billing` , {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }),
          body: JSON.stringify({
            paymentId: Object.keys(profileData.billing)[0]
          })
        })
        if (del.status === 200) {
          const res = await fetch(`${BASE_URL}/subscribers/billing`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }),
            body: JSON.stringify({
              cardNonce: nonce,
              postalCode: cardData.billing_postal_code
            })
          })
          if (res.status === 201) {
            setMessage('Payment method updated.');
            setMessageModal(true);
            fetchProfileData({
              token, 
              endpoint: userType === 'merchant' ? 'subscribers' : 'consumers'
            });
            setOpenModalPayment(false);
          } else {
            setErrorStatus(true);
          }
        } else {
          setErrorStatus(true);
        }
        setLoading(false);
      }
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [setLoading, profileData, setErrorStatus, getToken, setMessageModal, setMessage, fetchProfileData, userType])

  const initMFA = useCallback(async () => {
    setLoading(true);
    setMessage('');
    if (MFA.value !== 'email' && !MFAPhone) {
      setMessage('Phone number required.');
      setLoading(false);
      // setMessageModal(true);
      return;
    }
    try {
      const { token, tokenStatus } = await getToken();
      if (tokenStatus !== 'valid') return null;
      const res = await fetch(`${BASE_URL}/mfa`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          mfa_channel: MFA.value,
          mfa_channel_value: MFA.value === 'email' ? profileData.email : MFAPhone
        })
      })
      if (res.status === 202) {
        setMFAVerify(true);
      } if (res.status === 403) {
        setMessage('Check phone number and try again.')
      } else {
        setMessage('We could not set up MFA at this time.')
      }
      setLoading(false);
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [getToken, setErrorStatus, setLoading, MFA, MFAPhone, profileData, setMessage])

  const verifyMFA = useCallback(async () => {
    setLoading(true);
    setMessage('');
    if (!OTP) {
      setMessage('OTP required');
      setLoading(false);
      return;
    }
    try {
      const { token, tokenStatus } = await getToken();

      if (tokenStatus !== 'valid') return null;

      const res = await fetch(`${BASE_URL}/mfa-check`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          mfa_channel: MFA.value,
          mfa_channel_value: MFA.value === 'email' ? profileData.email : MFAPhone,
          otp: OTP
        })
      })
      switch (res.status) {
        case 200:
          setMFAVerify(false);
          setMFAEnable(false);
          fetchProfileData({
            token, 
            endpoint: userType === 'merchant' ? 'subscribers' : 'consumers'
          });
          break;
        case 400:
          setMessage('There was a problem with the request, please contact Bitdoor to resolve.')
          setMessageModal(true);
          break;
        case 403:
          setMessage('Max attempts reached, please wait 10 minutes and then try to verify again.')
          setMessageModal(true);
          setMFAVerify(false);
          setMFAEnable(false);
          break;
        case 409:
          setMessage('Incorrect OTP, please re-enter the code sent to you.')
          setMessageModal(true);
          break;
        default:
          setMessage('There was a problem with the request, please contact Bitdoor to resolve.')
          setMessageModal(true);
      }
      setLoading(false);
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [getToken, setErrorStatus, setLoading, MFA, MFAPhone, profileData, OTP, fetchProfileData, setMessage, setMessageModal, userType])

  const disableMFA = useCallback(async () => {
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (tokenStatus !== 'valid') return null;
      const res = await fetch(`${BASE_URL}/mfa`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        })
      })
      if (res.status === 200) {
        fetchProfileData({
          token, 
          endpoint: userType === 'merchant' ? 'subscribers' : 'consumers'
        });
      } else {
        setMessage('There was a problem turning off MFA');
        setMessageModal(true);
      }
      setLoading(false);
    }
    catch (e) {
      setErrorStatus(true);
      setLoading(false);
      console.log(e)
    }
  }, [getToken, setErrorStatus, setLoading, setMessageModal, setMessage, fetchProfileData, userType])

  const cardNonceResponseReceived = useCallback((errors, nonce, cardData, buyerVerificationToken) => {
    if (errors) {
      setErrorMessages(errors.map(error => error.message))
      return
    }
    setErrorMessages([]);
    update ? updatePaymentMethod({ nonce, cardData }) : addPaymentMethod({ nonce, cardData });
  }, [addPaymentMethod, update, updatePaymentMethod])

  const createVerificationDetails = useCallback(() => {
    return {
      currencyCode: "USD",
      intent: "STORE",
      billingContact: {
        familyName: profileData.firstName,
        givenName: profileData.lastName,
        email: profileData.email,
        // country: "US",
        // city: "San Francisco",
        // addressLines: ["101 Market St"],
        // postalCode: '91745'
        // phone: "415 555 5555"
      }
    }
  }, [profileData])

  const handleCancelSquare = useCallback(() => {
    setOpenModalPayment(false);
    setErrorMessages([]);
    // setNonceData({});
  }, [])

  const handleToggle = useCallback(() => {
    setMessage('')
    profileData.mfa_enabled ? setMFADisable(true) : setMFAEnable(true);
  }, [setMessage, profileData])

  const showPaymentMethod = profileData.billing
    ? Object.keys(profileData.billing).length > 0
      ? <Row style={{ alignItems: 'center' }}>
        x{profileData.billing[Object.keys(profileData.billing)[0]].lastFour}
        <IconLink onClick={() => { setUpdate(true); setOpenModalPayment(true) }}><FontAwesomeIcon icon="edit" /></IconLink>
        <IconLinkD onClick={() => setOpenModalPaymentDel(true)}><FontAwesomeIcon icon="trash-alt" /></IconLinkD>
      </Row>
      : <Button style={{ margin: '.5rem auto auto 0' }} onClick={() => setOpenModalPayment(true)}>
        Add Card
        </Button>
    : null

  const showCreditFreeze = profileData && isUserConsumer(userType)
    ? profileData.verification.credit_freeze
      ? <Button style={{margin: '.5rem 0 0 0'}} onClick={() => setOpenModalFreeze(true)}>Authorize</Button>
      : <>
        {
          !profileData.verification.credit_freeze && profileData.verification.credit_unfreeze
            ? `Authorized on ${formatDateLong(profileData.verification.credit_unfreeze_at)}`
            : 'None'
        }
      </>
    : null

  const showPayment = openModalPayment
    ? <StyledModal
      isOpen={openModalPayment}
      style={modalStyles}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent style={{ padding: '0', maxWidth: '30rem' }}>
        <Row style={{ justifyContent: 'center', height: '2rem', width: '100%' }}>
          <PaymentTitle>Payment Information</PaymentTitle>
          {/* <Link onClick={() => setOpenModalPayment(false)}><FontAwesomeIcon icon="times" /></Link> */}
        </Row>
        <SquarePaymentForm
          sandbox={false} //TRUE WHEN USING SANDBOX KEYS
          applicationId={process.env.REACT_APP_SQ_APP_ID}
          locationId={process.env.REACT_APP_SQ_LOCATION_ID}
          cardNonceResponseReceived={cardNonceResponseReceived}
          createVerificationDetails={createVerificationDetails}
          inputStyles={[{ lineHeight: '30px', padding: '5px 5px 5px 10px' }]}
          style={{ padding: '2rem' }}
        >

          <fieldset className="sq-fields">
            <Desktop>
              <Row style={{ justifyContent: 'left' }}>
                <CardInput style={{ width: '13rem', marginRight: '1rem' }}>
                  <CreditCardNumberInput />
                </CardInput>
                <CardInput style={{ width: '6rem' }}>
                  <CreditCardExpirationDateInput />
                </CardInput>
              </Row>
              <Row style={{ justifyContent: 'left', marginTop: '1rem' }}>
                <CardInput style={{ width: '5rem', marginRight: '1rem' }}>
                  <CreditCardCVVInput />
                </CardInput>
                <CardInput style={{ width: '6rem' }}>
                  <CreditCardPostalCodeInput />
                </CardInput>
              </Row>
            </Desktop>
            <Mobile>
              <Row style={{ justifyContent: 'left' }}>
                <CardInput style={{ width: '13rem', marginRight: '1rem' }}>
                  <CreditCardNumberInput />
                </CardInput>
              </Row>
              <Row style={{ justifyContent: 'left' }}>
                <CardInput style={{ width: '6rem', marginRight: '1rem' }}>
                  <CreditCardExpirationDateInput />
                </CardInput>
                <CardInput style={{ width: '5rem', marginRight: '1rem' }}>
                  <CreditCardCVVInput />
                </CardInput>
              </Row>
              <Row style={{ justifyContent: 'left', marginTop: '1rem' }}>
                <CardInput style={{ width: '6rem' }}>
                  <CreditCardPostalCodeInput />
                </CardInput>
              </Row>
            </Mobile>
          </fieldset>
          <Row style={{ justifyContent: 'center' }}>
            <Button2 onClick={handleCancelSquare}>Cancel</Button2>
            <CustomPayButton text={'Submit'} />
          </Row>
        </SquarePaymentForm>
        {<div className="sq-error-message">
          {errorMessages && errorMessages.map(errorMessage =>
            <Li key={`sq-error-${errorMessage}`}>{errorMessage}</Li>
          )}
        </div>}
      </ModalContent>
    </StyledModal>
    : null

  const displayAuthenticateButton = useCallback(() => {
    if (profileData.verification.tu_verified){
      return <ProfileText>Verified <Check><FontAwesomeIcon icon="check"/></Check></ProfileText>
    }

    return profileData.subscription.tier_int === 0 ?
    <Button style={{margin: '.5rem auto auto 0'}} disabled>Authenticate</Button> :
    <Button style={{margin: '.5rem auto auto 0'}} onClick={() => setOpenAuth(true)}>Authenticate</Button>
  }, [profileData])

  const showProfile = profileData
    ? <>
        <CardTitle><Icon><FontAwesomeIcon icon="user" /></Icon>Profile</CardTitle>
        <Desktop>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>{profileData.firstName ? 'NAME' : 'COMPANY:'}</ProfileLabel>
              <ProfileText>{isUserConsumer(userType) ? `${profileData.firstName} ${profileData.lastName}` : profileData.companyName}</ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              { isUserConsumer(userType) &&
                  <>
                    <ProfileLabel>Verify Account:</ProfileLabel>
                      {displayAuthenticateButton()}
                  </>
              }
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>ADDRESS:</ProfileLabel>
              {isUserConsumer(userType)
                ? profileData.address
                  ? <>
                      <ProfileText>{profileData.address}</ProfileText>
                      <ProfileText>
                        {`${profileData.city && profileData.city}, ${profileData.state} ${profileData.postalCode}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
                : profileData.companyAddress
                  ? <>
                      <ProfileText>{profileData.companyAddress}</ProfileText>
                      <ProfileText>
                      {`${profileData.companyCity}, ${profileData.companyState} ${profileData.companyPostalCode}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
              }
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>ACCOUNT STATUS:</ProfileLabel>
              <ProfileText>{profileData.subscription ? tiers[profileData.subscription.tier_int] : ''}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>EMAIL:</ProfileLabel>
              <ProfileText>{profileData.email}</ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>CREDIT FREEZE:</ProfileLabel>
              <ProfileText>{showCreditFreeze}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>PHONE:</ProfileLabel>
              <ProfileText>{profileData.phone}</ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>MULTI FACTOR AUTHENTICATION:</ProfileLabel>
              <Toggle 
                id={`toggle-mfa`}
                isOn={profileData.mfa_enabled} 
                handleToggle={() => handleToggle()}
              />
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>USER ID:</ProfileLabel>
              <ProfileText>{profileData.id}</ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>NEXT PAYMENT DATE:</ProfileLabel>
              <ProfileText>{profileData.subscription.next_pay_date ? formatDateSlash(profileData.subscription.next_pay_date) : ''}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>PAYMENT METHOD:</ProfileLabel>
              {showPaymentMethod}
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>NEXT PAYMENT AMOUNT:</ProfileLabel>
              <ProfileText>{profileData.subscription.next_pay_charge ? `$${profileData.subscription.next_pay_charge}` : ''}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn style={{minHeight: '4rem'}}>
              <ProfileLabel>DELETE ACCOUNT:</ProfileLabel>
              <DeleteButton onClick={() => setOpenModalConfirm(true)}>Delete Account</DeleteButton>
            </ProfileColumn>
          </ProfileRow>
        </Desktop>
        <Mobile>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>{profileData.firstName ? 'NAME' : 'COMPANY:'}</ProfileLabel>
              <ProfileText>{isUserConsumer(userType) ? `${profileData.firstName} ${profileData.lastName}` : profileData.companyName}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>ADDRESS:</ProfileLabel>
              {isUserConsumer(userType)
                ? profileData.address
                  ? <>
                      <ProfileText>{profileData.address}</ProfileText>
                      <ProfileText>
                        {`${profileData.city && profileData.city}, ${profileData.state} ${profileData.postalCode}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
                : profileData.companyAddress
                  ? <>
                      <ProfileText>{profileData.companyAddress}</ProfileText>
                      <ProfileText>
                      {`${profileData.companyCity}, ${profileData.companyState} ${profileData.companyPostalCode}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
              }
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>EMAIL:</ProfileLabel>
              <ProfileText>{profileData.email}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>PHONE:</ProfileLabel>
              <ProfileText>{profileData.phone}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>MULTI FACTOR AUTHENTICATION:</ProfileLabel>
              <Toggle 
                id={`toggle-mfa`}
                isOn={profileData.mfa_enabled} 
                handleToggle={() => handleToggle()}
              />
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>USER ID:</ProfileLabel>
              <ProfileText>{profileData.id}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              { isUserConsumer(userType) &&
                  <>
                      <ProfileLabel>Verify Account:</ProfileLabel>
                      <ProfileText>
                        {profileData.verification.tu_verified
                          ? <ProfileText>Verified <Check><FontAwesomeIcon icon="check"/></Check></ProfileText>
                          : <Button style={{margin: '.5rem auto auto 0'}} onClick={() => setOpenAuth(true)}>Authenticate</Button>
                        }
                      </ProfileText>
                  </>
              }
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>ACCOUNT STATUS:</ProfileLabel>
              <ProfileText>{profileData.subscription ? tiers[profileData.subscription.tier_int] : ''}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>CREDIT FREEZE:</ProfileLabel>
              <ProfileText>{showCreditFreeze}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>NEXT PAYMENT DATE:</ProfileLabel>
              <ProfileText>{profileData.subscription.next_pay_date ? formatDateSlash(profileData.subscription.next_pay_date) : ''}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>NEXT PAYMENT AMOUNT:</ProfileLabel>
              <ProfileText>{profileData.subscription.next_pay_charge ? `$${profileData.subscription.next_pay_charge}` : ''}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>PAYMENT METHOD:</ProfileLabel>
              {showPaymentMethod}
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>DELETE ACCOUNT:</ProfileLabel>
              <DeleteButton onClick={() => setOpenModalConfirm(true)}>Delete Account</DeleteButton>
            </ProfileColumn>
          </ProfileRow>
        </Mobile>
      </>
    : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>

  const showConfirmDelete = openModalConfirm
    ? <Modal
      isOpen={openModalConfirm}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.1em' }}><strong>Confirm Account Deletion</strong></Message>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>By clicking "Delete", all personal account information will be deleted from bitdoor. You will be logged out once account deletion is complete.</Message>
        <Row>
          <Button style={{ minWidth: '5rem' }} onClick={() => setOpenModalConfirm(false)}>Cancel</Button>
          <DeleteButtonInv onClick={handleDelete}>Delete</DeleteButtonInv>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showAccountDeleted = openModalDeleted
    ? <Modal
      isOpen={openModalDeleted}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>{message}</Message>
        <Row>
          <Button style={{ width: 'auto' }} onClick={() => handleLogout()}>Ok</Button>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

    const showMessageModal = openSlack
    ? <Modal
      isOpen={openSlack}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.1em', marginTop: '1rem', marginBottom: '1rem' }}><strong>Send request for help.</strong></Message>
        <TextInput required value={helpText} onChange={(e) => setHelpText(e.target.value)}
        name='text' type='text' placeholder='' autoComplete='text' />
        <Row>
          <Button style={{ width: 'auto' }} onClick={() => setOpenSlack(false)}>Cancel</Button>
          <Button style={{ width: 'auto' }} onClick={() => {sendSlack(helpText); setOpenSlack(false)}}>Send</Button>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showConfirmDeletePayment = openModalPaymentDel
    ? <Modal
      isOpen={openModalPaymentDel}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.1em' }}><strong>Confirm Payment Deletion</strong></Message>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>By clicking "Delete", future payments will be cancelled with the current payment method. Some services provided by bitdoor may be not available.</Message>
        <Row>
          <Button onClick={() => setOpenModalPaymentDel(false)}>Cancel</Button>
          <DeleteButtonInv onClick={() => { setOpenModalPaymentDel(false); delPaymentMethod() }}>Delete</DeleteButtonInv>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showAuth = openAuth
    ? <Modal
        isOpen={openAuth}
        style={modalStylesO}
        contentLabel="Form"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Message style={{fontSize: '1.2em', margin: '1rem'}}><strong>Authenticate</strong></Message>
          {/* <Message style={{fontSize: '.9em', marginTop: '1rem'}}></Message> */}
          <AuthForm 
            setErrorStatus={setErrorStatus}
            setMessage={setMessage}
            setMessageModal={setMessageModal}
            setOpenAuth={setOpenAuth}
            profileData={profileData}
            submitAuthentication={submitAuthentication}
            dob={dob}
            setDob={setDob}
            questions={questions}
            setQuestions={setQuestions}
            address={address}
            setAddress={setAddress}
            postalCode={postalCode}
            city={city}
            setCity={setCity}
            setPostalCode={setPostalCode}
            authNonce={authNonce}
            country={country}
            setCountry={setCountry}
            addrState={addrState}
            setAddrState={setAddrState}
            setFirstNameSt={setFirstNameSt}
            setLastNameSt={setLastNameSt}
          />
        </ModalContent>
      </Modal>
    : <></>

  const showQuestions = questions
    ? <Modal
      isOpen={openQuestions}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent style={{ width: '24rem' }}>
        <Message style={{ fontSize: '1.2em', margin: '1rem' }}><strong>Authentication Questions</strong></Message>
        <Message style={{ marginBottom: '1rem' }}>Please answer these questions to verify your identity.</Message>
        <MultipleChoice>
          {
            questions.questions.map((q, i) => (
              <QuestionContainer onChange={(e) => handleAnswer(e)} key={`profile-question-${q.questionId + i}`}>
                <Label style={{ marginLeft: '1rem' }}>{q.fullQuestionText}</Label>
                {q.answerChoice.map((a, i) => (
                  <Row style={{ margin: '0', marginLeft: '2rem' }} key={`profile-answer-${a.answerId + i}`}>
                    <Radio type="radio" value={a.answerId} name={q.questionId} /><Answer>{a.answerChoiceText}</Answer>
                  </Row>
                ))}
              </QuestionContainer>
            ))
          }
        </MultipleChoice>
        <Row>
          <Button onClick={() => setOpenQuestions(false)} style={{ marginRight: '2rem', width: '7rem' }}>Cancel</Button>
          <Button onClick={submitAnswers} style={{ width: '7rem' }}>Submit</Button>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showSideNav = isUserConsumer(userType)
   ? <SideNavC view={view} setView={setView} />
   : <SideNavM view={view} setView={setView} />
  
  const tuUrl = () => { window.open('https://www.transunion.com/credit-freeze', '_blank') }

  const showFreeze = openModalFreeze
    ? <Modal
      isOpen={openModalFreeze}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <strong>Credit Freeze Alert</strong>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>
          There is a credit freeze on your file.
          </Message>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>
          By clicking "Authorize", I am authorizing Bitdoor to deliver my credit score and report.
            Please visit <SpanLink onClick={tuUrl}>TransUnion®</SpanLink> for more information.
          </Message>
        <Row>
          <Button onClick={() => setOpenModalFreeze(false)}>Cancel</Button>
          <Button onClick={unfreezeCredit}>Authorize</Button>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showUpgradeModal = openModalUpgrade
    ? <Modal
      isOpen={openModalUpgrade}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        {selectedTier > profileData.subscription.tier_int ? <strong>Account Upgrade</strong> : <strong>Account Downgrade</strong>}
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>
          {upgradeMessage(selectedTier)}.
            </Message>
        <Row>
          <Button onClick={() => setOpenModalUpgrade(false)}>Cancel</Button>
          <Button onClick={() => changeTier(selectedTier)}>Submit</Button>
        </Row>
      </ModalContent>
    </Modal>
    : null

  function upgradeMessage(selectedTier) {
    let { trial_1_allowed, trial_2_allowed, tier_int } = profileData.subscription;
    let selectPrem = document.getElementById("monthy-select-prem");
    let selectPro = document.getElementById("monthy-select-pro");
    let premInnerText = selectPrem ? selectPrem.innerText : "";
    let proInnerText = selectPro ? selectPro.innerText : "";

    //If they are downgrading
    if (tier_int > selectedTier) {
      if (selectedTier === 0) {
        return `Your subscription will be downgraded to ${tiers[selectedTier]} after your current subscribtion expires`;
      }
      else {
        return trial_1_allowed ?
          `Your 10 day trial for ${tiers[selectedTier]} will be redeemed upon submission. After 10 days, your card on file will be charged ${proInnerText}` :
          `Your subscription will be downgraded to ${tiers[selectedTier]} after your current subscribtion expires. Upon downgrade your card on file will be charged ${proInnerText}`
      }
    }
    //If they are upgrading
    if (tier_int < selectedTier) {
      if (selectedTier === 1) {
        return trial_1_allowed ?
          `Your 10 day trial for ${tiers[selectedTier]} will be redeemed upon submission. After 10 days, your card on file will be charged ${proInnerText}` :
          `Upon submission your card on file will be charged immediately for ${proInnerText}`

      }
      else {
        return trial_2_allowed ?
          `Your 10 day trial for ${tiers[selectedTier]} will be redeemed upon submission. After 10 days, your card on file will be charged ${premInnerText}` :
          `Upon submission your card on file will be charged immediately for ${premInnerText}`
      }
    }
  }

  const handleUpgrade = (tier) => {
    setSelectedTier(tier.newTier);
    setMonthly(tier.monthly);
    setOpenModalUpgrade(true);
  }

  // const handleDowngrade = (tier) => {
  //   // setSelectedTier(tier);
  //   // setOpenModalUpgrade(true);
  //   setMessage('This feature is coming soon. Please contact customer service for downgrade options.');
  //   setMessageModal(true);
  // }

  const showUpgrade = (tier) => {
    if (profileData.subscription.tier_int === tier.newTier) {
      return (
        <ButtonInv
          style={{ margin: '1.5rem auto auto auto' }}
          disabled={true}
        >
          CURRENT PLAN
        </ButtonInv>
      )
    } else {
      return (
        <Column>
          {tier.newTier > profileData.subscription.tier_int
            ? <Button
              style={{ margin: '1.5rem auto auto auto', minWidth: '6.5rem' }}
              onClick={() => handleUpgrade(tier)}
              disabled={tier === profileData.subscription.next_tier ? true : false}
            >
              UPGRADE PLAN
              </Button>
            : <Button
              style={{ margin: '1.5rem auto auto auto', minWidth: '6.5rem' }}
              onClick={() => handleUpgrade(tier)}
              disabled={tier === profileData.subscription.next_tier ? true : false}
            >
              DOWNGRADE PLAN
              </Button>
          }
          {tier === profileData.subscription.next_tier
            ? <UpgradeSpan>Will upgrade on {formatDateSlash(profileData.subscription.next_pay_date)}</UpgradeSpan>
            : ''
          }
        </Column>
      )
    }
  }

  const consumerTiers = () => {
    return (
      <TierContainer>
        <CardTier>
          <LabelTier>FREE</LabelTier>
          <LabelTier style={{ fontWeight: '700' }}>$0</LabelTier>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Control your data</ProfileLabel>
            <CheckIcon><FontAwesomeIcon icon="check" /></CheckIcon>
          </Column>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Access to offers</ProfileLabel>
            <CheckIcon><FontAwesomeIcon icon="check" /></CheckIcon>
          </Column>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Credit Report</ProfileLabel>
            <Text style={{ margin: '0' }}>First report free</Text>
          </Column>
          <>{showUpgrade({ newTier: 0, monthly: true })}</>
        </CardTier>
        <CardTier>
          <LabelTier>Pro</LabelTier>
          {
            profileData.subscription.tier_int === 1
              ? profileData.subscription.monthly
                ? <LabelTier>${tierCost['pro_month']}/month</LabelTier>
                : <LabelTier>${tierCost['pro_year']}/year</LabelTier>
              : <CenterRow>
                <StyledSelect
                  id='monthy-select-pro'
                  value={selectPro}
                  onChange={(d) => setSelectPro(d)}
                  name='monthy-select-pro'
                  options={optionsPro}
                  isSearchable={false}
                  className='custom-select'
                  styles={selectStylesNoBox}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                />
              </CenterRow>
          }
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Control your data</ProfileLabel>
            <CheckIcon><FontAwesomeIcon icon="check" /></CheckIcon>
          </Column>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Access to offers</ProfileLabel>
            <CheckIcon><FontAwesomeIcon icon="check" /></CheckIcon>
          </Column>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Credit Report</ProfileLabel>
            <Text style={{ margin: '0' }}>Monthly</Text>
          </Column>
          <>{showUpgrade({ newTier: 1, monthly: true })}</>
        </CardTier>
        <CardTier>
          <LabelTier>Premium</LabelTier>
          {
            profileData.subscription.tier_int === 2
              ? profileData.subscription.monthly
                ? <LabelTier>${tierCost['pro_month']}/month</LabelTier>
                : <LabelTier>${tierCost['pro_year']}/year</LabelTier>
              : <CenterRow>
                <StyledSelect
                  id='monthy-select-pro'
                  value={selectPrem}
                  onChange={(d) => setSelectPrem(d)}
                  name='monthy-select-pro'
                  options={optionsPrem}
                  isSearchable={false}
                  className='custom-select'
                  styles={selectStylesNoBox}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                />
              </CenterRow>
          }
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Control your data</ProfileLabel>
            <CheckIcon><FontAwesomeIcon icon="check" /></CheckIcon>
          </Column>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Access to offers</ProfileLabel>
            <CheckIcon><FontAwesomeIcon icon="check" /></CheckIcon>
          </Column>
          <Column style={{ marginTop: '1rem' }}>
            <ProfileLabel>Credit Report</ProfileLabel>
            <Text style={{ margin: '0' }}>Unlimited</Text>
          </Column>
          <>{showUpgrade({ newTier: 2, monthly: true })}</>
        </CardTier>
      </TierContainer>
    )
  }

  const showPricing = isUserMerchant(userType) && profileData
    ? <>
      <CardTitle style={{ marginTop: '1rem', marginLeft: '0' }}>Our Pricing Model</CardTitle>
      <Column style={{ width: '35rem', justifyContent: 'center', alignItems: 'center' }}>
        <Text>
          We use a "freemium" pricing model. Account features are free to use. Fees only apply when
          consumers redeem your published offers. You will not be charged if bitdoor does not generate
          customers from your offers.
          </Text>
        <TablePricingM>
          <tbody>
            <tr>
              <td style={{ fontWeight: '700' }}>Membership</td>
              <td style={{ textAlign: 'center', color: '#4597b7' }}>Free</td>
            </tr>
            <tr>
              <td style={{ fontWeight: '700' }}>Access To Our Consumer Network</td>
              <td style={{ textAlign: 'center', color: '#4597b7' }}>Free</td>
            </tr>
            <tr>
              <td style={{ fontWeight: '700' }}>Create/Publish Offers</td>
              <td style={{ textAlign: 'center', color: '#4597b7' }}>Free</td>
            </tr>
            <tr>
              <td style={{ fontWeight: '700' }}>Redeemed Offers</td>
              <td style={{ textAlign: 'center' }}>1% per incremental customer transaction(minimum $1.00 fee)</td>
            </tr>
          </tbody>
        </TablePricingM>
      </Column>
    </>
    : <>
      <CardTitle style={{ marginTop: '3rem', marginBottom: '1rem' }}><Icon><FontAwesomeIcon icon="tags" /></Icon>Subscription</CardTitle>
      {consumerTiers()}
    </>

  const showMFAEnable = MFAEnable
    ? <StyledModal
      isOpen={MFAEnable}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.2em', margin: '1rem' }}><strong>Multi Factor Authentication</strong></Message>
        <Message style={{ margin: '0 0 1rem 0' }}>If enabled, this will be required during login.</Message>
        {
          MFAVerify
            ? <>
              <Label>Enter One Time Password: </Label>
              <MFARow style={{ margin: '1rem' }}>
                <Input
                  value={OTP}
                  name='OTP'
                  autoComplete='off'
                  style={{ width: '8rem' }}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </MFARow>
              <Row>
                <Button
                  onClick={() => { setMFAEnable(false); setMessage(''); setMFAVerify(false); }}
                  style={{ marginRight: '2rem', width: '7rem' }}
                >Cancel</Button>
                <Button onClick={verifyMFA} style={{ width: '7rem' }}>Submit</Button>
              </Row>
            </>
            : <>
              <MFARow>
                <Label style={{ marginRight: '2rem' }}>Method: </Label>
                <StyledSelectMFA
                  value={MFA}
                  onChange={(selected) => setMFA(selected)}
                  options={mfaOptions}
                  isSearchable={false}
                  // className='custom-select'
                  styles={selectStyles}
                />
              </MFARow>
              <MFARow style={{ margin: '1.5rem 1.5rem 0 1.5rem' }}>
                {
                  MFA.value === 'email'
                    ? <Input
                      value={profileData ? profileData.email : ''}
                      name='email'
                      autoComplete='off'
                      disabled={true}
                      style={{ width: '14rem' }}
                    />
                    : <PhoneInput
                      onChange={setMFAPhone}
                      value={MFAPhone}
                      name='mfa-voice'
                      autoComplete='off'
                      defaultCountry='US'
                      style={{ height: 'auto' }}
                      placeholder='Phone Number'
                    />
                }
              </MFARow>
              <Error>{message ? message : ''}</Error>
              <Row style={{ marginTop: '1rem' }}>
                <Button onClick={() => { setMessage(''); setMFAEnable(false) }} style={{ marginRight: '2rem', width: '7rem' }}>Cancel</Button>
                <Button onClick={initMFA} style={{ width: '7rem' }}>Submit</Button>
              </Row>
            </>
        }
      </ModalContent>
    </StyledModal>
    : <></>

  const showConfirmDisableMFA = MFADisable
    ? <Modal
      isOpen={MFADisable}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.1em' }}><strong>Confirm Disable MFA</strong></Message>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>By clicking "Disable", Multi Factor Authentication will not be required to log in.</Message>
        <Row>
          <Button style={{ minWidth: '5rem' }} onClick={() => setMFADisable(false)}>Cancel</Button>
          <DeleteButtonInv onClick={() => { setMFADisable(false); disableMFA() }}>Disable</DeleteButtonInv>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  return (
    <Container>
      {showSideNav}
      <Content>
        {showProfile}
        {showPricing}
        {showConfirmDelete}
        {showAccountDeleted}
        {showMessageModal}
        {showPayment}
        {showConfirmDeletePayment}
        {showAuth}
        {showQuestions}
        {showFreeze}
        {showUpgradeModal}
        {showMFAEnable}
        {showConfirmDisableMFA}
      </Content>
    </Container>
  );
}

export default isLoading(Profile);

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const TierContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
    margin: auto;
  }

  @media (max-width: 414px) {
    margin: auto;
    padding: 2rem 0 0 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const QuestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`

const MultipleChoice = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-height: 30rem;
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary};
`

const CheckIcon = styled.div`
  padding: .3rem auto;
  margin: auto;
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.green};
`

const CardTier = styled.div`
  ${({ theme }) => theme.animations.fadeIn};
  margin: 1rem 3rem;
  width: 16rem;
  minHeight: 16rem;
  display: flex;
  flex-direction: column;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: .25rem;
  background-color: #fff;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;

  @media (max-width: 414px) {
    margin-left: 3rem;
  }
`

const Text = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1em;
  font-weight: 500;
  margin-top: 1rem;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const TablePricingM = styled.table`
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-top: 1rem;
  width: 30rem;

  td {
    text-transform: none;
    border: 1px solid #dee2e6;
    vertical-align: middle;
  }
  
  @media (max-width: 768px) {
    width: auto;
  }

`

const Check = styled.span`
  padding: .3rem auto;
  margin: auto auto auto .2rem;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.green};
`

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  @media (max-width: 414px) {
    width: 85%;
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Button = styled.button`
  color: #fff;
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.buttonPrimary};
  height: 2rem;
  min-width: 8rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : '#fff'};
    color: ${({ theme, disabled }) => disabled ? '#fff' : theme.colors.primary};
    border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  };

`

const ButtonInv = styled.button`
  color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
  background-color: ${({ theme, disabled }) => disabled ? '#fff' : theme.colors.buttonPrimary};
  height: 2rem;
  min-width: 8rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 700;
  font-size: .9em;
`

const DeleteButton = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.error};
  background-color: ${({ theme }) => theme.colors.error};
  height: 2rem;
  min-width: 8rem;
  font-size: 1em;
  margin: .5rem auto auto 0;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.error};
  };

`

const DeleteButtonInv = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.error};
  background-color: ${({ theme }) => theme.colors.error};
  height: 2rem;
  min-width: 5rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 500;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.error};
  };

`

const Message = styled.div`
  text-align: center;
  max-width: 19rem;
  margin: auto 1rem;

  @media (max-width: 414px) {
    max-width: 15rem
    width: 100%;
  }
`

const CardInput = styled.div`
  text-align: left;
  span {
    margin-left: .2rem;
  }
`

const PaymentTitle = styled.div`
  font-size: 1.6em;
  font-weight: 700;
  margin: .5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  @media (max-width: 414px) {
    font-size: 1.4em;
  }
`

// const Link = styled.div`
//   font-weight: 500;
//   color: ${({ theme }) => theme.colors.primary };
//   cursor: pointer
// `

const IconLink = styled.div`
  font-weight: 500;
  font-size: .9em;
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
  margin-left: 1rem;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const IconLinkD = styled.div`
  font-weight: 500;
  font-size: .9em;
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
  margin-left: 1rem;

  :hover {
    color: ${({ theme }) => theme.colors.error};
  }
`

const Button2 = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: .9em;
  height: 2rem;
  padding: .4rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  margin-right: 2rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const Li = styled.li`
  color: ${({ theme }) => theme.colors.error};
  font-size: .9em;
  padding-left: 1rem;
`

const Radio = styled.input`
  margin: .5rem;
  height: auto;
  padding: .5rem;
  box-shadow: none;
  :focus {
    box-shadow: none;
  }
`

const Answer = styled.span`
  font-size: .9em;
`

const Label = styled.label`
  font-size: 1em;
`

const LabelTier = styled.label`
  font-size: 1.8em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
`

const SpanLink = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  cursor: pointer;
  vertical-align: top;
`

const UpgradeSpan = styled.span`
  font-size: .8em;
  color: ${({ theme }) => theme.colors.textSecondary};
  text-align: center;
  margin-top: .4rem;
`

const StyledSelect = styled(Select)`
  width: 10rem;
  div {
    min-height: 1.3rem;
  }
  option {
    vertical-align: center;
  }
`

const StyledSelectMFA = styled(Select)`
  width: 8rem;
  div {
    min-height: 1.3rem;
  }
  option {
    vertical-align: center;
  }
`

const ProfileRow = styled.div`
  display: flex;
  margin: 1.5rem 0 0 0;
  min-height: 3rem;
  @media (max-width: 414px) {
    margin-left: 3rem;
  }
`

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25rem;

  @media (max-width: 768px) {
    min-width: 10rem;
  }

`

const ProfileLabel = styled.label`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary};
`

const ProfileText = styled.label`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.text};
`

const Input = styled.input`
  padding: .4rem .75rem;
  height: 2rem;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
`

const MFARow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  align-items: center;
  margin: 0;
  height: 3rem;
  input {
    height: 1.5rem;
    width: 8rem;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: .9em;
  margin: 0;
  height: 1rem;
` 

const TextInput = styled.textarea`
  height: 10.5rem;
  margin-bottom: 1rem;
  resize: vertical;
  font-size: 1.2em;
  @media (max-width: 414px) {
    max-width: 90%;
  }
`